<template>
  <BaseNavMenuSubItemLink
    v-for="(subItem, index) in subItems"
    :key="index"
    :to="subItem.to"
    :hyperlink-text="subItem.hyperlinkText"
  ></BaseNavMenuSubItemLink>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseNavMenuSubItemLink from "./BaseNavMenuSubItemLink.vue";
import type { BaseNavMenuSubItem } from "@/base/interfaces/menus/navMenus/BaseNavMenuSubItem.ts";

export default defineComponent({
  name: "BaseNavMenuSubItemLinkList",

  components: {
    BaseNavMenuSubItemLink,
  },

  expose: [],

  props: {
    /**
     * BaseNavMenuSubItemLink items to be rendered in this component.
     */
    subItems: {
      type: Array as PropType<BaseNavMenuSubItem[]>,

      required: true,
    },
  },
});
</script>
