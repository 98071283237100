<template>
  <VueApexCharts type="bar" :options="options" :series="series" :height="height" />
</template>

<script lang="ts">
import type { ApexOptions } from "apexcharts";
import { initFlowbite } from "flowbite";
import type { PropType } from "vue";
import VueApexCharts from "vue3-apexcharts";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseStackedBarChart",
  expose: [],
  components: {
    VueApexCharts,
  },

  props: {
    /** The datasets to display on the chart */
    series: {
      type: Array as PropType<ApexOptions["series"]>,
      required: true,
    },

    /** The categories (x-axis labels) for the chart */
    categories: {
      type: Array as PropType<string[]>,
      required: true,
    },

    /** The tooltip options for the chart. */
    tooltip: {
      type: Object as PropType<ApexOptions["tooltip"]>,
      required: false,
      default: () => ({
        enabled: true,
      }),
    },

    /** The height for this chart */
    height: {
      type: [String, Number] as PropType<Exclude<ApexOptions["chart"], undefined>["height"]>,
      required: false,
      default: undefined,
    },
  },

  computed: {
    options() {
      return {
        chart: {
          type: "bar",
          fontFamily: "Inter, sans-serif",
          stacked: true,
          stackType: "100%",
        },

        yaxis: {
          labels: {
            /* istanbul ignore next -- @preserve */ formatter(value: string) {
              return `${value}%`;
            },
          },
        },

        dataLabels: {
          enabled: false,
        },

        series: this.series,

        xaxis: {
          categories: this.categories,
        },

        tooltip: this.tooltip,
      };
    },
  },

  mounted() {
    initFlowbite();
  },
});
</script>
