import type { PropType } from "vue";
import type { ErrorBagInterface } from "@/validation/classes/ErrorBag.ts";
import ErrorBag from "@/validation/classes/ErrorBag.ts";

const OptionalErrorBagProp = {
  /** The error bag used to display errors. */
  errorBag: {
    required: false,
    type: Object as PropType<ErrorBagInterface>,
    default: () => ErrorBag.new({}),
    validator: (errorBag: ErrorBagInterface) => errorBag instanceof ErrorBag,
  },
};

export default OptionalErrorBagProp;
