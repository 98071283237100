<template>
  <button
    type="button"
    class="flex items-center group text-sm font-medium text-gray-700 hover:text-gray-900"
    @click="$emit('click')"
  >
    <span>{{ label }}</span>

    <span
      v-if="activeCount"
      class="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700"
    >
      {{ activeCount }}
    </span>

    <ChevronDownIcon class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"></ChevronDownIcon>
  </button>
</template>

<script lang="ts">
import ChevronDownIcon from "@heroicons/vue/20/solid/ChevronDownIcon";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseFilterMenuButton",
  expose: [],
  components: {
    ChevronDownIcon,
  },

  props: {
    /** Button text */
    label: {
      type: String as PropType<string>,
      required: true,
    },

    /**
     * How many filter options are active
     */
    activeCount: {
      type: Number as PropType<number | undefined>,
      required: false,
      default: undefined,
    },
  },

  emits: {
    click: () => true,
  },
});
</script>
