<template>
  <Transition
    enter-active-class="ease-out duration-300"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    data-testid="modal-transition"
  >
    <div
      v-if="isOpen"
      class="relative modal-overlay"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      :aria-hidden="!isOpen"
      v-bind="$attrs"
    >
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" data-testid="modal-overlay"></div>

      <div class="fixed inset-0 w-screen overflow-y-auto">
        <div
          class="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0"
          :class="mobileVerticalPosition === 'top' ? 'items-start' : 'items-end'"
        >
          <div
            class="base-modal-content-container relative rounded-lg transform bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm"
          >
            <div class="bg-blue-700 px-4 py-6 sm:px-6 rounded-t-lg">
              <div class="flex items-center justify-between">
                <h2 id="modal-title" class="text-base font-semibold leading-6 text-white">{{ modalTitle }}</h2>

                <div class="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    class="relative rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    @click="$emit('close')"
                    @keydown.enter="$emit('close')"
                  >
                    <span class="absolute -inset-2.5"></span>

                    <span class="sr-only">Close modal</span>

                    <svg
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>

              <div v-if="modalDescription !== undefined" class="mt-1" data-testid="modal-description-container">
                <p class="text-sm text-blue-300">{{ modalDescription }}</p>
              </div>
            </div>

            <div class="px-4 pb-6 pt-2 sm:px-6">
              <div class="space-y-5">
                <slot name="body"></slot>

                <slot name="alerts"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

/**
 * @todo add notification stack here, as currently they are hidden under the z-height of the modal
 */

export default defineComponent({
  name: "BaseModal",
  expose: [],
  inheritAttrs: false,

  props: {
    /** Used to display the modal. Allows for transition animations. */
    isOpen: {
      type: Boolean,
      required: true,
    },

    /** The text to display for the modal title */
    modalTitle: {
      required: true,
      type: String,
    },

    /** The text to display for the modal description */
    modalDescription: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /** Where should the modal be placed vertically when in mobile */
    mobileVerticalPosition: {
      type: String as PropType<"bottom" | "top">,
      required: false,
      default: "bottom",
    },
  },

  emits: {
    close: () => true,
  },
});
</script>

<style scoped>
@media only screen and (min-width: theme("screens.sm")) {
  .base-modal-content-container {
    min-width: 640px;
  }
}

.modal-overlay {
  z-index: 9999;
}
</style>
