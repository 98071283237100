/**
 * An enum that stores the formats for
 * the different parts or wholely of a
 * date time.
 *
 * This is to drive consistency.
 */
enum DateTimeFormatsEnum {
  Date = "YYYY-MM-DD",
  Time = "HH:mm",
  // For datetime use toISOString as it handles timezone to UTC.

  VuepicDate = "yyyy-MM-dd",
  VuepicDateTime = "yyyy-MM-dd HH:mm:ss",
  // Time is consistent so use Time for VuepicTime.
}

export default DateTimeFormatsEnum;
