<template>
  <div class="mt-6 flex gap-x-3">
    <UserAvatar class="h-6 w-6 rounded-full bg-gray-50 text-xs"></UserAvatar>

    <div class="relative flex-auto">
      <TemporaryMediaContainer
        v-model="media"
        :accept="getDefaultImageMimeTypes()"
        id-prefix="media"
        hide-input
        input-type="block"
      >
        <template #inline-input="{ openFileInput, disabled: mediaDisabled, loading, mediaCount }">
          <div class="w-full max-w-lg">
            <div
              :class="mediaCount > 0 && 'mb-4'"
              class="overflow-hidden w-full rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600"
            >
              <BaseInputTextarea
                v-model="comment"
                label="Comment"
                hide-label
                :rows="2"
                id-attribute="comment"
                :max-length="1000"
                placeholder="Add your comment..."
                class="block w-full resize-none !border-0 !outline-none !shadow-none !ring-0 !bg-transparent py-1.5"
                :disabled="mediaDisabled || disabled || loading"
                hide-max-character-count
              >
              </BaseInputTextarea>

              <div class="flex justify-between py-2 pl-3 pr-2 w-full">
                <div class="flex flex-row-reverse items-center gap-x-2">
                  <BaseBadge
                    v-if="mediaCount > 0"
                    html-aria-label="Media Count"
                    badge-colour="blue"
                    :badge-text="mediaCount.toString()"
                    without-dot
                  ></BaseBadge>

                  <button
                    type="button"
                    :disabled="mediaDisabled || disabled"
                    class="flex h-8 w-6 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                    @click="openFileInput"
                  >
                    <BaseLoadingSpinner v-if="loading" size="sm"></BaseLoadingSpinner>

                    <template v-else>
                      <FontAwesomeIcon
                        class="h-[1.125rem] w-[1.125rem]"
                        :icon="['fal', 'paperclip']"
                        aria-hidden="true"
                      />

                      <span class="sr-only">Attach a file</span>
                    </template>
                  </button>
                </div>

                <div class="flex items-center gap-4">
                  <BaseMaxCharacterCount :model-value="comment" :max-length="1000"></BaseMaxCharacterCount>

                  <BaseButton
                    button-text="Comment"
                    theme="white"
                    size="md"
                    class="font-semibold text-gray-900"
                    :disabled="mediaDisabled || disabled || loading"
                    @click="handleSubmit"
                  >
                  </BaseButton>
                </div>
              </div>
            </div>

            <!-- Error message -->
            <p v-show="errorMessage" id="comment-error" :aria-hidden="!errorMessage" class="mt-2 text-sm text-red-600">
              {{ errorMessage }}
            </p>
          </div>
        </template>
      </TemporaryMediaContainer>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent, toRef } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPaperclip } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TemporaryMediaContainer from "@/media/components/TemporaryMediaContainer.vue";
import useMimeTypesComposable from "@/media/composables/MimeTypesComposable.ts";
import BaseLoadingSpinner from "@/base/components/loading/BaseLoadingSpinner.vue";
import BaseBadge from "@/base/components/badges/BaseBadge.vue";
import type VaporMedia from "@/media/interfaces/VaporMediaInterface";
import BaseInputTextarea from "@/base/components/inputs/BaseInputTextarea.vue";
import BaseButton from "@/base/components/buttons/BaseButton.vue";
import { z } from "zod";
import VaporMediaSchema from "@/media/schemas/VaporMediaSchema.ts";
import useErrorBag from "@/validation/composables/ErrorBagComposable.ts";
import useValidatorComposable from "@/validation/composables/ValidatorComposable.ts";
import BaseMaxCharacterCount from "@/base/components/inputs/BaseMaxCharacterCount.vue";
import UserAvatar from "@/user/components/UserAvatar.vue";

library.add(faPaperclip);

const CommentSubmitSchema = z
  .object({
    comment: z.null().or(z.literal("")),
    media: z
      .array(
        VaporMediaSchema.transform((vaporMedia) => ({
          media: vaporMedia.key,
          original_file_name: vaporMedia.file.name,
        }))
      )
      .min(1, {
        message: "Text or an image is required",
      }),
  })
  .or(
    z.object({
      comment: z
        .string()
        .min(1, {
          message: "Text or an image is required",
        })
        .max(1000),
      media: z.array(
        VaporMediaSchema.transform((vaporMedia) => ({
          media: vaporMedia.key,
          original_file_name: vaporMedia.file.name,
        }))
      ),
    })
  );

export type CommentSubmitData =
  | {
      comment: "" | null;
      media: [VaporMedia, ...VaporMedia[]];
    }
  | {
      comment: string;
      media: VaporMedia[];
    };

export default defineComponent({
  name: "BaseCommentBox",
  components: {
    FontAwesomeIcon,
    TemporaryMediaContainer,
    BaseLoadingSpinner,
    BaseBadge,
    BaseInputTextarea,
    BaseButton,
    BaseMaxCharacterCount,
    UserAvatar,
  },

  props: {
    /**
     * Disable comment controls
     */
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  emits: {
    submit: (_data: CommentSubmitData) => true,
  },

  setup() {
    const { errorBag, newErrorBag } = useErrorBag();
    const { getDefaultImageMimeTypes } = useMimeTypesComposable();

    // @ts-expect-error CommentSubmitSchema is a schema but a ZodUnion
    const { validate } = useValidatorComposable(toRef(CommentSubmitSchema));

    return {
      getDefaultImageMimeTypes,
      errorBag,
      newErrorBag,
      validate,
    };
  },

  data() {
    return {
      comment: "",
      media: [] as VaporMedia[],
    };
  },

  computed: {
    errorMessage() {
      return (
        this.errorBag.findFirstMessageByFieldName("") ??
        this.errorBag.findFirstMessageByFieldName("comment") ??
        this.errorBag.findFirstMessageByFieldName("media")
      );
    },
  },

  methods: {
    handleSubmit() {
      const data = {
        comment: this.comment,
        media: this.media,
      };
      const validationResult = this.validate(data);

      this.errorBag = validationResult.errorBag;

      if (!validationResult.success) {
        return;
      }

      this.$emit("submit", validationResult.data as unknown as CommentSubmitData);
    },
  },

  expose: [],
});
</script>
