<template>
  <BaseSteps :steps="clonedSteps" @click-step="handleClickStep($event)" />

  <slot name="in-between-steps-and-content" />

  <!-- Handle v-if conditional rendering -->
  <template v-if="conditionalRenderingType === 'v-if'">
    <template v-for="step in clonedSteps" :key="step.key">
      <div v-if="step.status === 'current'" role="region" :aria-label="step.title">
        <slot :name="'step-' + step.key" :step="step" />
      </div>
    </template>
  </template>
  <!-- endregion -->

  <!-- region v-show conditional rendering -->
  <template v-else>
    <template v-for="step in clonedSteps" :key="step.key">
      <div
        v-show="step.status === 'current'"
        :aria-hidden="step.status !== 'current'"
        role="region"
        :aria-label="step.title"
      >
        <slot :name="'step-' + step.key" :step="step" />
      </div>
    </template>
  </template>
  <!-- endregion -->
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseSteps from "./BaseSteps.vue";

/** A step from the BaseSteps component */
type Step = InstanceType<typeof BaseSteps>["$props"]["steps"][0];

/**
 * @todo Currently this component does not support completed BaseSteps styling.
 */
export default defineComponent({
  name: "BaseStepsWithSlots",
  expose: [],
  components: {
    BaseSteps,
  },

  props: {
    /** The steps to display */
    steps: {
      required: true,
      type: Array as PropType<Step[]>,
    },

    /** The conditional rendering directive to use */
    conditionalRenderingType: {
      required: false,
      type: String,
      default: "v-if",
      validator: (value: string) => ["v-if", "v-show"].includes(value),
    },
  },

  data() {
    return {
      clonedSteps: this.steps.map((step) => ({ ...step })),
    };
  },

  methods: {
    handleClickStep(step: Step) {
      /**
       * Mark all steps as upcoming to give inactive styling
       */
      this.clonedSteps = this.clonedSteps.map((clonedStep) => ({ ...clonedStep, status: "upcoming" }));

      const currentStep = this.clonedSteps.find((clonedStep) => clonedStep.key === step.key);

      /* istanbul ignore next -- @preserve */
      if (currentStep === undefined) {
        return;
      }

      /** Set the clicked step as current, this will display the slot and give active styling */
      currentStep.status = "current";
    },
  },
});
</script>
