import NumberPropValidator from "@/validation/props/NumberPropValidator.ts";
import type { PropType } from "vue";

const OptionalNumberProp = {
  required: false as const,
  type: Number as PropType<number | undefined>,
  validator: NumberPropValidator,
};

const RequiredNumberProp = {
  required: true as const,
  type: Number as PropType<number>,
  validator: NumberPropValidator,
};

export { OptionalNumberProp, RequiredNumberProp };
