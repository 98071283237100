<template>
  <dl role="list" class="divide-y divide-gray-100">
    <template v-for="item in items" :key="item.key">
      <div role="listitem" class="sm:grid sm:grid-cols-3 sm:gap-4" :class="itemClass">
        <dt class="text-sm font-medium leading-6 text-gray-900">{{ item.title }}</dt>

        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {{ item.value !== null ? item.value : "-" }}
        </dd>
      </div>
    </template>
  </dl>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

/**
 * @todo add description list item slot type support
 */
export interface BaseDescriptionListItem {
  key: string;
  title: string;
  value: string | null;
}

export default defineComponent({
  name: "BaseDescriptionList",
  expose: [],
  props: {
    /** Description list items */
    items: {
      type: Array as PropType<BaseDescriptionListItem[]>,
      required: true,
    },

    /** The class for each item */
    itemClass: {
      required: false,
      type: String,
      default: "px-4 py-6 sm:px-0",
    },
  },
});
</script>
