<template>
  <div class="sm:px-6 lg:px-8 mt-5">
    <slot>
      <MainPageLayout
        :page-heading="pageHeading ?? /* istanbul ignore next -- @preserve */ ''"
        :page-sub-heading="pageSubHeading"
        :breadcrumbs="breadcrumbs"
        class="main-page-wrapper"
      >
        <template #pageHeading="{ pageHeading: slotPageHeading }">
          <slot name="pageHeading" :page-heading="slotPageHeading"> </slot>
        </template>

        <template #pageImage>
          <slot name="pageImage"> </slot>
        </template>

        <template #pageButtonBar>
          <slot name="pageButtonBar"> </slot>
        </template>

        <template #pageSubHeading>
          <slot name="pageSubHeading"> </slot>
        </template>

        <template #nav>
          <slot name="nav"></slot>
        </template>

        <template #pageContent>
          <slot name="pageContent"></slot>
        </template>
      </MainPageLayout>
    </slot>
  </div>
</template>

<script lang="ts">
import MainPageLayout, { MainPageLayoutProps } from "@/base/components/pageLayouts/MainPageLayout.vue";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainPageWrapper",
  expose: [],
  components: { MainPageLayout },
  props: {
    ...MainPageLayoutProps,

    /** Main page heading. */
    pageHeading: {
      required: false,
      type: String,
      validator: (pageHeading: string) => assertStringIsNotBlank(pageHeading),
      default: undefined,
    },
  },
});
</script>
