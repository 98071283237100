<template>
  <p class="bg-purple-600 text-sm text-white fond-semibold p-4" aria-invalid="true" role="alert">
    {{ errorText }}
  </p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorFallback",
  expose: [],

  props: {
    /**
     * The error text to display
     */
    errorText: {
      required: true,
      type: String,
    },
  },
});
</script>
