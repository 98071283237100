<template>
  <slot :parsed-data="parsedData" :is-fetching="isFetching" :do-fetch="fetch" />
</template>

<script lang="ts" setup generic="T">
import UseFetch from "@/app/http/composables/UseFetchComposable.ts";
import type { Ref } from "vue";
import { onMounted, ref, unref, watch } from "vue";
import type { ZodSchema } from "zod";

const props = defineProps<{
  // eslint-disable-next-line no-undef, vue/require-prop-comment
  schema: ZodSchema<T>;
  /** The url to fetch data from. */
  fetchUrl: string;
  /** Query params. Must be reactive to cause accurate refresh */
  params?: Record<string, unknown> | Ref<Record<string, unknown>>;
}>();

const emit = defineEmits<{
  fetchSuccess: [parsedData: T | undefined];
}>();

watch(
  () => props.fetchUrl,
  (newUrl) => {
    localUrl.value = newUrl;
    fetch();
  }
);
const refParams = ref(unref(props.params ?? {}));

const localUrl = ref(props.fetchUrl);

const { doFetch, isFetching } = UseFetch(
  localUrl,
  props.schema,
  {
    onFailure: "navigateToErrorPage",
    onSchemaParseFailure: "pushNotification",
  },
  refParams
);

watch(
  () => props.params,
  async () => {
    refParams.value = unref(props.params ?? /* istanbul ignore next -- @preserve */ {});
    fetch();
  },
  { deep: true }
);

const parsedData = ref<Ref<T> | undefined>(undefined);

/**
 * Fetch data from API
 */
function fetch(): void {
  parsedData.value = undefined;

  doFetch()
    .then((parsedResponseData) => {
      parsedData.value = parsedResponseData;

      // Emit event to parent component
      emit("fetchSuccess", { ...parsedResponseData });
    })
    // Do nothing on error as axios error will navigate to error page
    .catch(() => {});
}

defineExpose({
  parsedData,
  fetch,
});

onMounted(() => {
  fetch();
});
</script>

<script lang="ts">
/**
 * Reusable component for wrapping show pages/views
 *
 * - Contains reusable logic for fetching data from API
 * - Contains reusable logic for parsing data from API
 */
export default {
  name: "BaseShowContainer",
};
</script>
