enum AnalyticEventsEnum {
  loginSuccess = "login:success",
  loginFailure = "login:failure",

  appVersionMismatchRefresh = "app:version-mismatch:refresh",
  appOffline = "app:offline",

  pageView = "page:view",

  userTaskStarted = "userTask:started",
  userTaskUpdatedStatus = "userTask:updated:status",
  userTaskCompleted = "userTask:completed",

  cannyChangelogOpen = "canny:changelog:open",
  cannyFeedbackOpen = "canny:feedback:open",

  pageExpired = "http:419",

  userPasswordForgot = "user:password:forgot",
  userPasswordResetSuccess = "user:password:reset:success",
}

export default AnalyticEventsEnum;
