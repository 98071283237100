<template>
  <div>
    <div class="sm:hidden">
      <BaseMobileTabMenu :id="id" :tabs="tabs" :name="name"></BaseMobileTabMenu>
    </div>

    <div class="hidden sm:block">
      <BaseDesktopTabMenu :tabs="tabs" :attribute-aria-label="attributeAriaLabel"></BaseDesktopTabMenu>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseDesktopTabMenu from "./desktop/BaseDesktopTabMenu.vue";
import BaseMobileTabMenu from "./mobile/BaseMobileTabMenu.vue";
import getTabsProp from "./TabProps.ts";

/**
 * A tab menu that displays a list of tabs.
 *
 * - Uses router links to handle navigation.
 * - Uses a select element for mobile devices.
 * - Allows for active styling of the current tab.
 */
export default defineComponent({
  name: "BaseTabMenu",
  expose: [],
  components: {
    BaseDesktopTabMenu,
    BaseMobileTabMenu,
  },

  props: {
    /**
     * The tabs to display.
     */
    tabs: getTabsProp(true),

    /**
     * The aria label for the tab menu.
     */
    attributeAriaLabel: {
      required: true,
      type: String,
    },

    /**
     * The id of the mobile tab menu.
     */
    id: {
      required: true,
      type: String as PropType<string>,
    },

    /**
     * The name of the tab menu.
     */
    name: {
      required: true,
      type: String as PropType<string>,
    },
  },
});
</script>
