import { toLower, startCase, lowerCase } from "lodash";
import { singular, plural } from "pluralize";

/**
 * Converts a string to title case.
 * @param str The string to convert
 * @returns The converted string
 * @example titleCase("my-task-type") // My Task Type
 */
function titleCasePlural(str: string): string {
  if (typeof str !== "string") throw new TypeError("Expected a string");

  let clonedStr = str.slice();
  clonedStr = toLower(clonedStr);
  clonedStr = startCase(clonedStr);
  clonedStr = clonedStr.trim();
  clonedStr = plural(clonedStr);

  return clonedStr;
}

/**
 * Converts a string to title case and makes it singular.
 * @param str The string to convert
 * @returns The converted string
 * @example titleCaseSingular("my-task-types") // My Task Type
 */
function titleCaseSingular(str: string): string {
  if (typeof str !== "string") throw new TypeError("Expected a string");

  let clonedStr = str.slice();
  clonedStr = toLower(clonedStr);
  clonedStr = startCase(clonedStr);
  clonedStr = clonedStr.trim();
  clonedStr = singular(clonedStr);

  return clonedStr;
}

/**
 * Converts a string to title case.
 * @param str The string to convert.
 * @returns The converted string.
 * @example titleCase("my-task-type") // My Task Type
 */
function titleCase(str: string): string {
  if (typeof str !== "string") throw new TypeError("Expected a string");

  let clonedStr = str.slice();
  clonedStr = toLower(clonedStr);
  clonedStr = startCase(clonedStr);
  clonedStr = clonedStr.trim();

  return clonedStr;
}

/**
 * Converts a string to lower case.
 * @param str The string to convert
 * @returns The converted string
 * @example lowerCase("MyTaskType") // my task type
 */
function lowerCasePlural(str: string): string {
  if (typeof str !== "string") throw new TypeError("Expected a string");

  let clonedStr = str.slice();
  clonedStr = lowerCase(clonedStr);
  clonedStr = clonedStr.trim();
  clonedStr = plural(clonedStr);

  return clonedStr;
}

/**
 * Converts a string to lower case and makes it singular.
 * @param str The string to convert
 * @returns The converted string
 * @example lowerCaseSingular("MyTaskTypes") // my task type
 */
function lowerCaseSingular(str: string): string {
  if (typeof str !== "string") throw new TypeError("Expected a string");

  let clonedStr = str.slice();
  clonedStr = lowerCase(clonedStr);
  clonedStr = clonedStr.trim();
  clonedStr = singular(clonedStr);

  return clonedStr;
}

export { lowerCaseSingular, titleCaseSingular, titleCasePlural, titleCase, lowerCasePlural };
