<template>
  <div>
    <p id="radiogroup-label" :class="{ 'sr-only': hideLabel, 'mb-2': hideLabel === false, [labelClass]: true }">
      {{ label }}
    </p>

    <div class="grid gap-x-2 gap-y-2" :class="gridColsClass" role="radiogroup" aria-labelledby="radiogroup-label">
      <template v-for="option in options" :key="option.value">
        <button
          :id="getButtonId(option)"
          :disabled="disabled"
          :class="[
            getButtonClass(),
            isChecked(option) ? getActiveButtonClass(option.value) : getInactiveButtonClass(option.value),
          ]"
          tabindex="0"
          type="button"
          @click="updateModelValue(option.value)"
          @keydown.enter="updateModelValue(option.value)"
        >
          <span class="capitalize">{{ option.buttonText }}</span>

          <input
            :id="getRadioId(option)"
            :disabled="disabled"
            type="radio"
            :value="option.value"
            class="hidden"
            :aria-labelledby="getButtonId(option)"
            :checked="isChecked(option)"
            :aria-checked="isChecked(option)"
          />
        </button>
      </template>
    </div>

    <!-- Error message -->
    <p
      v-show="hasErrorMessage"
      :id="errorHtmlId"
      :aria-hidden="!hasErrorMessage"
      class="mt-2 text-sm text-red-600"
      data-testid="radio-group-panel-error"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup lang="ts" generic="T extends string | number">
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import type { GridColsSize } from "@/base/functions/css/grid/GridFunctions.ts";
import { getSmallGridColsClass } from "@/base/functions/css/grid/GridFunctions.ts";
import type { PropType } from "vue";
import { computed, ref, unref } from "vue";

export interface Option<ValueType> {
  value: ValueType;

  buttonText: string;
}

type TypedOption = Option<T>;

type Options = TypedOption[] | readonly TypedOption[];

type OptionValues = Options[number]["value"];

const props = defineProps({
  /** The HTML ID prefix to use for the buttons. */
  htmlIdPrefix: {
    required: false,
    type: String as PropType<string | undefined>,
    default: undefined,
  },

  /** The options to display. */
  options: {
    required: true,
    type: Array as PropType<Options>,
  },

  /** The field metadata to configure. */
  modelValue: {
    required: false,
    type: [String, Number, null] as unknown as PropType<T | null | undefined>,
    default: undefined,
  },

  /** The class to use for the buttons. */
  buttonClass: {
    required: false,
    type: String as PropType<string | undefined>,
    default: undefined,
  },

  /** The class to use for the active buttons. */
  activeButtonClass: {
    required: false,
    type: [String, Function] as PropType<string | ((optionValue: OptionValues) => string[] | string) | undefined>,
    default: undefined,
  },

  /** The class to use for the inactive buttons. */
  inactiveButtonClass: {
    required: false,
    type: [String, Function] as PropType<string | ((optionValue: OptionValues) => string[] | string) | undefined>,
    default: undefined,
  },

  /** Used to disable the radio inputs */
  disabled: {
    required: false,
    type: Boolean,
  },

  /** Used to set the grid size */
  gridColsSize: {
    required: false,
    default: 2,
    type: [String, Number] as PropType<GridColsSize>,
  },

  /** The label to use for the radio group. */
  label: {
    required: false,
    type: String,
    default: "Radio Group",
  },

  /** Whether to hide the label. */
  hideLabel: {
    required: false,
    type: Boolean,
  },

  /** The error message to display. */
  errorMessage: {
    required: false,
    type: [String, undefined] as PropType<string | undefined>,
    default: undefined,
  },

  /** The HTML ID of the error message. */
  errorHtmlId: {
    required: false,
    type: String,
    default: "radio-buttons-error",
  },

  /** The class to use for the label. */
  labelClass: {
    required: false,
    type: String,
    default: "text-sm font-medium text-gray-700",
  },
});

defineEmits<{
  (e: "update:modelValue", value: OptionValues): void;
}>();

const hasErrorMessage = computed(() => {
  return typeof props.errorMessage === "string";
});

const gridColsClass = ref(getSmallGridColsClass(props.gridColsSize));

const { updateModelValue } = useModelValue<OptionValues>();

const getRadioId = (option: TypedOption): string => {
  return `${props.htmlIdPrefix}-${option.value}-radio`;
};

const getButtonId = (option: TypedOption): string => {
  return `${props.htmlIdPrefix}-${option.value}-button`;
};

const getButtonClass = (): string => {
  return (
    props.buttonClass ??
    "px-4 py-2 text-base shadow-sm font-medium rounded-md inline-flex items-center justify-center cursor-pointer h-10 w-full sm:col-span-1 col-span-2"
  );
};

const getActiveButtonClass = (optionValue: OptionValues): string[] | string => {
  if (typeof props.activeButtonClass === "function") {
    return props.activeButtonClass(optionValue);
  }

  if (typeof props.activeButtonClass === "string") {
    return props.activeButtonClass;
  }

  return "bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed";
};

const getInactiveButtonClass = (optionValue: OptionValues): string[] | string => {
  if (typeof props.inactiveButtonClass === "function") {
    return props.inactiveButtonClass(optionValue);
  }

  if (typeof props.inactiveButtonClass === "string") {
    return props.inactiveButtonClass;
  }

  return "border-gray-300 border text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 disabled:bg-gray-50 disabled:cursor-not-allowed";
};

const checkedOption = computed(() => {
  return props.options.find((option) => option.value === props.modelValue);
});

const isChecked = (option: TypedOption): boolean => {
  const checkedOptionValue = unref(checkedOption)?.value;

  return checkedOptionValue !== undefined && checkedOptionValue === option.value;
};
</script>
