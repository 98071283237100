import type { ComputedRef, PropType } from "vue";
import { computed } from "vue";

export const XLeftPosition = "left";
export const XRightPosition = "right";

export const YTopPosition = "top";
export const YBottomPosition = "bottom";

export const XPositions = Object.freeze([XLeftPosition, XRightPosition]);
export const YPositions = Object.freeze([YBottomPosition, YTopPosition]);

export type XPosition = typeof XLeftPosition | typeof XRightPosition;
export type YPosition = typeof YBottomPosition | typeof YTopPosition;

export const BaseClassList = Object.freeze(["absolute"]);

export const YTopPositionClassList = Object.freeze(["-translate-y-full"]);

export const XLeftYTopPositionClassList = Object.freeze(["origin-bottom-left", "right-0"]);

export const XRightYTopPositionClassList = Object.freeze(["origin-bottom-right", "left-0"]);

export const XLeftYBottomPositionClassList = Object.freeze(["origin-top-left", "right-0"]);

export const XRightYBottomPositionClassList = Object.freeze(["origin-top-right", "left-0"]);

export const xPositionProp = {
  type: String as PropType<XPosition>,
  required: true as const,
  validator(value: string): boolean {
    return XPositions.includes(value);
  },
};

export const yPositionProp = {
  type: String as PropType<YPosition>,
  required: true as const,
  validator(value: string): boolean {
    return YPositions.includes(value);
  },
};

/**
 * Reusable code when dealing with relative-absolute positioned elements
 * like dropdowns.
 * @todo add stick direction as eg. position x right sticks to the left most point of the parent but still flows right
 * @param xPosition The element x flow direction. This doesn't control where the element extremes sticks to.
 * @param yPosition The element y flow direction. This doesn't control where the element extremes sticks to.
 * @returns Composable
 */
export function useAbsoluteRelativePositionComposable(
  xPosition: XPosition,
  yPosition: YPosition
): {
  classList: ComputedRef<string[]>;
} {
  return {
    classList: computed<string[]>(() => {
      const classList = [...BaseClassList];

      if (!XPositions.includes(xPosition)) {
        throw new TypeError(`Invalid x position '${xPosition}' provided`);
      }

      if (!YPositions.includes(yPosition)) {
        throw new TypeError(`Invalid y position '${yPosition}' provided`);
      }

      if (yPosition === "top") {
        classList.push(...YTopPositionClassList);

        if (xPosition === "left") {
          classList.push(...XLeftYTopPositionClassList);
        } else {
          classList.push(...XRightYTopPositionClassList);
        }
      } else if (xPosition === "left") {
        classList.push(...XLeftYBottomPositionClassList);
      } else {
        classList.push(...XRightYBottomPositionClassList);
      }

      return classList;
    }),
  };
}

export default useAbsoluteRelativePositionComposable;
