import { baseAppVariables } from "@/app/BaseAppVariables.ts";
import useNotificationStore from "@/base/stores/NotificationStore.ts";
import * as Sentry from "@sentry/vue";
import { HttpStatusCode, isAxiosError } from "axios";
import { ZodError } from "zod";

export const reportableStatusCodes = [
  HttpStatusCode.BadRequest,
  HttpStatusCode.PaymentRequired,
  HttpStatusCode.MethodNotAllowed,
  HttpStatusCode.RequestTimeout,
  HttpStatusCode.PayloadTooLarge,
  HttpStatusCode.UriTooLong,
  HttpStatusCode.TooManyRequests,
  HttpStatusCode.RequestHeaderFieldsTooLarge,
  HttpStatusCode.UnavailableForLegalReasons,
  HttpStatusCode.InternalServerError,
  HttpStatusCode.NotImplemented,
  HttpStatusCode.BadGateway,
  HttpStatusCode.ServiceUnavailable,
  HttpStatusCode.GatewayTimeout,
  HttpStatusCode.HttpVersionNotSupported,
  HttpStatusCode.VariantAlsoNegotiates,
  HttpStatusCode.InsufficientStorage,
  HttpStatusCode.LoopDetected,
  HttpStatusCode.NotExtended,
  HttpStatusCode.NetworkAuthenticationRequired,
];

/**
 * Get the error message to display to the end user.
 * @param error The error to get the message from.
 * @returns The error message.
 */
function getErrorMessage(error: Error): string | false {
  if (error instanceof ZodError) {
    // Don't report in production to prevent errors on minor typing differences.
    return baseAppVariables.isProduction ? false : "Could not parse data";
  }

  if (isAxiosError(error)) {
    // If no response, don't report as it's likely a network error.
    if (error.response === undefined) {
      return false;
    }

    const isReportableStatusCode = reportableStatusCodes.includes(error.response.status as HttpStatusCode);

    if (!isReportableStatusCode) {
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  /* istanbul ignore if -- @preserve */ if (!error?.message) {
    return false;
  }

  return error.message;
}

/**
 * Handle an error.
 * @param error The error to handle.
 */
export default function errorHandler(error: Error): void {
  const { push } = useNotificationStore();

  Sentry.captureException(error);

  const message = getErrorMessage(error);

  if (typeof message === "string") {
    push({
      duration: 5000,
      title: "Error",
      message,
      type: "error",
    });
  }

  if (window.baseAppVariables.isProduction === false && import.meta.env["VITE_APP_ENV"] !== "testing") {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

/**
 * Handle an error without failing in production.
 * @param error The error to handle.
 * @param options  The options for handling the error.
 * @param options.callErrorHandlerAnyway Call the error handler even in local environments.
 */
function handleErrorWithoutFailingInProduction(error: Error, options: { callErrorHandlerAnyway?: boolean } = {}): void {
  const isLocal = window.baseAppVariables.isProduction === false && import.meta.env["VITE_APP_ENV"] !== "testing";
  const callErrorHandler = !isLocal || options.callErrorHandlerAnyway === true;

  // Allow error handler to be called when inside a promise that has been caught.
  if (callErrorHandler) {
    errorHandler(error);
  }

  if (isLocal) {
    throw error;
  }
}

export { handleErrorWithoutFailingInProduction };
