import forms from "@tailwindcss/forms";
import plugin from "flowbite/plugin";
import defaultTheme from "tailwindcss/defaultTheme";

export default {
  content: {
    files: [
      "./resources/**/*.blade.php",
      "./resources/**/*.js",
      "./resources/**/*.ts",
      "./resources/js/**/*.vue",
      "./vueform.config.ts", // or where `vueform.config.js` is located
      "./node_modules/flowbite/**/*.js",
    ],
  },

  theme: {
    extend: {
      fontFamily: {
        // Recommended by tailwindui See: https://tailwindui.com/documentation#requirements
        sans: ["Inter var", ...defaultTheme.fontFamily.sans],
      },
    },
  },

  plugins: [
    // Need for tailwindui input groups See: https://tailwindui.com/components/application-ui/forms/input-groups,
    forms,
    // eslint-disable-next-line global-require
    plugin,
  ],
};
