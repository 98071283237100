import type { PropType } from "vue";
import z from "zod";
import type Tab from "./TabInterface.ts";
import RouteLocationRawSchema from "@/routing/schemas/RouteLocationRawSchema.ts";

const TabsSchema = z.array(z.object({ name: z.string(), to: RouteLocationRawSchema }));

const getTabsProp = <T extends boolean>(
  required: T
): { required: T; type: PropType<Tab[]>; default: () => Tab[]; validator: (tabs: Tab[]) => boolean } => ({
  required,
  type: Array as PropType<Tab[]>,
  default: /* istanbul ignore next -- @preserve */ () => [],
  validator: (tabs: Tab[]) => {
    const res = TabsSchema.safeParse(tabs);
    return res.success;
  },
});

export default getTabsProp;
