<template>
  <div
    role="menu"
    class="rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none gap-4 flex flex-col max-h-96 overflow-y-auto"
  >
    <div v-for="option in options" :key="option.key" role="menuitem">
      <BaseFilterMultiSelectOptionDropdownMenuItem
        :option-key="option.key"
        :label="option.label"
        :model-value="option.value"
        :no-wrap="noWrap"
        @update:model-value="handleOptionUpdate(option, $event)"
      ></BaseFilterMultiSelectOptionDropdownMenuItem>
    </div>
  </div>
</template>

<script lang="ts">
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { z } from "zod";
import BaseFilterMultiSelectOptionDropdownMenuItem from "./items/BaseFilterMultiSelectOptionDropdownMenuItem.vue";

export const MultiSelectFilterOptionSchema = z.object({
  key: z.union([z.string(), z.number()]),
  label: z.string(),
  value: z.boolean(),
});

export interface MultiSelectFilterOption extends z.infer<typeof MultiSelectFilterOptionSchema> {}
/**
 * @todo add nested options
 */
export default defineComponent({
  name: "BaseFilterMultiSelectOptionDropdownMenu",
  expose: [],
  components: {
    BaseFilterMultiSelectOptionDropdownMenuItem,
  },

  props: {
    /**
     * Options for the filter.
     */
    options: {
      type: Array as PropType<MultiSelectFilterOption[]>,
      required: true,
    },

    /**
     * Whether the dropdown menu items should not wrap its text.
     */
    noWrap: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: false,
    },
  },

  emits: {
    "update:options": (options: MultiSelectFilterOption[]) => Array.isArray(options),
  },

  setup() {
    const { updateModelValue: updateOptions } = useModelValue<MultiSelectFilterOption[]>("options");

    return {
      updateOptions,
    };
  },

  methods: {
    handleOptionUpdate(updateOption: MultiSelectFilterOption, updateValue: boolean) {
      const options = [...this.options];
      const updateOptionIndex = options.findIndex((option) => option.key === updateOption.key);

      options[updateOptionIndex] = { ...updateOption, value: updateValue };

      this.updateOptions(options);
    },
  },
});
</script>
