import type { ComponentPublicInstance, ComputedOptions, DefineComponent, MethodOptions } from "vue";
import { getCurrentInstance } from "vue";

interface RefComposable {
  getComponentRef: <T extends ComponentPublicInstance | DefineComponent>(
    name: string
  ) =>
    | (T extends DefineComponent
        ? ComponentPublicInstance<
            InstanceType<T>["$props"],
            InstanceType<T>["$attrs"],
            InstanceType<T>["$data"],
            T["computed"] extends ComputedOptions ? T["computed"] : ComputedOptions,
            T["methods"] extends MethodOptions ? T["methods"] : MethodOptions
          >
        : T)
    | undefined;
}

/**
 * Reusable code for getting a component $ref with type hinting
 * @returns code
 */
export function useRefComposable(): RefComposable {
  const vm = getCurrentInstance();

  return {
    getComponentRef<T extends ComponentPublicInstance | DefineComponent>(
      name: string
    ):
      | (T extends DefineComponent
          ? ComponentPublicInstance<
              InstanceType<T>["$props"],
              InstanceType<T>["$attrs"],
              InstanceType<T>["$data"],
              T["computed"] extends ComputedOptions ? T["computed"] : ComputedOptions,
              T["methods"] extends MethodOptions ? T["methods"] : MethodOptions
            >
          : T)
      | undefined {
      /* istanbul ignore if -- @preserve */
      if (!vm) {
        return undefined;
      }

      const componentRefs = vm.refs[name];
      let componentRef;

      if (Array.isArray(componentRefs)) {
        [componentRef] = componentRefs;
      } else {
        componentRef = componentRefs;
      }

      /* istanbul ignore if -- @preserve */
      if (typeof componentRef !== "object") {
        return undefined;
      }

      /* istanbul ignore if -- @preserve */
      if (!("$" in componentRef)) {
        return undefined;
      }

      return componentRef;
    },
  };
}

export default useRefComposable;
