import z from "zod";

export type StatusCode =
  | 100
  | 101
  | 102
  | 103
  | 200
  | 201
  | 202
  | 203
  | 204
  | 205
  | 206
  | 207
  | 208
  | 226
  | 300
  | 301
  | 302
  | 303
  | 304
  | 305
  | 306
  | 307
  | 308
  | 400
  | 401
  | 402
  | 403
  | 404
  | 405
  | 406
  | 407
  | 408
  | 409
  | 410
  | 411
  | 412
  | 413
  | 414
  | 415
  | 416
  | 417
  | 421
  | 422
  | 423
  | 424
  | 426
  | 428
  | 429
  | 431
  | 451
  | 500
  | 501
  | 502
  | 503
  | 504
  | 505
  | 506
  | 507
  | 508
  | 510
  | 511;

const StatusCodeSchema = z.union([
  z.literal(100),
  z.literal(101),
  z.literal(102),
  z.literal(103),
  z.literal(200),
  z.literal(201),
  z.literal(202),
  z.literal(203),
  z.literal(204),
  z.literal(205),
  z.literal(206),
  z.literal(207),
  z.literal(208),
  z.literal(226),
  z.literal(300),
  z.literal(301),
  z.literal(302),
  z.literal(303),
  z.literal(304),
  z.literal(305),
  z.literal(306),
  z.literal(307),
  z.literal(308),
  z.literal(400),
  z.literal(401),
  z.literal(402),
  z.literal(403),
  z.literal(404),
  z.literal(405),
  z.literal(406),
  z.literal(407),
  z.literal(408),
  z.literal(409),
  z.literal(410),
  z.literal(411),
  z.literal(412),
  z.literal(413),
  z.literal(414),
  z.literal(415),
  z.literal(416),
  z.literal(417),
  z.literal(421),
  z.literal(422),
  z.literal(423),
  z.literal(424),
  z.literal(426),
  z.literal(428),
  z.literal(429),
  z.literal(431),
  z.literal(451),
  z.literal(500),
  z.literal(501),
  z.literal(502),
  z.literal(503),
  z.literal(504),
  z.literal(505),
  z.literal(506),
  z.literal(507),
  z.literal(508),
  z.literal(510),
  z.literal(511),
]);

/**
 * Casts a value to a StatusCode
 * @param statusCode The value to cast
 * @returns The cast value or undefined if the value could not be casted
 */
export default function castToStatusCode(statusCode: unknown): StatusCode | undefined {
  const isNumResult = z
    .preprocess((val) => (typeof val === "string" ? parseInt(val, 10) : val), z.number())
    .safeParse(statusCode);

  if (!isNumResult.success) {
    return undefined;
  }

  const res = StatusCodeSchema.safeParse(isNumResult.data);

  return res.success ? res.data : undefined;
}
