import z from "zod";

/**
 * The response from the vapor store request.
 */
const VaporStoreResponseSchema = z.object({
  /** The s3 uuid of the file. */
  uuid: z.string(),
  /** The path to the file in the temporary s3 bucket. */
  key: z.string(),
  /** The temporary s3 bucket. Holds the file for 24 hours. */
  bucket: z.string(),
  /** The url of the file in the temporary s3 bucket. */
  url: z.string().transform((url): string => {
    const urlBody = url.split("?")[0];

    return urlBody ?? /* istanbul ignore next -- @preserve */ url;
  }),
});

export default VaporStoreResponseSchema;
