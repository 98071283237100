import type { PaginationLinks, PaginationMeta } from "@/base/interfaces/PaginationInterface";
import { PaginationLinksSchema, PaginationMetaSchema } from "@/base/schemas/PaginationSchema.ts";
import type { PropType } from "vue";

const metaValidator = (value: PaginationMeta): boolean => {
  return PaginationMetaSchema.safeParse(value).success;
};

const linksValidator = (value: PaginationLinks): boolean => {
  return PaginationLinksSchema.safeParse(value).success;
};

const RequiredPaginationMetaProp = {
  paginationMeta: {
    required: true as const,
    type: Object as PropType<PaginationMeta>,
    validator: metaValidator,
  },
};

const OptionalPaginationMetaProp = {
  paginationMeta: {
    required: false as const,
    type: Object as PropType<PaginationMeta | undefined>,
    validator: (value: PaginationMeta): boolean => {
      return PaginationMetaSchema.safeParse(value).success;
    },
    default: undefined,
  },
};

const RequiredPaginationLinksProp = {
  paginationLinks: {
    required: true as const,
    type: Object as PropType<PaginationLinks>,
    validator: linksValidator,
  },
};

const OptionalPaginationLinksProp = {
  paginationLinks: {
    required: false as const,
    type: Object as PropType<PaginationLinks | undefined>,
    validator: (value: PaginationLinks): boolean => {
      return PaginationLinksSchema.safeParse(value).success;
    },
    default: undefined,
  },
};

export {
  OptionalPaginationLinksProp,
  OptionalPaginationMetaProp,
  RequiredPaginationLinksProp,
  RequiredPaginationMetaProp,
};
