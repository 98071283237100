type ColSize = "full" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

/**
 * Get the matching tailwindcss sm:col-span class for the column size
 * @param colSize the column size
 * @returns the matching tailwindcss sm:col-span class
 */
function getSmallColSpanClass(colSize: ColSize | number): string {
  switch (colSize) {
    case "full":
      return "sm:col-span-full";
    case 1:
      return "sm:col-span-1";
    case 2:
      return "sm:col-span-2";
    case 3:
      return "sm:col-span-3";
    case 4:
      return "sm:col-span-4";
    case 5:
      return "sm:col-span-5";
    case 6:
      return "sm:col-span-6";
    case 7:
      return "sm:col-span-7";
    case 8:
      return "sm:col-span-8";
    case 9:
      return "sm:col-span-9";
    case 10:
      return "sm:col-span-10";
    case 11:
      return "sm:col-span-11";
    case 12:
      return "sm:col-span-12";
    default:
      return "";
  }
}

/** The grid column size */
type GridColsSize = "full" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
/** The matching tailwindcss sm:col-grid class for the number of columns */
type SmallGridColClass =
  | "sm:grid-cols-1"
  | "sm:grid-cols-2"
  | "sm:grid-cols-3"
  | "sm:grid-cols-4"
  | "sm:grid-cols-5"
  | "sm:grid-cols-6"
  | "sm:grid-cols-7"
  | "sm:grid-cols-8"
  | "sm:grid-cols-9"
  | "sm:grid-cols-10"
  | "sm:grid-cols-11"
  | "sm:grid-cols-12"
  | "sm:grid-cols-full";

/**
 * Get the matching tailwindcss sm:col-grid class for the number of columns
 * @param gridCols The grid column size
 * @returns the matching tailwindcss sm:col-grid class
 */
function getSmallGridColsClass(gridCols: GridColsSize | number): SmallGridColClass | undefined {
  switch (gridCols) {
    case "full":
      return "sm:grid-cols-full" as const;
    case 1:
      return "sm:grid-cols-1" as const;
    case 2:
      return "sm:grid-cols-2" as const;
    case 3:
      return "sm:grid-cols-3" as const;
    case 4:
      return "sm:grid-cols-4" as const;
    case 5:
      return "sm:grid-cols-5" as const;
    case 6:
      return "sm:grid-cols-6" as const;
    case 7:
      return "sm:grid-cols-7" as const;
    case 8:
      return "sm:grid-cols-8" as const;
    case 9:
      return "sm:grid-cols-9" as const;
    case 10:
      return "sm:grid-cols-10" as const;
    case 11:
      return "sm:grid-cols-11" as const;
    case 12:
      return "sm:grid-cols-12" as const;
    default:
      return undefined;
  }
}

/**
 * Get the matching tailwindcss col-span class for the column size
 * @param colSize The column size
 * @returns the matching tailwindcss col-span class
 */
function getColSpanClass(colSize: ColSize | number): string {
  switch (colSize) {
    case "full":
      return "col-span-full";
    case 1:
      return "col-span-1";
    case 2:
      return "col-span-2";
    case 3:
      return "col-span-3";
    case 4:
      return "col-span-4";
    case 5:
      return "col-span-5";
    case 6:
      return "col-span-6";
    case 7:
      return "col-span-7";
    case 8:
      return "col-span-8";
    case 9:
      return "col-span-9";
    case 10:
      return "col-span-10";
    case 11:
      return "col-span-11";
    case 12:
      return "col-span-12";
    default:
      return "";
  }
}

export { getColSpanClass, getSmallColSpanClass, getSmallGridColsClass };
export type { ColSize, GridColsSize };
