<template>
  <div>
    <div class="sm:hidden">
      <label :for="htmlId">
        <span class="sr-only">Select a tab</span>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select
          :id="htmlId"
          :name="htmlName"
          class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          @change="handleSelectChange"
        >
          <option v-for="option in renderOptions" :key="option.key" :value="option.key" :selected="option.isSelected">
            {{ option.label }}
          </option>
        </select>
      </label>
    </div>

    <div class="hidden sm:block">
      <ul class="flex space-x-4">
        <li v-for="option in renderOptions" :key="option.key">
          <button
            :aria-selected="option.isSelected"
            type="button"
            class="rounded-md px-3 py-2 text-sm font-medium text-gray-500"
            :class="{ '!bg-blue-100 !text-blue-700': option.isSelected, 'hover:text-gray-700': !option.isSelected }"
            @click="handleButtonChange(option as Tab)"
          >
            {{ option.label }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T extends string">
import { computed } from "vue";

interface Tab {
  label: string;
  key: T;
}

const props = defineProps<{
  /** The tabs to display. */
  tabs: Tab[];
  /** The key of the selected tab. */
  selectedKey: T;
  /** The id of the select element. */
  htmlId: string;
  /** The name of the select element. */
  htmlName: string;
}>();

const emit = defineEmits<{
  // Emitted when the selected tab key changes.
  (event: "update:selectedKey", key: T): void;
}>();

/**
 * Handles the select change event.
 * @param $event The select change event.
 */
function handleSelectChange($event: Event): void {
  const target = $event.target as HTMLSelectElement;
  const findOption = props.tabs.find((tab) => tab.key === target.value);

  /* istanbul ignore if -- @preserve */
  if (!findOption) {
    throw new Error(`The tab with key "${target.value}" does not exist.`);
  }

  emit("update:selectedKey", findOption.key);
}

/**
 * Handles the button click event.
 * @param tab The tab that was clicked.
 */
function handleButtonChange(tab: Tab): void {
  const findOption = props.tabs.find((option) => option.key === tab.key);

  /* istanbul ignore if -- @preserve */
  if (!findOption) {
    throw new Error(`The tab with key "${tab.key}" does not exist.`);
  }

  emit("update:selectedKey", findOption.key);
}

const renderOptions = computed(() => {
  return props.tabs.map((tab) => {
    return {
      label: tab.label,
      key: tab.key,
      isSelected: tab.key === props.selectedKey,
    };
  });
});
</script>
