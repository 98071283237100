<template>
  <button
    type="button"
    :class="buttonTheme"
    class="text-sm font-semibold py-1.5 px-2.5 rounded-md hover:bg-white hover:opacity-75 disabled:cursor-not-allowed disabled:opacity-50 whitespace-nowrap"
    @click="$emit('click')"
    @keypress.enter="$emit('click')"
  >
    <FontAwesomeIcon
      v-if="icon"
      class="mr-2"
      :icon="['far', icon]"
      size="lg"
      fixed-width
      data-testid="base-button-action-bar-icon"
    />
    {{ buttonText }}
  </button>
</template>

<script lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseButtonActionBar",
  expose: [],

  components: {
    FontAwesomeIcon,
  },

  props: {
    /** The text to display on the button */
    buttonText: {
      required: true,
      type: String,
    },

    /** The icon to display on the button */
    icon: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /** The theme of the button */
    theme: {
      required: false,
      type: String as PropType<"blue" | "red">,
      default: "blue" as const,
    },
  },

  emits: {
    click: () => true,
  },

  computed: {
    buttonTheme(): string {
      return this.theme === "red" ? "text-red-600 bg-red-50" : "text-blue-600 bg-blue-50";
    },
  },
});
</script>
