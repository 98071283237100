<template>
  <BaseFilterMenuFilter :position="position" :label="label">
    <template #default="{ updateShowDropdown }">
      <BaseFilterSelectOptionDropdownMenu
        :options="options"
        :no-wrap="noWrap"
        @update:model-value="
          handleUpdateModelValue($event);
          updateShowDropdown(false);
        "
      />
    </template>
  </BaseFilterMenuFilter>
</template>

<script lang="ts">
import BaseFilterSelectOptionDropdownMenu from "@/base/components/menus/dropdownMenus/BaseFilterSelectOptionDropdownMenu.vue";
import type { FilterMenuPosition } from "@/base/components/menus/filterMenus/filters/BaseFilterMenuFilter.vue";
import BaseFilterMenuFilter from "@/base/components/menus/filterMenus/filters/BaseFilterMenuFilter.vue";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import { ClickAway } from "@/base/directives/ClickAwayDirective.ts";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { SelectFilterOption } from "../../dropdownMenus/BaseFilterSelectOptionDropdownMenu.vue";

export default defineComponent({
  name: "BaseFilterMenuSelectFilter",
  expose: [],
  components: {
    BaseFilterSelectOptionDropdownMenu,
    BaseFilterMenuFilter,
  },

  directives: {
    ClickAway,
  },

  props: {
    /** Filter options */
    options: {
      type: Array as PropType<SelectFilterOption[]>,
      required: true,
    },

    /** Filter menu position in filter list */
    position: {
      type: String as PropType<FilterMenuPosition>,
      required: true,
    },

    /** The filter label */
    label: {
      type: String as PropType<string>,
      required: true,
    },

    /**
     * Whether the dropdown menu items should not wrap its text.
     */
    noWrap: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: false,
    },
  },

  emits: {
    "update:modelValue": (_value: unknown) => true,
  },

  setup() {
    const { updateModelValue } = useModelValue();

    return {
      updateModelValue,
    };
  },

  methods: {
    handleUpdateModelValue(value: unknown) {
      this.updateModelValue(value);
    },
  },
});
</script>
