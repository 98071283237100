<template>
  <!-- @todo these inputs needs to be refactored same layout, same classes -->
  <label :for="id" class="block" data-testid="text-label" :class="labelClass">
    <span
      data-testid="text-label-text"
      v-bind="{
        ...(hideLabel ? { class: 'sr-only' } : {}),
      }"
    >
      {{ label }}
    </span>

    <div class="relative" :class="{ 'mt-2': !hideLabel }">
      <input v-bind="attributes" @input="handleInput" @keypress="isNumber($event)" />
    </div>
  </label>
  <!-- Error message -->
  <p v-show="hasErrorMessage" :id="errorId" :aria-hidden="!hasErrorMessage" class="mt-2 text-sm text-red-600">
    {{ errorMessage ?? localErrorMessage }}
  </p>
</template>

<script lang="ts">
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import { debounce, type DebouncedFunc } from "lodash";
import type { PropType } from "vue";
import { defineComponent } from "vue";

/**
 * Number input with label
 * @author Aaron MacDougall
 */
export default defineComponent({
  name: "BaseInputNumber",

  expose: ["flush"],

  inheritAttrs: false,

  // Prevents inherited attributes so we can control binding

  props: {
    /**
     * Id HTML attribute
     */
    id: {
      required: true,
      type: String,
      validator: (id) => assertStringIsNotBlank(id),
    },

    /**
     * Input label text
     */
    label: {
      required: true,
      type: String as PropType<string>,
      validator: (label) => assertStringIsNotBlank(label),
    },

    /**
     * Whether to hide the label
     */
    hideLabel: {
      required: false,
      type: Boolean,
    },

    /**
     * HTML attribute to prevent browser interaction
     */
    disabled: {
      required: false,
      type: Boolean,
    },

    /**
     * Error message to render with the input
     *
     * Should only be used if there is a error to display
     */
    errorMessage: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * Id HTML attribute for error message
     */
    errorId: {
      required: false,
      type: String,
      default: "error",
      validator: (id) => assertStringIsNotBlank(id),
    },

    /** Debounce time in milliseconds */
    debounce: {
      required: false,
      type: Number as PropType<number | undefined>,
      default: 0,
      validator: (val: number) => val >= 0,
    },

    /**
     * Model value prop for v-model two-way binding
     */
    modelValue: {
      required: false,
      type: Number as PropType<number | null | undefined>,
      default: undefined,
    },

    /**
     * Placeholder HTML attribute for input placeholder text
     */
    placeholder: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * Class for the field label.
     */
    labelClass: {
      type: String,
      required: false,
      default: "text-sm font-medium text-gray-700",
    },
  },

  emits: {
    "update:modelValue": (value: number | null) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return typeof value === "number" || value === null;
    },
  },

  setup() {
    const { updateModelValue } = useModelValue();
    return {
      updateModelValue,
    };
  },

  data() {
    return {
      debounceFunction: undefined as DebouncedFunc<(event: Event) => void> | undefined,
      localErrorMessage: undefined as string | undefined,
    };
  },

  computed: {
    attributes() {
      return {
        ...this.$attrs,
        id: this.id,
        type: "text",
        class: ["class" in this.$attrs ? this.$attrs["class"] : "", this.inputClass, this.errorInputClass] as string[],
        "aria-invalid": this.hasErrorMessage,
        "aria-describedby": this.errorId,
        ...(this.placeholder !== undefined ? { placeholder: this.placeholder } : {}),
        ...(this.disabled ? { disabled: this.disabled } : {}),
        value: this.modelValue,
      };
    },

    inputClass() {
      return "block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 focus:outline-none disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500";
    },

    hasErrorMessage() {
      return this.errorMessage !== undefined || this.localErrorMessage !== undefined;
    },

    errorInputClass() {
      return this.hasErrorMessage
        ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-inset focus:ring-red-500"
        : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-600";
    },

    usesDebounce() {
      return this.debounce !== undefined && this.debounce > 0;
    },
  },

  created() {
    if (!this.usesDebounce) {
      return;
    }

    this.debounceFunction = debounce(($event: Event) => {
      this.callUpdateModelValueAfterInputEvent($event);
    }, this.debounce);
  },

  methods: {
    isNumber(event: KeyboardEvent) {
      const allowedFunctionalKeys = ["Backspace", "Enter"];

      if (allowedFunctionalKeys.includes(event.key)) {
        return;
      }

      const input = event.target as HTMLInputElement | null;
      const alreadyContainsDecimal = input?.value.includes(".");
      const alreadyHasValue = input?.value !== "";
      const isMax = Number(input?.value) > Number.MAX_SAFE_INTEGER;
      const isMin = Number(input?.value) < Number.MIN_SAFE_INTEGER;

      this.localErrorMessage = undefined;

      if (isMax || isMin) {
        this.localErrorMessage = "Please enter a valid number";

        event.preventDefault();
        return;
      }

      if (event.key === "-" && alreadyHasValue) {
        this.localErrorMessage = "Please enter a valid number";

        event.preventDefault();
        return;
      }

      if (event.key === "." && alreadyContainsDecimal === true) {
        this.localErrorMessage = "Please enter a valid number";

        event.preventDefault();
        return;
      }

      if (Number.isNaN(Number(event.key)) && event.key !== "." && event.key !== "-") {
        this.localErrorMessage = "Please enter a valid number";

        event.preventDefault();
      }
    },

    flush() {
      /* istanbul ignore else -- @preserve */
      if (this.debounceFunction) {
        this.debounceFunction.flush();
      }
    },

    handleInput(event: Event) {
      if (this.disabled) {
        return;
      }

      this.localErrorMessage = undefined;

      if (this.usesDebounce && this.debounceFunction) {
        this.debounceFunction(event);

        return;
      }

      this.callUpdateModelValueAfterInputEvent(event);
    },

    callUpdateModelValueAfterInputEvent(event: Event) {
      const input = event.target as HTMLInputElement | null;

      /* istanbul ignore next -- @preserve */
      if (!input || !("value" in input)) {
        return;
      }

      // Prevents hitting validation.
      if (input.value === "-") {
        return;
      }

      this.updateModelValue(input.value !== "" ? Number(input.value).valueOf() : null);
    },
  },
});
</script>
