/**
 * Asserts if the provided string is not blank (not just whitespace)
 * @param string string to test
 * @returns Returns true if the value is a string and not blank
 * @example "this string is not blank" ✅ valid
 * @example " this string is not blank " ✅ valid
 * @example " " ❌ not valid
 * @example "" ❌ not valid
 * @example [] ❌ not valid
 */
export default function assertStringIsNotBlank(string: unknown): boolean {
  if (typeof string !== "string") {
    throw new TypeError("`assertStringIsNotBlank` function expects `string` parameter to be typeof `string`");
  }

  /**
   * Trim string to remove whitespace.
   * If the entire string is just whitespace then string will be empty
   * and return false
   */
  return string.trim() !== "";
}
