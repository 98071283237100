<template>
  <slot :parsed-data="parsedData" :is-fetching="isFetching" :do-fetch="fetch" />
</template>

<script lang="ts" setup generic="T">
import useFetch from "@/app/http/composables/UseFetchComposable.ts";
import type { Ref } from "vue";
import { onMounted, ref, toRef, unref, watch } from "vue";
import type { ZodSchema } from "zod";
import useDebounce from "../composables/DebounceComposable.ts";

const props = defineProps<{
  // eslint-disable-next-line no-undef, vue/require-prop-comment
  schema: ZodSchema<T>;
  /** The url to fetch data from. */
  fetchUrl: string;
  /** Query params. Must be reactive to cause accurate refresh */
  params?: Record<string, unknown> | Ref<Record<string, unknown>>;
  /** Debounce timeout in milliseconds */
  debounce?: number | undefined;
  /** Instruct the component not to fetch on create */
  noFetchOnMount?: boolean;
}>();

const refParams = ref(unref(props.params ?? {}));

const { doFetch: nonDebouncedDoFetch, isFetching } = useFetch(
  props.fetchUrl,
  props.schema,
  {
    onSchemaParseFailure: "pushNotification",
    onFailure: "navigateToErrorPage",
  },
  refParams
);

const { invokeDebouncedCallback: doFetch } = useDebounce(
  toRef(props.debounce),
  nonDebouncedDoFetch,
  "debounceAfterInvoke"
);

watch(
  () => props.params,
  async () => {
    refParams.value = unref(props.params ?? /* istanbul ignore next -- @preserve */ {});
    fetch();
  },
  { deep: true }
);

// eslint-disable-next-line no-undef
const parsedData = ref<Ref<T> | undefined>(undefined);

/**
 * Fetch data from API
 */
function fetch(): void {
  const promise = doFetch();

  if (!promise) return;

  promise
    .then((parsedResponseData) => {
      parsedData.value = parsedResponseData;
    })
    // Do nothing on error as axios error will navigate to error page
    .catch(() => {});
}

onMounted(() => {
  if (!props.noFetchOnMount) {
    fetch();
  }
});

defineExpose({
  fetch,
});
</script>

<script lang="ts">
/**
 * Reusable component for wrapping index pages/views
 *
 * - Contains reusable logic for fetching data from API
 * - Contains reusable logic for parsing data from API
 * - Contains reusable logic for redirecting to error page
 */
export default {
  name: "BaseIndexContainer",
};
</script>
