import { z } from "zod";

type FieldErrors = string[];

type Field = string;

type ErrorBagErrorsConstructor =
  | Record<Field, FieldErrors | undefined>
  | Record<number, FieldErrors | undefined>
  | Record<symbol, FieldErrors | undefined>;

type ErrorBagErrorsInterface = Record<Field, FieldErrors>;

interface ErrorBagInterface {
  findByFieldName: (field: Field) => FieldErrors | undefined;
  findFirstMessageByFieldName: (field: Field) => string | undefined;
  isEmpty: () => boolean;
  wipeByFieldName: (field: Field) => void;
  push: (field: Field, error: string) => void;
  clear: () => void;
  firstErrorKey: () => Field | undefined;
}

const ErrorBagErrorsSchema = z.record(z.string(), z.array(z.string()));

/**
 * Class for parsing and reading errors
 */
class ErrorBag implements ErrorBagInterface {
  private readonly errors: ErrorBagErrorsInterface;

  /**
   * Create a new instance of `ErrorBag`
   * @param errors an object with the errors
   * @returns the new instance
   */
  public static new(errors: ErrorBagErrorsConstructor): ErrorBagInterface {
    // Deep clone and parse for errors
    const res = ErrorBagErrorsSchema.safeParse(errors);
    const errorBagErrors = res.success ? res.data : {};
    // Remove field object keys where the value is just a blank array
    Object.keys(errorBagErrors).forEach((fieldErrorKey) => {
      if (errorBagErrors[fieldErrorKey]?.length === 0) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete errorBagErrors[fieldErrorKey];
      }
    });
    return new ErrorBag(errorBagErrors);
  }

  private constructor(errors: ErrorBagErrorsInterface) {
    this.errors = errors;
  }

  /**
   * Removes an array of errors for a field from the error bag
   * @param field the field to remove errors for in the error bag
   */
  public wipeByFieldName(field: Field): void {
    if (Object.hasOwn(this.errors, field)) {
      this.errors[field] = [];
    }
  }

  /**
   * Returns an array of errors for a field from the error bag
   * @param field the field to find in the error bag
   * @returns the field errors or undefined if no errors found
   * @example [`Password is invalid`, `Password is too small`]
   */
  public findByFieldName(field: Field): FieldErrors | undefined {
    // Find all errors for a key
    const foundErrorKey: Field | undefined = Object.keys(this.errors).find((errorField) => errorField === field);
    // If field name not found in errors return undefined
    if (foundErrorKey === undefined) {
      return undefined;
    }
    // Get array of errors for the field
    const foundErrorsForKey = this.errors[foundErrorKey];

    /* istanbul ignore if -- @preserve This is defensive code and should never be reached */
    if (!Array.isArray(foundErrorsForKey)) {
      // Assert field errors are valid, if not return undefined
      return undefined;
    }

    return foundErrorsForKey;
  }

  /**
   * Returns the first error message in the array of errors for a field from the error bag
   * @param field the field to find in the error bag
   * @returns the first error message or undefined if no errors found
   * @example `Password is invalid`
   */
  public findFirstMessageByFieldName(field: Field): string | undefined {
    // Get field errors
    const fieldErrors = this.findByFieldName(field);
    // Ensure field errors are valid, if not return undefined
    if (fieldErrors === undefined || fieldErrors.length === 0 || !(0 in fieldErrors)) {
      return undefined;
    }
    // Return the first error message in the field errors array
    return fieldErrors[0];
  }

  public isEmpty(): boolean {
    return Object.keys(this.errors).length === 0;
  }

  public push(field: Field, error: string): void {
    let localFieldErrors = this.errors[field];

    if (localFieldErrors === undefined) {
      localFieldErrors = [];
    }

    if (localFieldErrors.includes(error)) {
      return;
    }

    localFieldErrors.push(error);

    this.errors[field] = localFieldErrors;
  }

  public clear(): void {
    Object.keys(this.errors).forEach((fieldErrorKey) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.errors[fieldErrorKey];
    });
  }

  public firstErrorKey(): Field | undefined {
    return Object.keys(this.errors)[0];
  }
}

export default ErrorBag;

export type { ErrorBagErrorsConstructor, ErrorBagErrorsInterface, ErrorBagInterface, Field };
