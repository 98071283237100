<template>
  <p
    v-if="fieldLabel"
    data-testid="base-input-checkbox-label"
    class="block mb-2"
    :class="{ [fieldLabelClass]: true, 'sr-only': hideFieldLabel }"
  >
    {{ fieldLabel }}
  </p>

  <!-- Checkbox Input + Label -->
  <label :for="id" :class="labelClass" data-testid="base-input-checkbox-label" class="cursor-pointer">
    <!-- checkbox -->
    <input
      :id="id"
      :name="name"
      :checked="modelValue ?? false"
      type="checkbox"
      :class="inputClass"
      :disabled="disabled"
      :required="required"
      :value="valueAttribute"
      v-bind="{
        ...$attrs,
      }"
      @change="handleChange($event)"
    />

    <!-- with label -->
    <template v-if="hideLabel === false">
      <span :class="{ 'font-semibold': description !== undefined }" data-testid="base-input-checkbox-label-text">
        {{ label }}
      </span>

      <br v-if="description" data-testid="base-input-checkbox-label-break-tag" />

      <p v-if="description" data-testid="base-input-checkbox-description" class="ml-6 mt-1 text-sm text-gray-500">
        {{ description }}
      </p>
    </template>

    <!-- hidden label -->
    <template v-else>
      <span class="sr-only" data-testid="base-input-checkbox-screen-reader-label">{{ label }}</span>
    </template>
  </label>

  <!-- Error message -->
  <p
    v-show="hasErrorMessage"
    :id="errorMessageId"
    :aria-hidden="!hasErrorMessage"
    class="mt-2 text-sm text-red-600"
    data-testid="base-input-checkbox-error-message"
  >
    {{ errorMessage }}
  </p>
</template>

<script lang="ts">
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseInputCheckbox",
  inheritAttrs: false,
  expose: [],

  props: {
    /**
     * Id Html attribute
     */
    id: {
      required: true,
      type: String,
      validator: (id) => assertStringIsNotBlank(id),
    },

    /**
     * Name HTML attribute
     */
    name: {
      required: true,
      type: String,
      validator: (name) => assertStringIsNotBlank(name),
    },

    /**
     * Field label text
     */
    fieldLabel: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * Input label text
     */
    label: {
      required: false,
      type: String,
      default: "Confirm",
      validator: (label) => assertStringIsNotBlank(label),
    },

    /**
     * Input description text
     */
    description: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /** Hide the label text */
    hideLabel: {
      required: false,
      type: Boolean,
    },

    /**
     * Disabled HTML attribute to prevent browser interaction
     */
    disabled: {
      required: false,
      type: Boolean,
    },

    /**
     * Required HTML attribute for specifying if input value is required
     */
    required: {
      required: false,
      type: Boolean,
    },

    /**
     * Html attribute value to be submitted on form submission
     */
    valueAttribute: {
      required: false,
      type: String,
      default: "",
    },

    /**
     * Model value prop for v-model two-way binding
     */
    modelValue: {
      required: false,
      type: Boolean as PropType<boolean | null | undefined>,
      default: undefined,
    },

    /**
     * Class for the checkbox label.
     */
    labelClass: {
      type: [String, Object, Array] as PropType<Record<PropertyKey, unknown> | unknown[] | string>,
      required: false,
      default: "block text-sm text-gray-900",
    },

    /**
     * Class for the field label.
     */
    fieldLabelClass: {
      type: String,
      required: false,
      default: "text-sm font-medium text-gray-700",
    },

    /**
     * Hide the field label
     */
    hideFieldLabel: {
      type: Boolean,
      required: false,
    },

    /**
     * Error message to render with the input
     *
     * Should only be used if there is a error to display
     */
    errorMessage: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * Id HTML attribute for error message
     */
    errorMessageId: {
      required: false,
      type: String,
      default: "checkbox-error",
      validator: (id) => assertStringIsNotBlank(id),
    },
  },

  emits: {
    "update:modelValue": (checked: boolean) => {
      return typeof checked === "boolean";
    },
  },

  setup() {
    const { updateModelValue } = useModelValue();
    return {
      updateModelValue,
    };
  },

  computed: {
    inputClass() {
      const baseClasses = [
        "cursor-pointer disabled:cursor-not-allowed mr-2 h-4 w-4 rounded disabled:cursor-not-allowed",
      ];

      if (this.modelValue === true) {
        baseClasses.push("disabled:bg-blue-400 disabled:text-blue-400");
      } else {
        baseClasses.push("disabled:bg-gray-50 disabled:text-gray-50");
      }

      baseClasses.push(this.errorInputClass);

      return baseClasses;
    },

    hasErrorMessage() {
      return this.errorMessage !== undefined;
    },

    errorInputClass() {
      return this.hasErrorMessage
        ? "border-red-300 text-red-600 focus:ring-red-500"
        : "border-gray-300 text-blue-600 focus:ring-blue-500";
    },
  },

  methods: {
    handleChange($event: Event) {
      const input = $event.target as HTMLInputElement | null;

      /* istanbul ignore if -- @preserve */
      if (!input || !("checked" in input)) {
        return;
      }

      this.updateModelValue(input.checked);
    },
  },
});
</script>
