<template>
  <BaseInputPassword
    v-if="field.type === 'password'"
    :id="field.id"
    :auto-complete="field.autoComplete"
    :name="field.name"
    :placeholder="field.placeholder"
    :disabled="disabled"
    :label="field.label"
    :hide-label="hideLabel"
    :error-message="errorBag.findFirstMessageByFieldName(field.key)"
    :error-message-id="`error-${field.key}`"
    :model-value="getFormDataInputTextFieldValue(field.key)"
    @update:model-value="emitUpdateModelValueEvent($event)"
  />

  <BaseInputEmail
    v-else-if="field.type === 'email'"
    :id="field.id"
    :auto-complete="field.autoComplete"
    :name="field.name"
    :placeholder="field.placeholder"
    :disabled="disabled"
    :label="field.label"
    :hide-label="hideLabel"
    :error-message="errorBag.findFirstMessageByFieldName(field.key)"
    :error-message-id="`error-${field.key}`"
    :model-value="getFormDataInputTextFieldValue(field.key)"
    @update:model-value="emitUpdateModelValueEvent($event)"
  />

  <BaseSelectInput
    v-else-if="field.type === 'select'"
    :id="field.id"
    :name="field.name"
    :disabled="disabled"
    :label="field.label"
    :hide-label="hideLabel"
    :error-message="errorBag.findFirstMessageByFieldName(field.key)"
    :error-id="`error-${field.key}`"
    :options="field.options"
    :label-key="field.optionLabelKey"
    :value-key="field.optionValueKey"
    :model-value="getFormDataSelectFieldValue(field.key)"
    @update:model-value="emitUpdateModelValueEvent($event)"
  />

  <BaseInputMultiSelectCustom
    v-else-if="field.type === 'multi-select'"
    :options="field.options"
    :hide-label="hideLabel"
    :model-value="getFormDataMultiSelectFieldValue(field.key)"
    :html-label="field.label"
    :html-id="field.id"
    :error-message="errorBag.findFirstMessageByFieldName(field.key)"
    :error-id="`error-${field.key}`"
    :disabled="disabled"
    :empty-message="field.emptyMessage"
    @update:model-value="emitUpdateModelValueEvent($event)"
  />

  <BaseRadioGroupPanel
    v-else-if="field.type === 'radio-group-panel'"
    :error-message="errorBag.findFirstMessageByFieldName(field.key)"
    :options="field.options"
    :label="field.label"
    :disabled="field.disabled !== true ? disabled : true"
    :hide-label="hideLabel"
    :model-value="getFormDataRadioGroupPanelValue(field.key)"
    @update:model-value="emitUpdateModelValueEvent($event)"
  />

  <BaseInputCheckbox
    v-else-if="field.type === 'checkbox'"
    :id="field.id"
    :name="field.name"
    :label="field.checkboxLabel ?? field.label"
    :disabled="disabled"
    :description="field.description"
    :hide-label="hideLabel"
    :error-message="errorBag.findFirstMessageByFieldName(field.key)"
    :model-value="getFormDataCheckboxFieldValue(field.key)"
    @update:model-value="emitUpdateModelValueEvent($event)"
  />

  <BaseRadioButtons
    v-else-if="field.type === 'radio-buttons'"
    :error-message="errorBag.findFirstMessageByFieldName(field.key)"
    :options="field.options"
    :label="field.label"
    :disabled="disabled"
    :hide-label="hideLabel"
    :model-value="getFormDataRadioButtonsValue(field.key)"
    @update:model-value="emitUpdateModelValueEvent($event)"
  />

  <template v-else-if="field.type === 'component'">
    <component
      :is="field.component"
      :ref="getComponentFieldRefName()"
      v-bind="getComponentProps(field)"
      @update:model-value="emitUpdateModelValueEvent($event)"
    />
  </template>

  <BaseInputTextarea
    v-else-if="field.type === 'textarea'"
    :id-attribute="field.id"
    :name="field.name"
    :placeholder="field.placeholder"
    :disabled="disabled"
    :label="field.label"
    :hide-label="hideLabel"
    :error-message="errorBag.findFirstMessageByFieldName(field.key)"
    :error-id="`error-${field.key}`"
    :model-value="getFormDataInputTextFieldValue(field.key)"
    :debounce="field.debounce"
    @update:model-value="emitUpdateModelValueEvent($event)"
  />

  <BaseInputDate
    v-else-if="field.type === 'date'"
    :id="field.id"
    :name="field.name"
    :placeholder="field.placeholder"
    :disabled="disabled"
    :label="field.label"
    :date="field.date"
    :time="field.time"
    :inline="field.inline"
    :hide-label="hideLabel"
    :error-message="errorBag.findFirstMessageByFieldName(field.key)"
    :error-id="`error-${field.key}`"
    :model-value="getFormDataInputTextFieldValue(field.key)"
    @update:model-value="emitUpdateModelValueEvent($event)"
  />

  <BaseInputFile
    v-else-if="field.type === 'file'"
    :id="field.id"
    :name="field.name"
    :disabled="disabled"
    :label="field.label"
    :hide-label="hideLabel"
    :error-message="errorBag.findFirstMessageByFieldName(field.key)"
    :error-id="`error-${field.key}`"
    @update:model-value="emitUpdateModelValueEvent($event)"
  />

  <BaseInputText
    v-else
    :id="field.id"
    :name="field.name"
    :placeholder="field.placeholder"
    :disabled="disabled"
    :label="field.label"
    :hide-label="hideLabel"
    :error-message="errorBag.findFirstMessageByFieldName(field.key)"
    :error-id="`error-${field.key}`"
    :model-value="getFormDataInputTextFieldValue(field.key)"
    :debounce="field.debounce"
    @update:model-value="emitUpdateModelValueEvent($event)"
  />
</template>

<script lang="ts">
import BaseButton from "@/base/components/buttons/BaseButton.vue";
import BaseRadioButtons from "@/base/components/buttons/BaseRadioButtons.vue";
import BaseInputDate from "@/base/components/inputs/BaseInputDate.vue";
import BaseInputEmail from "@/base/components/inputs/BaseInputEmail.vue";
import BaseInputFile from "@/base/components/inputs/BaseInputFile.vue";
import BaseInputPassword from "@/base/components/inputs/BaseInputPassword.vue";
import BaseInputText from "@/base/components/inputs/BaseInputText.vue";
import BaseInputTextarea from "@/base/components/inputs/BaseInputTextarea.vue";
import BaseRadioGroupPanel from "@/base/components/inputs/BaseRadioGroupPanel.vue";
import BaseSelectInput from "@/base/components/inputs/BaseSelectInput.vue";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import { useRefComposable } from "@/base/composables/RefComposable.ts";
import DisabledProp from "@/base/props/DisabledProp.ts";
import OptionalErrorBagProp from "@/validation/props/ErrorBagProp.ts";
import { get } from "lodash";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseInputCheckbox from "../inputs/BaseInputCheckbox.vue";
import BaseInputMultiSelectCustom from "../inputs/BaseInputMultiSelectCustom.vue";
import type {
  ComponentField,
  ComponentFieldComponentInstance,
  FormData,
  FormDataValue,
  FormField,
} from "./BaseFormInterface.ts";

export default defineComponent({
  name: "BaseFormInput",
  expose: ["validate"],

  components: {
    BaseInputEmail,
    BaseInputPassword,
    BaseInputText,
    BaseButton,
    BaseSelectInput,
    BaseRadioGroupPanel,
    BaseInputMultiSelectCustom,
    BaseInputCheckbox,
    BaseRadioButtons: BaseRadioButtons as typeof BaseRadioButtons<number | string>,
    BaseInputTextarea,
    BaseInputDate,
    BaseInputFile,
  },

  props: {
    ...DisabledProp,

    /** The form field to render */
    field: {
      type: Object as PropType<FormField>,
      required: true,
    },

    /** The form data */
    formData: {
      type: Object as PropType<FormData>,
      required: true,
    },

    /** Hide labels */
    hideLabel: {
      required: true,
      type: Boolean,
    },

    ...OptionalErrorBagProp,
  },

  emits: {
    "update:modelValue": (_val: FormDataValue) => true,
  },

  setup(props) {
    const { getComponentRef } = useRefComposable();

    const { updateModelValue } = useModelValue<FormDataValue>();

    const getComponentFieldRefName = (): string => {
      return `field-component-${props.field.key}`;
    };

    const getFieldComponentRef = (): ComponentFieldComponentInstance => {
      const refName = getComponentFieldRefName();
      const ref = getComponentRef<ComponentFieldComponentInstance>(refName);

      /* istanbul ignore next -- @preserve */
      if (!ref) {
        throw new Error(`Field component ref ${refName} not found`);
      }

      return ref;
    };

    const validate = (): boolean => {
      /* istanbul ignore else -- @preserve */
      if (props.field.type === "component") {
        const ref = getFieldComponentRef();

        return ref.validate();
      }

      /* istanbul ignore next -- @preserve */
      return true;
    };

    return {
      updateModelValue,
      getComponentFieldRefName,
      getFieldComponentRef,
      validate,
    };
  },

  methods: {
    getComponentProps(field: ComponentField) {
      // @ts-expect-error - Defensive code to handle missing component prop.
      const props = field.component["props"] !== undefined ? field.component.props : {};

      // Prevent vue errors by not passing props to components that don't accept them.
      const disallowProps = "render" in field.component;

      return {
        ...("componentProps" in field ? /* istanbul ignore next -- @preserve */ field.componentProps : {}),
        ...(!disallowProps || "requestErrorBag" in props ? { requestErrorBag: this.errorBag } : {}),

        ...(!disallowProps || "errorMessage" in props
          ? {
              errorMessage: this.errorBag.findFirstMessageByFieldName(field.key),
            }
          : {}),

        ...(!disallowProps || "errorMessageId" in props
          ? {
              errorMessageId: `error-${field.key}`,
            }
          : {}),

        ...(!disallowProps || "modelValue" in props ? { modelValue: this.getFormDataKey(field.key) } : {}),

        ...(!disallowProps || "disabled" in props ? { disabled: this.disabled } : {}),
      };
    },

    emitUpdateModelValueEvent(val: FormDataValue) {
      this.updateModelValue(val);
    },

    getFormDataInputTextFieldValue(key: string): string | null | undefined {
      const value = this.getFormDataKey(key);

      /* istanbul ignore next -- @preserve */
      if (typeof value !== "string" && value !== undefined && value !== null) {
        throw new TypeError(`Form data text field ${key} must be a string or undefined or null`);
      }

      return value;
    },

    getFormDataRadioGroupPanelValue(key: string): boolean | string | null | undefined {
      const value = this.getFormDataKey(key);

      /* istanbul ignore next -- @preserve */
      if (typeof value !== "string" && value !== undefined && value !== null && typeof value !== "boolean") {
        throw new TypeError(`Form data radio group panel ${key} invalid value`);
      }

      return value;
    },

    getFormDataRadioButtonsValue(key: string): number | string | null | undefined {
      const value = this.getFormDataKey(key);

      /* istanbul ignore next -- @preserve */
      if (typeof value !== "string" && typeof value !== "number" && value !== undefined && value !== null) {
        throw new TypeError(`Form data radio button ${key} invalid value`);
      }

      return value;
    },

    getFormDataMultiSelectFieldValue(key: string): number[] | string[] | null | undefined {
      const value = this.getFormDataKey(key);

      /* istanbul ignore next -- @preserve */
      if (!Array.isArray(value) && value !== undefined && value !== null) {
        throw new TypeError(`Form data multi select field ${key} must be an array or undefined or null`);
      }

      return value;
    },

    getFormDataCheckboxFieldValue(key: string) {
      const value = this.getFormDataKey(key);

      /* istanbul ignore next -- @preserve */
      if (typeof value !== "boolean" && value !== undefined && value !== null) {
        throw new TypeError(`Form data checkbox field ${key} must be a boolean or undefined or null`);
      }

      return value;
    },

    getFormDataSelectFieldValue(key: string): FormDataValue {
      return this.getFormDataKey(key);
    },

    getFormDataKey(key: string): FormDataValue {
      return get(this.formData, key);
    },
  },
});
</script>
