import z from "zod";

const TagSchema = z.object({
  /** The primary key. Can be passed to issue API endpoints. */
  company_tag_id: z.number().optional(),
  tag_id: z.number().optional(),
  name: z.string(),
  identifier: z.string(),
});

const CompanyTagSchema = z.object({
  company_tag_id: z.number(),
  name: z.string(),
  identifier: z.string(),
});

export { CompanyTagSchema };

export default TagSchema;
