import z from "zod";

export const MediaSchemaObject = {
  id: z.number().int(),
  file_name: z.string(),
  name: z.string(),
  mime_type: z.string(),
  has_preview: z.boolean(),
  full_url: z.string().url(),
  conversions: z.record(z.string(), z.string().url()).nullable(),
};

const MediaSchema = z.object(MediaSchemaObject);

export default MediaSchema;
