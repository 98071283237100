import MediaSchema from "@/media/schemas/Media.ts";
import z from "zod";

const EmailSchema = z.string().email();

/** Parses a user object */
const UserSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string().nullable(),
  full_name: z.string(),
  email: EmailSchema,
  avatar: MediaSchema.nullish(),
});

const createFirstNameSchema = (
  stringOptions?: Parameters<(typeof z)["string"]>[0],
  minOptions?: Parameters<z.ZodString["min"]>[1],
  maxOptions?: Parameters<z.ZodString["max"]>[1]
): z.ZodString => z.string(stringOptions).min(1, minOptions).max(50, maxOptions);

const FirstNameSchema = createFirstNameSchema();

const createLastNameSchema = (
  stringOptions?: Parameters<(typeof z)["string"]>[0],
  minOptions?: Parameters<z.ZodString["min"]>[1],
  maxOptions?: Parameters<z.ZodString["max"]>[1]
): z.ZodString => z.string(stringOptions).min(1, minOptions).max(50, maxOptions);

const LastNameSchema = createLastNameSchema();

/** Parses a partial user object */
const PartialUserSchema = z.object({
  full_name: z.string(),
});

const OptionalPartialUserSchema = z.object({
  full_name: z.string().nullable(),
});

/** Parses an array of users */
const UsersSchema = z.array(UserSchema);

export {
  createLastNameSchema,
  createFirstNameSchema,
  EmailSchema,
  FirstNameSchema,
  LastNameSchema,
  OptionalPartialUserSchema,
  PartialUserSchema,
  UserSchema,
  UsersSchema,
};
