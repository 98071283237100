<template>
  <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center main-page-wrapper">
      <p class="text-base font-semibold text-blue-600">{{ errorStatusCode ?? "Error" }}</p>

      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        {{ errorTitle }}
      </h1>

      <p class="mt-6 text-base leading-7 text-gray-600">{{ errorDescription }}</p>

      <div class="mt-5 flex items-center justify-center gap-x-6">
        <a
          v-if="isBorked"
          href="/logout"
          class="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Logout
        </a>

        <RouterLink
          v-else
          class="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          :to="routeLocationRaw"
          >{{ routerLinkText }}</RouterLink
        >
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

/**
 * The error page component
 *
 * - Displays the error message and the status code
 * - Displays a link to go back to the home page
 */
export default defineComponent({
  name: "TheBaseErrorPage",
  expose: [],
  props: {
    /**
     * The HTTP status code of the error
     */
    statusCode: {
      required: false,
      type: Number as PropType<number | undefined>,
      default: undefined,
      validator: (value: number) => {
        return value >= 400 && value < 600;
      },
    },

    /**
     * The error message
     */
    errorMessage: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
      validator: (errorMessage: string) => typeof errorMessage === "string",
    },

    /**
     * The error title string
     */
    error: {
      required: false,
      type: String,
      default: undefined,
    },

    /**
     * Whether the user is authenticated
     *
     * - Used to determine whether to display the link to the home page
     */
    isAuthenticated: {
      required: true,
      type: Boolean,
      validator: (value: boolean) => typeof value === "boolean",
    },

    /**
     * Whether the page is borked
     *
     * - Used to determine whether to display the link to the home page
     */
    isBorked: {
      required: false,
      type: Boolean as PropType<boolean | undefined>,
      default: false,
    },
  },

  computed: {
    /**
     * The route location object to the home page
     *
     * - If user is authenticated, the home page is displayed
     * - If user is not authenticated, the login page is displayed
     * - If user is authenticated and the status code is 401, the login page is displayed.
     *   This is because we can assume that the user is not authenticated
     * @returns The route location object to the home page
     */
    routeLocationRaw() {
      if (this.isAuthenticated && this.statusCode !== 401) {
        return { name: "home" };
      }
      return { name: "login" };
    },

    /**
     * Get the text of the router link
     * @returns The text of the link
     */
    routerLinkText() {
      if (this.isAuthenticated && this.statusCode !== 401) {
        return "Go back home";
      }
      return "Login";
    },

    errorStatusCode() {
      if (this.statusCode !== undefined && this.statusCode >= 400 && this.statusCode < 600) {
        return this.statusCode;
      }
      return undefined;
    },

    /**
     * The title of the error page
     *
     * - Populated via the status code
     * @returns The title of the error page
     */
    errorTitle() {
      if (this.error !== undefined && this.error !== "") {
        return this.error;
      }
      if (this.statusCode === 401) {
        return "Unauthorized";
      }
      if (this.statusCode === 403) {
        return "Forbidden";
      }
      if (this.statusCode === 404) {
        return "Page not found";
      }
      if (this.statusCode === 408) {
        return "Request timeout";
      }
      if (this.statusCode === 429) {
        return "Too many requests";
      }
      if (this.statusCode !== undefined && this.statusCode >= 500 && this.statusCode < 600) {
        return "Server error";
      }
      return "Unknown error";
    },

    /**
     * The description of the error page
     * @returns The description of the error page
     */
    errorDescription() {
      if (this.errorMessage !== undefined) {
        return this.errorMessage;
      }
      if (this.statusCode === 401) {
        return "You are not authorized to access this page. Please login and try again.";
      }
      if (this.statusCode === 403) {
        return "You are not authorized to access this page.";
      }
      if (this.statusCode === 404) {
        return "Sorry, we couldn’t find the page you’re looking for.";
      }
      if (this.statusCode === 408) {
        return "The server timed out waiting for the request. Please try again later.";
      }
      if (this.statusCode === 429) {
        return "You have sent too many requests in a given amount of time. Please try again later.";
      }
      if (this.statusCode !== undefined && this.statusCode >= 500 && this.statusCode < 600) {
        return "An error occurred on the server. Please try again later.";
      }
      return "An unknown error occurred. Please try again later.";
    },
  },
});
</script>
