<template>
  <form
    ref="form"
    :title="title"
    :aria-label="title"
    aria-describedby="form-error"
    :class="{ 'border rounded-xl': layoutOptions.type === 'inline' && layoutOptions.noBorder !== true }"
  >
    <div class="space-y-12">
      <div
        v-for="(section, sectionIdx) in baseForm"
        :key="section.key"
        data-testid="section-border"
        :class="{
          'border-b border-gray-900/10':
            layoutOptions.type === 'stacked' && layoutOptions.noBorder !== true && layoutOptions.noSections !== true,
          'pb-12': layoutOptions.type === 'stacked' && layoutOptions.noSections !== true,
          'lg:grid grid-cols-3 gap-x-3': layoutOptions.type === 'stacked' && layoutOptions.inlineSections === true,
        }"
      >
        <div
          v-if="!section.hideSection && layoutOptions.noSections !== true"
          :class="{
            'py-6': layoutOptions.type === 'inline',
            'px-4 sm:px-6': layoutOptions.type === 'inline' && layoutOptions?.noHorizontalPadding !== true,
            'col-span-1 col-start-1': layoutOptions.type === 'stacked' && layoutOptions.inlineSections === true,
          }"
          data-testid="section-container"
        >
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            {{ section.title }}
          </h2>

          <p v-if="section.description" class="mt-1 text-sm leading-6 text-gray-600" data-testid="section-description">
            {{ section.description }}
          </p>
        </div>

        <BaseFormSectionLayout
          :layout-options="layoutOptions"
          :section="section"
          :hide-bottom-border-for-inline-layout="sectionIdx === baseForm.length - 1 && hideButtonControls"
        >
          <template v-for="field in section.fields">
            <BaseFormInputLayout
              v-if="field.isHidden === false"
              :id="`field-${field.key}`"
              :key="field.key"
              :field="field"
              :layout-options="layoutOptions"
            >
              <template #default="{ hideInputLabel }">
                <BaseFormInput
                  :ref="`field-input-${field.key}`"
                  :field="field"
                  :disabled="disableForm"
                  :error-bag="displayErrors"
                  :form-data="formData"
                  :hide-label="hideInputLabel"
                  @update:model-value="handleFieldUpdate(field, $event)"
                />
              </template>
            </BaseFormInputLayout>
          </template>
        </BaseFormSectionLayout>
      </div>
    </div>

    <!-- #region Error Messages -->
    <p
      v-show="displayErrorMessage"
      id="form-error"
      :aria-hidden="displayErrorMessage === undefined"
      role="alert"
      aria-live="polite"
      class="mt-2 text-sm text-red-600 text-right"
      :class="{ 'px-4 sm:px-6': layoutOptions.type === 'inline' && layoutOptions.noHorizontalPadding !== true }"
    >
      {{ displayErrorMessage }}
    </p>
    <!-- #endregion -->

    <!-- #region Form Buttons -->
    <div
      v-if="!hideButtonControls"
      data-testid="form-buttons"
      class="flex items-center justify-end gap-x-6"
      :class="{
        'px-4 py-4 sm:py-6 sm:px-6': layoutOptions.type === 'inline' && layoutOptions.noHorizontalPadding !== true,
        'pt-4 sm:pt-6': layoutOptions.type === 'inline' && layoutOptions.noHorizontalPadding === true,
        'mt-6': layoutOptions.type === 'stacked',
      }"
    >
      <slot name="before-button-controls" />

      <BaseButton
        v-if="enableCancelButton"
        type="button"
        theme="white"
        button-text="Cancel"
        :disabled="disableForm"
        size="xl"
        @click="$emit('cancel')"
      />

      <BaseButton :disabled="disableForm || disableSubmit" type="submit" :button-text="submitButtonText" size="xl" />

      <slot name="after-button-controls" />
    </div>
    <!-- #endregion -->
  </form>
</template>

<script lang="ts">
import { useRefComposable } from "@/base/composables/RefComposable.ts";
import useFormOnSubmitListenerComposable from "@/base/composables/UseFormOnSubmitListenerComposable.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import { getSmallColSpanClass } from "@/base/functions/css/grid/GridFunctions.ts";
import type { ErrorBagInterface } from "@/validation/classes/ErrorBag.ts";
import ErrorBag from "@/validation/classes/ErrorBag.ts";
import UseErrorBag from "@/validation/composables/ErrorBagComposable.ts";
import useValidatorComposable from "@/validation/composables/ValidatorComposable.ts";
import { get, has, map, set, unset } from "lodash";
import type { PropType, Ref } from "vue";
import { defineComponent, ref } from "vue";
import type { ZodSchema } from "zod";
import z from "zod";
import BaseButton from "../buttons/BaseButton.vue";
import BaseFormInput from "./BaseFormInput.vue";
import type { LayoutOptions } from "./BaseFormInputLayout.vue";
import BaseFormInputLayout from "./BaseFormInputLayout.vue";
import type {
  ComponentField,
  ComponentFieldComponentInstance,
  FormData,
  FormDataValue,
  FormField,
  FormSection,
  PropFormField,
  PropFormSection,
} from "./BaseFormInterface.ts";
import BaseFormSectionLayout from "./BaseFormSectionLayout.vue";

/**
 * Form Component
 *
 * - Handles multiple form sections
 * - Inputs with different types: email, password
 * - Validation/Parsing via Zod
 * - Submitting
 * @author Jamie Wood
 * @see https://tailwindui.com/components/application-ui/forms/form-layouts#component-1c3f9860d66ce5cd9fb35ba7fde6721a
 *    based on tailwindui stacked layout
 * @todo Rewrite component with Typescript Generics
 */
export default defineComponent({
  name: "BaseForm",
  expose: ["setFieldData"],
  components: {
    BaseButton,
    BaseFormInput,
    BaseFormInputLayout,
    BaseFormSectionLayout,
  },

  props: {
    /**
     * The form sections
     *
     * - This breaks the form into multiple segments
     */
    sections: {
      required: true,
      type: Array as PropType<PropFormSection[]>,
    },

    /**
     * The form fields
     *
     * - If the fields section key does not match a sections key they will not render
     */
    fields: {
      required: true,
      type: Array as PropType<PropFormField[]>,
    },

    /**
     * Prevent form interaction
     */
    disableForm: {
      required: false,
      type: Boolean,
    },

    /**
     * Disable the submit button
     */
    disableSubmit: {
      required: false,
      type: Boolean,
    },

    /**
     * An error bag containing errors from an external source
     *
     * - Has precedent over validation errors
     * - Good idea to clear this prop in the parent after a `submitAttempted` event is emitted
     */
    requestErrorBag: {
      required: false,
      type: [Object, undefined] as PropType<ErrorBagInterface | undefined>,
      validator: (errorBag) => errorBag === undefined || errorBag instanceof ErrorBag,
      default: undefined,
    },

    /**
     * An error from the server
     *
     * - Displays if no error bag errors are present
     */
    requestErrorMessage: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * The accessible title of the form
     */
    title: {
      required: true,
      type: String,
      validator: (title) => assertStringIsNotBlank(title),
    },

    /** Hide the save button */
    hideButtonControls: {
      required: false,
      type: Boolean,
    },

    /** Submit the form on input */
    submitOnInput: {
      required: false,
      type: Boolean,
    },

    /**
     * Enable the cancel button
     *
     * hideButtonControls gets precedence over this prop
     */
    enableCancelButton: {
      required: false,
      type: Boolean,
    },

    /** The form layout options */
    layoutOptions: {
      type: Object as PropType<LayoutOptions>,
      required: false,
      default: () => ({
        type: "stacked" as const,
      }),
    },

    /**
     * The Zod schema used to validate the form.
     *
     * - Overrides the FormField zodSchema option
     * - Intended to be main way to validate form moving forward as FormField zodSchema option will be deprecated
     */
    zodSchema: {
      required: false,
      type: Object as PropType<ZodSchema | undefined>,
      default: undefined,
    },

    /**
     * Set form data keys from computed callbacks.
     *
     * Supports dot notation.
     * Key gets added to the form data if it does not exist.
     * Key gets removed from the form data if it is hidden.
     */
    computedProperties: {
      required: false,
      type: Array as PropType<((formData: FormData) => { key: string; value: unknown; isHidden?: boolean })[]>,
      default: () => [],
    },

    /** What the text of the submit button will be */
    submitButtonText: {
      type: String,
      required: false,
      default: "Save",
    },
  },

  emits: {
    submitAttempted: () => true,
    submit: (_payload: unknown) => true,
    cancel: () => true,
    "update:field": (_field: { key: string; value: unknown }) => true,
  },

  setup(_props, context) {
    const { getComponentRef } = useRefComposable();

    // the form's data
    const formData = ref({}) as Ref<FormData>;

    const baseForm = ref([]) as Ref<FormSection[]>;

    const form = ref() as Ref<HTMLFormElement>;

    // the schema used to validate the form's data
    const schema: Ref<ZodSchema> = ref(z.object({}));
    // the error bag used to store errors
    const { errorBag } = UseErrorBag();
    // the validate function used to validate the form
    const { validate } = useValidatorComposable(schema);

    const getFieldInputRefName = (field: ComponentField): string => {
      return `field-input-${field.key}`;
    };

    const getFieldInputRef = (field: ComponentField): ComponentFieldComponentInstance => {
      const fieldInputRefName = getFieldInputRefName(field);
      const fieldInputRef = getComponentRef<ComponentFieldComponentInstance>(fieldInputRefName);

      /* istanbul ignore if -- @preserve */
      if (fieldInputRef == null) {
        throw new TypeError(`Cannot get ref for ${fieldInputRefName}`);
      }

      return fieldInputRef;
    };

    const validateComponentFields = (): boolean => {
      return baseForm.value.every((section) => {
        return section.fields.every((field) => {
          return field.type === "component" && !field.isHidden ? getFieldInputRef(field).validate() : true;
        });
      });
    };

    // submit listener used to emit a submit event
    const { addFormSubmitListener, submitForm } = useFormOnSubmitListenerComposable(
      () => {
        context.emit("submit", formData.value);
      },
      () => {
        const validComponentFields = validateComponentFields();
        const res = validate(formData.value);

        errorBag.value = res.errorBag;

        const firstKey = errorBag.value.firstErrorKey();

        if (typeof firstKey === "string") {
          const scrollElement = document.getElementById(`field-${firstKey}`);

          /* istanbul ignore else -- @preserve */
          if (scrollElement !== null) {
            scrollElement.scrollIntoView({
              block: "center",
            });
          }
        }

        if (res.data !== undefined) {
          formData.value = res.data;
        }
        return res.success && validComponentFields;
      }
    );
    return {
      baseForm,
      formData,
      errorBag,
      validate,
      schema,
      addFormSubmitListener,
      submitForm,
      form,
    };
  },

  computed: {
    displayErrors() {
      return this.requestErrorBag && !this.requestErrorBag.isEmpty() ? this.requestErrorBag : this.errorBag;
    },

    // The displayErrorMessage function returns the requestErrorMessage
    // if the displayErrors array is empty, otherwise it returns undefined.
    displayErrorMessage() {
      return this.displayErrors.isEmpty() ? this.requestErrorMessage : undefined;
    },
  },

  watch: {
    fields: {
      deep: true,
      handler(fields, oldFields) {
        const newKeys = map(fields, "key");
        const oldKeys = map(oldFields, "key");

        const keysChanged = newKeys.some((key) => !oldKeys.some((oldKey) => oldKey === key));

        if (keysChanged) {
          throw new Error(
            "Currently BaseForm does not support adding or removing fields. Please use the isHidden property to dynamically hide fields."
          );
        }

        // Does not call setInitialFormData as it would wipe out the form data.
        this.setFormAfterInitialFormData(); // Need to reset the form fields as they could have changed.
      },
    },
  },

  created() {
    this.setInitialFormData(); // Has to happen first so we can check if fields are hidden.
    this.setFormAfterInitialFormData();
  },

  mounted() {
    this.addFormSubmitListener(this.form);
  },

  methods: {
    setFieldData(key: string, value: unknown) {
      set(this.formData, key, value);
    },

    setFormAfterInitialFormData() {
      this.setForm();
      this.updateFormFields(); // Has to happen before setComputedFormData so computed properties can be set accurately.
      this.setFormDataSchema();
      this.setComputedFormData();
    },

    // This function creates a Zod schema from the fields.
    // The schema is used to validate the form data before it is submitted.
    setFormDataSchema() {
      // If a Zod schema is provided, use that instead
      if (this.zodSchema !== undefined) {
        this.schema = this.zodSchema;

        return;
      }

      this.setOldFormDataSchema();
    },

    /**
     * Old way of creating a Zod schema.
     *
     * Constructs a Zod schema from the form fields.
     * @deprecated
     */
    setOldFormDataSchema() {
      // Create an empty object to store the Zod schema
      let objectSchema = z.object({});

      // Loop through each section
      this.baseForm.forEach((section) => {
        // Loop through each field
        section.fields.forEach((field) => {
          // Start with a basic schema for strings that are required
          let fieldSchema: ZodSchema = z.unknown();

          // If the field already has a Zod schema, use that instead

          if ("zodSchema" in field ? field.zodSchema != null : false) {
            // eslint-disable-next-line jsdoc/check-tag-names
            /** @ts-expect-error Asserted there is a zod schema in if statement */
            fieldSchema = field.zodSchema;
          } else if (field.type === "password") {
            // If the field is a password, require it to be at least 8 characters

            fieldSchema = z.string().trim().min(8);
          } else if (field.type === "email") {
            // If the field is an email, require it to be a valid email address
            fieldSchema = z.string().trim().email();
          }

          objectSchema = objectSchema.merge(z.object({ [field.key]: fieldSchema }));
        });
      });

      // Create a new object schema from the Zod schemas
      this.schema = objectSchema;
    },

    /**
     * Handle form data update after a field is updated
     * @param fieldToUpdate The field that was updated
     * @param value The new value of the field
     */
    handleFieldUpdate(fieldToUpdate: FormField, value: FormDataValue) {
      // Update the form data field.
      this.setFormDataKey(fieldToUpdate.key, value, false);

      this.updateFormFields();

      this.setComputedFormData(); // Must happen after updating form fields as we need a fully updated formData

      this.$emit(`update:field`, { key: fieldToUpdate.key, value });

      // Next tick so component fields are rendered when toggling isHidden.
      this.$nextTick()
        .then(() => {
          // Submit form if submitOnInput feature is enabled.
          if (this.submitOnInput) {
            this.submitForm(this.form);
          }
        })
        .catch(
          /* istanbul ignore next -- @preserve */ (error) => {
            throw error;
          }
        );
    },

    /**
     * Loop through each form field and call the callbacks
     * @param callbacks The callbacks to call
     */
    callCallbacksWithEachFormField(callbacks: ((field: FormField) => void)[]) {
      this.baseForm.forEach((section) => {
        section.fields.forEach((field) => {
          callbacks.forEach((callback: (field: FormField) => void) => {
            callback(field);
          });
        });
      });
    },

    updateFormFields() {
      const callbacks = [
        this.updateIsHidden,
        this.updateFormDataKey, // Has to happen after updateIsHidden
        this.updateComponentProps,
      ];

      this.callCallbacksWithEachFormField(callbacks);
    },

    /**
     * When a field is updated, update form field component props.
     * @param formField The field to update
     */
    updateComponentProps(formField: FormField) {
      if (formField.type !== "component" || formField.getComponentProps === undefined) {
        return;
      }

      // eslint-disable-next-line no-param-reassign
      formField.componentProps = formField.getComponentProps(this.formData);
    },

    /**
     * Hide form fields that should be hidden
     *
     * Calls the isHidden function and removes the field from the `formData` if it is hidden
     * @param formField The field to update if it is hidden
     */
    updateIsHidden(formField: FormField) {
      if (!("checkIsHidden" in formField)) {
        return;
      }

      const isHidden = formField.checkIsHidden(this.formData);

      // Check if the field's visibility was changed
      if (isHidden !== formField.isHidden) {
        // Update the field's visibility
        // eslint-disable-next-line no-param-reassign
        formField.isHidden = isHidden;
      }
    },

    /**
     * Set `formData` from the form fields
     *
     * Should be used to set the `formData` after the form is created.
     * @param formField The field to update
     */
    updateFormDataKey(formField: FormField) {
      if (formField.isHidden) {
        // Supports dot notation
        unset(this.formData, formField.key);

        const isNested = formField.key.includes(".");

        // Remove parent object if is now empty.
        if (isNested) {
          const parentKey = formField.key.split(".").slice(0, -1).join(".");
          const parentValue = get(this.formData, parentKey);

          if (parentValue instanceof Object && Object.keys(parentValue).length === 0) {
            unset(this.formData, parentKey);
          }
        }

        return;
      }

      // No need to set the field if it already exists in the formData
      if (has(this.formData, formField.key)) {
        return;
      }

      const hasDefaultValue = "defaultValue" in formField;

      // Add the key back to the formData
      this.setFormDataKey(formField.key, formField.defaultValue, !hasDefaultValue);
    },

    setComputedFormData() {
      this.computedProperties.forEach((callback) => {
        const computedProperty = callback(this.formData);

        if (computedProperty.isHidden === true) {
          unset(this.formData, computedProperty.key);

          return;
        }

        this.setFormDataKey(computedProperty.key, computedProperty.value, false);
      });
    },

    /**
     * Set the `formData` from the props
     *
     * Should only be called once on component creation.
     * This is because it does not check `isHidden` fields.
     */
    setInitialFormData() {
      this.fields.forEach((propField) => {
        const valueConfigured = "initialValue" in propField || "defaultValue" in propField;

        this.setFormDataKey(
          propField.key,
          "initialValue" in propField && propField.initialValue !== undefined
            ? propField.initialValue
            : propField.defaultValue,
          !valueConfigured
        );
      });
    },

    setFormDataKey(key: string, value: unknown | undefined, isValueAbsent?: boolean) {
      const modifiedValue = isValueAbsent === false ? value : undefined;

      this.setFieldData(key, modifiedValue);
    },

    // Set the form to be displayed
    setForm() {
      // 1. Map over the sections
      this.baseForm = this.sections.map((section) => {
        // 2. Get all fields in the current section
        const sectionFields = this.fields
          .filter((field) => field.sectionKey === section.key)
          .map((field) => {
            // 3. Set a class based on the field's colSize
            return {
              ...field,
              class: this.layoutOptions.type === "stacked" ? getSmallColSpanClass(field.colSize ?? "full") : "",
              isHidden: false, // Gets set by updateFormFields
              hideLabel: "hideLabel" in field ? field.hideLabel : undefined,
            };
          });
        // 4. Return the section with the fields
        return {
          ...section,
          hideSection: section.hideSection !== undefined ? section.hideSection : false,
          fields: sectionFields,
        };
      });
    },
  },
});
</script>
