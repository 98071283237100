import { debounce, type DebouncedFunc } from "lodash";
import type { Ref } from "vue";
import { computed, ref } from "vue";

/**
 * Reusable code for debouncing a task or process.
 * @param debounceMs Debounce timeout in milliseconds
 * @param callback What to call after a debounce
 * @param mode Debounce mode
 * @returns code
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useDebounce<CallbackType extends (...args: any[]) => any>(
  debounceMs: Ref<number | undefined>,
  callback: CallbackType,
  mode: "debounceAfterInvoke" | "debounceAnyInvoke"
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
): {
  invokeDebouncedCallback: (...args: Parameters<CallbackType>) => ReturnType<CallbackType> | undefined;
} {
  const debounceFunction = ref<DebouncedFunc<CallbackType> | undefined>();

  const usesDebounce = computed(() => {
    return debounceMs.value !== undefined && debounceMs.value > 0;
  });

  // @todo uncomment when used
  // const flushDebounce = (): void => {
  //   /* istanbul ignore else -- @preserve */
  //   if (debounceFunction.value) {
  //     debounceFunction.value.flush();
  //   }
  // };

  const invokeDebouncedCallback = (...args: Parameters<CallbackType>): ReturnType<CallbackType> | undefined => {
    let rDebounceFunction = debounceFunction.value;

    if (usesDebounce.value) {
      if (mode === "debounceAfterInvoke") {
        if (rDebounceFunction != null) {
          return rDebounceFunction(...args);
        }

        debounceFunction.value = debounce(callback, debounceMs.value);
      } else {
        if (rDebounceFunction == null) {
          debounceFunction.value = debounce(callback, debounceMs.value);
          rDebounceFunction = debounceFunction.value;
        }

        return rDebounceFunction(...args);
      }
    }

    return callback(...args);
  };

  return {
    // flushDebounce,
    invokeDebouncedCallback,
  };
}
