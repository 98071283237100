import { defineStore } from "pinia";
import type { Ref } from "vue";
import { ref } from "vue";
import type StackNotificationInterface from "../components/notifications/interfaces/StackNotificationInterface.ts";

/**
 * A store for managing global application notifications
 */
const useNotificationStore = defineStore("NotificationStore", () => {
  /** A history of notifications */
  const history: Ref<StackNotificationInterface[]> = ref([]);
  /**
   * The latest notification
   *
   * - We use this ref to the listen to notification pushes and display the latest notification
   */
  const latest: Ref<StackNotificationInterface | undefined> = ref(undefined);

  /**
   * Pushes a notification to the history and sets the latest notification
   * @param notification The notification to push
   * @param notification.title The notification title
   * @param notification.message The notification message
   * @param notification.duration The notification duration
   * @param notification.type The notification type
   */
  function push(notification: { title: string; message?: string; type: "error" | "success"; duration?: number }): void {
    const stackNotification: StackNotificationInterface = {
      ...notification,
      key: history.value.length + 1,
    };
    history.value.push(stackNotification);
    latest.value = stackNotification;
  }

  return { history, latest, push };
});

export default useNotificationStore;
