<template>
  <dl :class="dlClass">
    <BaseStatCard v-for="item in stats" :key="item.title" v-bind="item"></BaseStatCard>
  </dl>
</template>

<script lang="ts">
import type Stat from "@/base/interfaces/StatInterface";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseStatCard from "./BaseStatCard.vue";

export default defineComponent({
  name: "BaseStatCardContainer",
  components: {
    BaseStatCard,
  },

  expose: [],
  props: {
    /** the array of stats to display */
    stats: {
      required: true,
      type: Array as PropType<Stat[]>,
    },

    /** the number of cols to use, first number is normal, second is small */
    cols: {
      required: false,
      type: Array as PropType<number[]>,
      default: () => [2, 3, 4],
      validator: (cols: number[]) => cols.length === 3,
    },
  },

  computed: {
    dlClass() {
      let base = "mt-5 grid gap-4";
      base += this.gridClass("sm", this.cols[0]);
      base += this.gridClass("md", this.cols[1]);
      base += this.gridClass("lg", this.cols[2]);
      return base;
    },
  },

  methods: {
    gridClass(size: string, col: number | undefined) {
      let base = "";
      switch (col) {
        case 1:
          switch (size) {
            case "sm":
              base = " sm:grid-cols-1";
              break;
            case "lg":
              base = " lg:grid-cols-1";
              break;
            default:
              base = " grid-cols-1";
              break;
          }
          break;
        case 2:
          switch (size) {
            case "sm":
              base = " sm:grid-cols-2";
              break;
            case "lg":
              base = " lg:grid-cols-2";
              break;
            default:
              base = " grid-cols-2";
              break;
          }
          break;
        case 3:
          switch (size) {
            case "sm":
              base = " sm:grid-cols-3";
              break;
            case "lg":
              base = " lg:grid-cols-3";
              break;
            default:
              base = " grid-cols-3";
              break;
          }
          break;
        case 4:
          switch (size) {
            case "sm":
              base = " sm:grid-cols-4";
              break;
            case "lg":
              base = " lg:grid-cols-4";
              break;
            default:
              base = " grid-cols-4";
              break;
          }
          break;
        case 5:
          switch (size) {
            case "sm":
              base = " sm:grid-cols-5";
              break;
            case "lg":
              base = " lg:grid-cols-5";
              break;
            default:
              base = " grid-cols-5";
              break;
          }
          break;
        default:
          switch (size) {
            case "sm":
              base = " sm:grid-cols-6";
              break;
            case "lg":
              base = " lg:grid-cols-6";
              break;
            default:
              base = " grid-cols-6";
              break;
          }
          break;
      }
      return base;
    },
  },
});
</script>
