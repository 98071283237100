<template>
  <div>
    <span :class="badgeClass" role="status" aria-live="polite" :aria-label="htmlAriaLabel">
      <svg
        v-if="withoutDot !== true"
        data-testid="base-badge-dot"
        :class="dotClass"
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx="3" cy="3" r="3" />
      </svg>
      {{ badgeText }}
      <button v-if="withIcon" type="button" :class="buttonClass" @click="handleClick()">
        <FontAwesomeIcon
          :icon="['fas', 'xmark']"
          :class="iconClass"
          data-testid="base-badge-icon"
          title="close badge icon"
        ></FontAwesomeIcon>
      </button>
    </span>
  </div>
</template>

<script lang="ts">
import { library } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { PropType } from "vue";
import { defineComponent } from "vue";

library.add(faXmark);

type BadgeColour = "blue" | "gray" | "green" | "red" | "yellow";

export default defineComponent({
  name: "BaseBadge",
  components: { FontAwesomeIcon },
  expose: [],
  props: {
    /**
     * HTML button type attribute
     */
    badgeText: {
      required: false,
      type: String,
      validator: (type) => typeof type === "string",
      default: "badge",
    },

    /**
     * The colour of the badge
     */
    badgeColour: {
      required: false,
      type: String as PropType<BadgeColour>,
      validator: (type: string) => ["blue", "gray", "green", "red", "yellow"].includes(type),
      default: "gray",
    },

    /** Whether the badge should render the dot icon or not */
    withoutDot: {
      required: false,
      type: Boolean,
    },

    /** Whether the badge should render the dot icon or not */
    withIcon: {
      required: false,
      type: Boolean,
    },

    /** The aria-label attribute for the badge */
    htmlAriaLabel: {
      required: true,
      type: String,
    },
  },

  emits: {
    click: () => true,
  },

  computed: {
    /**
     * Computed variable which contains classes for button styling
     * @returns returns array of classes for button
     */
    badgeClass() {
      let cssClass = "bg-gray-100 text-gray-600";
      switch (this.badgeColour) {
        case "red":
          cssClass = "bg-red-100 text-red-700";
          break;
        case "green":
          cssClass = "bg-green-100 text-green-700";
          break;
        case "blue":
          cssClass = "bg-blue-100 text-blue-700";
          break;
        case "yellow":
          cssClass = "bg-yellow-100 text-yellow-800";
          break;
        default:
          break;
      }
      return ["inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium", cssClass];
    },

    /**
     * Computed variable which contains classes for dot styling
     * @returns returns array of classes for dot
     */
    dotClass() {
      let cssClass = "fill-gray-400";
      switch (this.badgeColour) {
        case "red":
          cssClass = "fill-red-400";
          break;
        case "yellow":
          cssClass = "fill-yellow-400";
          break;
        case "green":
          cssClass = "fill-green-400";
          break;
        case "blue":
          cssClass = "fill-blue-400";
          break;
        default:
          break;
      }
      return ["h-1.5 w-1.5", cssClass];
    },

    /**
     * Computed variable which contains classes for button styling
     * @returns returns array of classes for button
     */
    buttonClass() {
      let cssClass = "hover:bg-gray-500/20";
      switch (this.badgeColour) {
        case "red":
          cssClass = "hover:bg-red-600/20";
          break;
        case "yellow":
          cssClass = "hover:bg-yellow-600/20";
          break;
        case "green":
          cssClass = "hover:bg-green-600/20";
          break;
        case "blue":
          cssClass = "hover:bg-blue-600/20";
          break;
        default:
          break;
      }
      return ["group relative -mr-1 h-3.5 w-3.5 rounded-sm", cssClass];
    },

    /**
     * Computed variable which contains classes for icon styling
     * @returns returns array of classes for icon
     */
    iconClass() {
      let cssClass = "stroke-gray-700/50 group-hover:stroke-gray-700/75";
      switch (this.badgeColour) {
        case "red":
          cssClass = "stroke-red-700/50 group-hover:stroke-red-700/75";
          break;
        case "yellow":
          cssClass = "stroke-yellow-800/50 group-hover:stroke-yellow-800/75";
          break;
        case "green":
          cssClass = "stroke-green-800/50 group-hover:stroke-green-800/75";
          break;
        case "blue":
          cssClass = "stroke-blue-800/50 group-hover:stroke-blue-800/75";
          break;
        default:
          break;
      }
      return ["h-3.5 w-3.5", cssClass];
    },
  },

  methods: {
    /**
     * Handles the click event on the button
     */
    handleClick() {
      this.$emit("click");
    },
  },
});
</script>
