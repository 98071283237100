<template>
  <BaseFilterMenuFilter :position="position" :active-count="0" :label="label">
    <div
      class="min-w-64 rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none gap-4 flex flex-col max-h-96 overflow-y-auto"
    >
      <BaseSearchInput
        placeholder="Search"
        class="w-full"
        :debounce="300"
        :model-value="modelValue ?? ''"
        @update:model-value="handleSearch"
      />
    </div>
  </BaseFilterMenuFilter>
</template>

<script lang="ts">
import BaseSearchInput from "@/base/components/inputs/BaseSearchInput.vue";
import type { FilterMenuPosition } from "@/base/components/menus/filterMenus/filters/BaseFilterMenuFilter.vue";
import BaseFilterMenuFilter from "@/base/components/menus/filterMenus/filters/BaseFilterMenuFilter.vue";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import { ClickAway } from "@/base/directives/ClickAwayDirective.ts";
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseFilterSearchFilter",
  expose: [],
  components: {
    BaseFilterMenuFilter,
    BaseSearchInput,
  },

  directives: {
    ClickAway,
  },

  props: {
    /** Filter menu position in filter list */
    position: {
      type: String as PropType<FilterMenuPosition>,
      required: true,
    },

    /** The filter label */
    label: {
      type: String as PropType<string>,
      required: true,
    },

    /** The filter value */
    modelValue: {
      type: [String, null] as PropType<string | null>,
      required: false,
      default: "",
    },
  },

  emits: {
    "update:modelValue": (_search: string) => true,
  },

  setup() {
    const { updateModelValue } = useModelValue<string>();

    return {
      updateModelValue,
    };
  },

  methods: {
    handleSearch($event: string) {
      this.updateModelValue($event);
    },
  },
});
</script>
