<template>
  <div class="flex items-center text-center text-gray-900 gap-x-4">
    <button
      type="button"
      :disabled="disabled"
      class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
      @click="handlePreviousClick"
    >
      <span class="sr-only">Previous</span>

      <FontAwesomeIcon :icon="['fas', 'chevron-left']" class="h-3 w-3" />
    </button>

    <div class="flex-auto text-sm font-semibold leading-none mt-0.5 min-w-48">{{ intervalText }}</div>

    <button
      type="button"
      :disabled="disabled || isNextDisabled"
      class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 disabled:text-gray-300"
      @click="handleNextClick"
    >
      <span class="sr-only">Next</span>

      <FontAwesomeIcon :icon="['fas', 'chevron-right']" class="h-3 w-3" />
    </button>
  </div>
</template>

<script lang="ts">
import useDebounce from "@/base/composables/DebounceComposable.ts";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import { isDayjs, type Dayjs } from "dayjs";
import type { PropType } from "vue";
import { computed, defineComponent, ref, toRef } from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faChevronRight, faChevronLeft);

type FormatType =
  | {
      /**
       * Display this exact text
       */
      exact: string;
    }
  | {
      /**
       * Only display the start date and render
       * using this format
       */
      start: string;
    }
  | {
      /**
       * Render the text as a range and render
       * using this format for both the start
       * and end dates of the interval
       */
      both: string;
    }
  | {
      /**
       * Render the text as a range and render
       * using this format for only the start date
       */
      start: string;

      /**
       * Render the text as a range and render
       * using this format for only the end date
       */
      end: string;
    };

export default defineComponent({
  name: "BaseDatePagination",
  expose: [],
  components: {
    FontAwesomeIcon,
  },

  props: {
    /** The interval to increment/decrement by */
    intervalType: {
      type: String as PropType<"day">,
      required: true,
    },

    /** The interval size to increment/decrement by */
    intervalSize: {
      type: Number as PropType<number>,
      required: true,
    },

    /**
     * The date at the start of the interval.
     */
    modelValue: {
      type: Object as PropType<Dayjs>,
      required: true,
    },

    /**
     * How should the interval be displayed.
     */
    format: {
      type: Function as PropType<(startDate: Dayjs, endDate: Dayjs) => FormatType>,

      required: true,
    },

    /**
     * Disable controls
     */
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },

    /**
     * Disable next button control
     */
    disableNext: {
      type: Function as PropType<(startDate: Dayjs, endDate: Dayjs) => boolean>,
      required: false,
      default: /* istanbul ignore next -- @preserve */ () => false,
    },

    /** Debounce date navigation time in milliseconds */
    debounce: {
      required: false,
      type: Number as PropType<number | undefined>,
      default: 0,
      validator: (val: number) => val >= 0,
    },

    /**
     * Instructs the component to emit the bounds on create.
     */
    noInitialEmit: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  emits: {
    "update:modelValue": (startDate: Dayjs, endDate: Dayjs) => isDayjs(startDate) && isDayjs(endDate),
  },

  setup(props) {
    const intervalSize = toRef(props.intervalSize);
    const intervalType = toRef(props.intervalType);
    const localModelValue = ref(props.modelValue.clone());
    const { updateModelValue: unDebouncedUpdateModelValue } = useModelValue<Dayjs, [Dayjs]>();

    const { invokeDebouncedCallback: updateModelValue } = useDebounce(
      toRef(props.debounce),
      unDebouncedUpdateModelValue,
      "debounceAnyInvoke"
    );

    const startDate = computed(() => {
      return localModelValue.value.clone();
    });

    const endDate = computed(() => {
      return localModelValue.value.clone().add(intervalSize.value - 1, intervalType.value);
    });

    if (!props.noInitialEmit) {
      // eslint-disable-next-line vue/no-ref-object-destructure
      unDebouncedUpdateModelValue(startDate.value.clone(), endDate.value.clone());
    }

    return {
      updateModelValue,
      localModelValue,
      startDate,
      endDate,
    };
  },

  computed: {
    isNextDisabled() {
      return this.disableNext(this.startDate, this.endDate);
    },

    computeFormat() {
      return this.format(this.startDate, this.endDate);
    },

    intervalText() {
      const format = this.computeFormat;

      if ("exact" in format) {
        return format.exact;
      }

      if ("start" in format && !("end" in format)) {
        return this.startDate.format(format.start);
      }

      const startDateFormat = "start" in format ? format.start : format.both;
      const endDateFormat = "end" in format ? format.end : format.both;

      return `${this.startDate.format(startDateFormat)} - ${this.endDate.format(endDateFormat)}`;
    },
  },

  watch: {
    modelValue(updatedModelValue) {
      this.localModelValue = updatedModelValue;
    },
  },

  methods: {
    handlePreviousClick() {
      this.localModelValue = this.localModelValue.clone().subtract(this.intervalSize, this.intervalType);
      this.updateModelValue(this.startDate.clone(), this.endDate.clone());
    },

    handleNextClick() {
      this.localModelValue = this.localModelValue.clone().add(this.intervalSize, this.intervalType);
      this.updateModelValue(this.startDate.clone(), this.endDate.clone());
    },
  },
});
</script>
