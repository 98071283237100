<template>
  <SideBarMenuPageLayout>
    <template #nav-menu>
      <BaseNavMenu :items="sideBarNavMenuItems" class="md:w-56">
        <template v-for="(item, index) in sideBarNavMenuItems" #[`${item.key}-icon`]="iconProps" :key="index">
          <slot :name="`${item.key}-icon`" v-bind="iconProps"> </slot>
        </template>
      </BaseNavMenu>
    </template>

    <template #default>
      <slot></slot>
    </template>
  </SideBarMenuPageLayout>
</template>

<script lang="ts">
import type { BaseNavMenuDropdownItem as BaseNavMenuDropdownItemInterface } from "@/base/interfaces/menus/navMenus/BaseNavMenuDropdownItem.ts";
import type { BaseNavMenuLinkItem as BaseNavMenuLinkItemInterface } from "@/base/interfaces/menus/navMenus/BaseNavMenuLinkItem.ts";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseNavMenu from "../menus/navMenus/BaseNavMenu.vue";
import SideBarMenuPageLayout from "@/base/components/pageLayouts/SideBarMenuPageLayout.vue";

export default defineComponent({
  name: "SideBarMenuPageWrapper",
  expose: [],
  components: {
    BaseNavMenu,
    SideBarMenuPageLayout,
  },

  props: {
    /**
     * The top level nav items.
     */
    sideBarNavMenuItems: {
      type: Array as PropType<(BaseNavMenuDropdownItemInterface | BaseNavMenuLinkItemInterface)[]>,

      required: true,
    },
  },
});
</script>
