<template>
  <div>
    <p
      id="listbox-label"
      class="block mb-2"
      :class="labelClass"
      v-bind="{ ...(hideLabel === true ? { class: 'sr-only' } : {}) }"
    >
      {{ label }}
    </p>

    <div v-click-away="onClickAway" class="relative">
      <!-- #region select -->
      <button
        type="button"
        role="combobox"
        class="disabled:bg-gray-50 disabled:cursor-not-allowed flex items-center w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
        aria-haspopup="listbox"
        :aria-expanded="isOpen"
        aria-controls="listbox"
        aria-labelledby="listbox-label"
        v-bind="{ ...(disabled === true ? { disabled: true } : {}) }"
        @click="isOpen = !isOpen"
      >
        <FontAwesomeIcon
          v-if="selectedOptionIcon"
          data-testid="select-icon"
          class="text-gray-900 ml-1 mr-3"
          :icon="selectedOptionIcon"
          aria-hidden="true"
          size="lg"
          fixed-width
        />

        <span class="block truncate">{{ selectLabel }}</span>

        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </button>
      <!-- #endregion select -->

      <!-- #region menu -->
      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ul
          v-show="isOpen"
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          tabindex="-1"
          role="listbox"
          aria-activedescendant="listbox-option-3"
        >
          <li
            v-for="(option, index) in options"
            :id="`listbox-option-${index}`"
            :key="index"
            class="cursor-default flex items-center select-none py-2 pl-4 pr-4 hover:bg-blue-600 hover:text-white"
            role="option"
            :aria-selected="index === selectedIndex"
            :class="[activeIndex === index || index === selectedIndex ? 'bg-blue-600 text-white' : 'text-gray-900']"
            tabindex="0"
            @click="
              handleOptionSelected(option);
              isOpen = false;
            "
            @mouseover="activeIndex = index"
            @mouseleave="activeIndex = null"
            @focusin="activeIndex = index"
            @focusout="activeIndex = null"
            @keydown.enter="
              handleOptionSelected(option);
              isOpen = false;
            "
          >
            <span v-if="option.icon" class="inset-y-0 flex items-center mr-3">
              <FontAwesomeIcon
                data-testid="option-icon"
                :class="[activeIndex === index || index === selectedIndex ? 'text-white' : 'text-gray-900']"
                :icon="option.icon"
                aria-hidden="true"
                size="lg"
                fixed-width
              />
            </span>

            <span
              data-testid="option-label"
              :class="[activeIndex === index || index === selectedIndex ? 'font-semibold' : 'font-normal']"
              class="block truncate"
              >{{ getOptionLabel(option) }}</span
            >
          </li>
        </ul>
      </transition>
      <!-- #endregion menu -->
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
  generic="
    Option extends Object & { [key: string]: any } & { icon?: MaybeReadonly<string[] | undefined> },
    OptionKey extends string
  "
>
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import { ClickAway } from "@/base/directives/ClickAwayDirective.ts";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ChevronUpDownIcon } from "@heroicons/vue/24/outline";
import { get } from "lodash";
import type { MaybeReadonly } from "resources/global";
import { computed, defineComponent, ref } from "vue";

const props = withDefaults(
  defineProps<{
    /** The options to display in the select */
    options: Option[];
    /**
     * The current value
     *
     * Is the currently selected option
     */
    modelValue: Option[keyof Option] | null;
    /** The key to use for the label */
    labelKey: keyof Option;
    /** The key to use for the value */
    valueKey: OptionKey;

    /** The display label for the select */
    label: string;
    /** The label to display when no option is selected */
    noneSelectedLabel: string;

    /** Whether to hide the select custom label */
    hideLabel?: boolean;

    /** Whether the select is disabled */
    disabled?: boolean;

    /** The class to apply to the label */
    labelClass?: string;
  }>(),
  {
    labelClass: "text-sm font-medium text-gray-700",
  }
);

defineEmits<{
  (e: "update:modelValue", value: Option[OptionKey]): void;
}>();

defineComponent({
  name: "BaseSelectCustom",
  components: {
    ChevronUpDownIcon,
    FontAwesomeIcon,
  },
});

defineOptions({
  directives: {
    ClickAway,
  },
});

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
const { updateModelValue } = useModelValue<Option[OptionKey]>();

const isOpen = ref(false);

const activeIndex = ref<number | null>(null);

const selectedOption = computed(() => {
  return props.options.find((option) => get(option, props.valueKey) === props.modelValue);
});

const selectedOptionIcon = computed(() => {
  return selectedOption.value ? selectedOption.value.icon : undefined;
});

const selectLabel = computed(() => {
  return selectedOption.value ? get(selectedOption.value, props.labelKey) : props.noneSelectedLabel;
});

const selectedIndex = computed(() => {
  return props.options.findIndex((option) => get(option, props.valueKey) === props.modelValue);
});

const getOptionLabel = (option: Option): Option[typeof props.labelKey] => {
  return get(option, props.labelKey);
};

const handleOptionSelected = (option: Option): void => {
  const optionValue = get(option, props.valueKey);

  updateModelValue(optionValue);
};

const onClickAway = (): void => {
  isOpen.value = false;
};
</script>
