<template>
  <div class="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
    <h1 class="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">Coming Soon</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseComingSoonPage",
  expose: [],
});
</script>
