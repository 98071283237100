<template>
  <div v-show="hideIfNoActiveFilters ? activeFilterOptions.length : true" class="sm:flex sm:items-center">
    <h3 class="text-sm font-medium text-gray-500">
      Filters
      <span class="sr-only">, active</span>
    </h3>

    <div aria-hidden="true" class="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"></div>

    <div class="mt-2 sm:ml-4 sm:mt-0">
      <div class="-m-1 flex flex-wrap items-center">
        <span
          v-for="activeFilter in activeFilterOptions"
          :key="activeFilter.optionKey"
          class="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
        >
          <span>{{ activeFilter.optionLabel }}</span>

          <button
            type="button"
            class="ml-1 inline-flex flex-shrink-0 rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500"
            @click="removeFilterOption(activeFilter)"
          >
            <span class="sr-only">Remove filter for {{ activeFilter.optionLabel }}</span>

            <XMarkIcon class="h-4 w-4 flex-shrink-0"></XMarkIcon>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import XMarkIcon from "@heroicons/vue/20/solid/XMarkIcon";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { MultiSelectFilterOption } from "../../dropdownMenus/BaseFilterMultiSelectOptionDropdownMenu.vue";
import type { Filter } from "../BaseFilterMenu.vue";
import { MultiSelectFilterType } from "../BaseFilterMenu.vue";

interface ActiveFilterOption {
  filter: Filter;
  optionKey: number | string;
  optionLabel: string;
  active: boolean;
  option: MultiSelectFilterOption | undefined;
}

export default defineComponent({
  name: "BaseFilterOptionActiveList",
  expose: [],

  components: {
    XMarkIcon,
  },

  props: {
    /**
     * Filters list.
     */
    filters: {
      type: Array as PropType<Filter[]>,
      required: true,
    },

    /**
     * Hide the active filters list if there are no active filters.
     */
    hideIfNoActiveFilters: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  emits: {
    "update:filters": (filters: Filter[]): boolean => Array.isArray(filters),
  },

  computed: {
    activeFilterOptions() {
      const mappedFilters = this.filters.flatMap((filter) => {
        if (filter.type === MultiSelectFilterType) {
          return filter.options.map((option) => {
            const multiSelectOption: ActiveFilterOption = {
              filter,
              optionKey: option.key,
              optionLabel: option.label,
              active: option.value,
              option,
            };

            return multiSelectOption;
          });
        }

        const option: ActiveFilterOption = {
          filter,
          optionKey: filter.key,
          optionLabel: typeof filter.value === "string" ? filter.value : "unknown",
          active: filter.value !== null,
          option: undefined,
        };

        return option;
      }) as ActiveFilterOption[];

      return mappedFilters.filter((filter) => filter.active);
    },
  },

  methods: {
    removeFilterOption(data: ActiveFilterOption) {
      const activeFilterIndex = this.filters.findIndex((iFilter) => iFilter.key === data.filter.key);

      /* istanbul ignore if -- @preserve */
      if (activeFilterIndex === -1) {
        throw new Error(`Option not found with key ${data.optionKey}`);
      }

      const filtersCopy = [...this.filters];

      const activeFilterCopy = {
        ...data.filter,
      };

      // Single filter
      if (activeFilterCopy.type !== MultiSelectFilterType) {
        activeFilterCopy.value = null;
        // Multi select filter
      }

      if ("options" in activeFilterCopy && activeFilterCopy.options !== null) {
        const activeFilterOptionIndex = activeFilterCopy.options.findIndex(
          (filterOption) => filterOption.key === data.optionKey
        );

        /* istanbul ignore if -- @preserve */
        if (activeFilterOptionIndex === -1) {
          throw new Error(`Option not found with key ${data.optionKey}`);
        }

        /* istanbul ignore if -- @preserve */
        if (data.option === undefined) {
          throw new Error("Option is undefined");
        }

        activeFilterCopy.options[activeFilterOptionIndex] = { ...data.option, value: false };
      }

      filtersCopy[activeFilterIndex] = activeFilterCopy;

      this.$emit("update:filters", filtersCopy);
    },
  },
});
</script>
