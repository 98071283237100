<template>
  <slot
    :is-putting="isPutting"
    :is-fetching="isFetching"
    :put="put"
    :put-response-error-bag="putResponseErrorBag"
    :reset-put-response-error-bag="resetPutResponseErrorBag"
    :fetched-parsed-data="parsedData"
  />
</template>

<script lang="ts" setup generic="T">
import UseFetch from "@/app/http/composables/UseFetchComposable.ts";
import type { PutOptions } from "@/app/http/composables/UsePutComposable.ts";
import UsePut from "@/app/http/composables/UsePutComposable.ts";
import type { PropType, Ref } from "vue";
import { onMounted, ref } from "vue";
import type { RouteLocationRaw } from "vue-router";
import { useRouter } from "vue-router";
import type { ZodSchema } from "zod";

const props = defineProps({
  // eslint-disable-next-line no-undef, vue/require-prop-comment
  fetchSchema: {
    type: Object as PropType<ZodSchema<T>>,
    required: true,
  },

  /** The url to fetch data from. */
  fetchUrl: {
    required: true,
    type: String,
  },

  /** The url to put to */
  putUrl: {
    required: true,
    type: String,
  },

  /** Configuration object for navigating back to previous page after post */
  navigateBackAfterPut: {
    type: Object as PropType<{ fallback: RouteLocationRaw | undefined }>,
    default: undefined,
  },

  /** Whether to display a notification on submit */
  disableUpdateSuccessNotification: {
    required: false,
    type: Boolean,
  },
});

const { doFetch, isFetching } = UseFetch(props.fetchUrl, props.fetchSchema, {
  onSchemaParseFailure: "pushNotification",
  onFailure: "navigateToErrorPage",
});

const {
  doPut,
  isPending: isPutting,
  responseErrorBag: putResponseErrorBag,
  resetResponseErrorBag: resetPutResponseErrorBag,
  responseData: putResponseData,
} = UsePut(props.putUrl, undefined, {
  onFailure: "pushNotification",
  onSuccess: !props.disableUpdateSuccessNotification ? "pushNotification" : undefined,
});

// eslint-disable-next-line no-undef
const parsedData = ref<Ref<T> | undefined>(undefined);

const router = useRouter();

/**
 * Fetch data from API
 */
function fetch(): void {
  doFetch()
    .then((parsedResponseData) => {
      parsedData.value = parsedResponseData;
    })
    // Do nothing on error as axios error will navigate to error page
    .catch(() => {});
}

/**
 * Update the item via a put request
 * @param data The data to put
 * @param perRequestOptions The options to use for this request
 * @returns The response data
 */
async function put(data: unknown, perRequestOptions: PutOptions = {}): Promise<unknown | undefined> {
  // Make the request and return the response
  try {
    await doPut(data, perRequestOptions);
  } catch {
    return undefined;
  }

  // Handle navigation back after put
  const backFallback = props.navigateBackAfterPut?.fallback;

  // If fallback is configured we assume the developer wants to go back to the previous page
  if (backFallback !== undefined) {
    // If a previous page exists in the router history, go back
    if (router.options.history.state["back"] !== undefined) {
      router.back();
    } else {
      // Otherwise, go to the fallback page
      await router.push(backFallback);
    }
  }

  return putResponseData.value;
}

defineExpose({
  put,
  fetch,
  parsedData,
});

onMounted(() => {
  fetch();
});
</script>

<script lang="ts">
/**
 * Reusable component for wrapping update pages/views
 *
 * - Contains reusable logic for fetching the item to update from API
 *     - Contains reusable logic for fetching data from API
 *     - Contains reusable logic for parsing data from API
 *     - Contains reusable logic for redirecting to error page
 * - Contains reusable logic for updating the item via a put request
 *     - Pushes notifications to the notification store on error
 *     - Has success message and data exposed
 *     - Contains logic for navigating back to previous page after post
 *     - Exposes errors from endpoint error response
 */
export default {
  name: "BasePutContainer",
};
</script>
