import type VaporStoreResponse from "@/media/interfaces/VaporStoreResponseInterface.ts";
import VaporStoreResponseSchema from "@/media/schemas/VaporStoreResponseSchema.ts";
import Vapor from "laravel-vapor";
import type { Ref } from "vue";
import { ref } from "vue";

interface UseVaporStore {
  /**
   * Store a file in the temporary s3 bucket.
   * @param file The file to store in the temporary s3 bucket.
   * @returns The parsed response from the vapor store request.
   */
  store: (file: File) => Promise<VaporStoreResponse>;

  /**
   * The progress of the upload.
   */
  uploadProgress: Ref<number>;
}

/**
 * Composable for storing files to temporary s3 bucket using vapor.
 *
 * S3 Temporary files are cleared after 24 hours.
 * We use the file path to copy the file from the temporary bucket to the our storage.
 * @returns The store function and the upload progress.
 */
export default function useVaporStore(): UseVaporStore {
  const uploadProgress = ref(0);

  /**
   * Store a file in the temporary s3 bucket.
   * @param file The file to store in the temporary s3 bucket.
   * @throws ZodError If the response does not match the expected schema.
   * @returns The parsed response from the vapor store request.
   */
  const store = async (file: File): Promise<VaporStoreResponse> => {
    const response = await Vapor.store(file, {
      progress:
        /* istanbul ignore next -- @preserve. Can't get this covered in tests. Add coverage if possible. */
        (progress) => {
          uploadProgress.value = Math.round(progress * 100);
        },
    });

    return VaporStoreResponseSchema.parse(response);
  };

  return {
    store,
    uploadProgress,
  };
}
