import { ref } from "vue";
import type { Ref } from "vue";

interface IsOpenComposable {
  isOpen: Ref<boolean>;
  toggleIsOpen: () => void;
  open: () => void;
  close: () => void;
}

/**
 * Composable for basic open/close state management.
 * @returns the composable with exposed objects.
 */
function useIsOpenComposable(): IsOpenComposable {
  const isOpen = ref(false);

  /**
   * Sets the open state to true.
   */
  function open(): void {
    isOpen.value = true;
  }

  /**
   * Sets the open state to false.
   */
  function close(): void {
    isOpen.value = false;
  }

  /**
   * Toggles the open state.
   *
   * If the state is true, it will be set to false and vice versa.
   */
  function toggleIsOpen(): void {
    isOpen.value = !isOpen.value;
  }

  return {
    isOpen,
    toggleIsOpen,
    open,
    close,
  };
}

export default useIsOpenComposable;
