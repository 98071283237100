<template>
  <component
    :is="tag"
    :type="type"
    v-bind="buttonAttrs"
    :class="buttonClassList"
    @click="handleClick()"
    @keypress.enter.prevent="handleClick()"
  >
    <slot name="before" :button-text="buttonText"></slot>
    {{ buttonText }}
  </component>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { useBaseButton, sizeProp, themeProp } from "@/base/composables/buttons/BaseButtonComposable.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";

export default defineComponent({
  name: "BaseButton",

  expose: [],

  props: {
    /**
     * HTML button tag
     */
    tag: {
      required: false,
      type: String as PropType<"button" | "label">,
      validator: (type) => typeof type === "string" && ["button", "label"].includes(type),
      default: "button",
    },

    /**
     * HTML button type attribute
     */
    type: {
      required: false,
      type: String as PropType<"button" | "reset" | "submit">,
      validator: (type) => typeof type === "string" && ["button", "reset", "submit"].includes(type),
      default: "button",
    },

    /**
     * HTML disabled attribute
     */
    disabled: {
      required: false,
      type: Boolean,
    },

    /**
     * HTML name attribute
     */
    name: {
      required: false,
      default: undefined,
      type: String as PropType<string | undefined>,
      validator: (name) => assertStringIsNotBlank(name),
    },

    /**
     * The button text
     */
    buttonText: {
      required: true,
      type: String,
    },

    /**
     * The appearance of the component excluding sizing
     */
    theme: themeProp,

    /**
     * The size of the button
     */
    size: sizeProp,
  },

  emits: {
    click: () => true,
  },

  setup(props) {
    const { buttonClassList } = useBaseButton(props.theme, props.size);

    return {
      buttonClassList,
    };
  },

  computed: {
    buttonAttrs() {
      return {
        ...(this.name !== undefined ? { name: this.name } : {}),
        ...(this.disabled ? { disabled: this.disabled } : {}),
      };
    },
  },

  methods: {
    handleClick() {
      if (this.disabled) {
        return;
      }
      this.$emit("click");
    },
  },
});
</script>
