<template>
  <div class="relative group">
    <slot name="underlay"></slot>

    <div class="absolute left-0 top-0 h-full w-full" :class="overlayClassList">
      <slot name="overlay"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseOverlay",
  expose: [],
  props: {
    /** Class list to apply to the overlay */
    overlayClassList: {
      type: [String, Object, Array, undefined] as PropType<unknown[] | string>,
      required: false,
      default: undefined,
    },
  },
});
</script>
