import { getCurrentInstance } from "vue";
/**
 * Reusable logic for handling model value (v-model) emit events
 * @author Jamie Wood
 * @param name update event name
 * @returns return state object
 */
export default function useModelValue<T = unknown, ArgumentsType extends unknown[] = unknown[]>(
  name = "modelValue"
): { updateModelValue: (value: T, ...args: ArgumentsType) => void } {
  const vm = getCurrentInstance();

  /**
   * Emits update event from context and event name
   * @param value input value for emit update event
   * @param args additional argument sto pass to the emit
   */
  function updateModelValue(value: T, ...args: ArgumentsType): void {
    if (!vm) {
      return;
    }

    vm.emit(`update:${name}`, value, ...args);
  }

  return {
    updateModelValue,
  };
}
