<template>
  <!--
    Only load the data index container if a request attempt has been made.
    If the filter fetch has failed, it wont block the index.
  -->
  <BaseIndexContainer
    v-if="hasFetchedFilters"
    ref="indexContainer"
    v-slot="{ doFetch, isFetching, parsedData }"
    :fetch-url="fetchUrl"
    :schema="schema"
    :params="mergedParams"
  >
    <slot
      :do-fetch="doFetch"
      :is-fetching="isFetching"
      :parsed-data="parsedData"
      :update-filters="handleSlotUpdateFilters"
      :filters="filteredFilters"
    ></slot>
  </BaseIndexContainer>

  <div v-else></div>
</template>

<script lang="ts" setup generic="T">
import useFetch from "@/app/http/composables/UseFetchComposable.ts";
import type { Ref } from "vue";
import { computed, onMounted, ref, unref, watch } from "vue";
import type { ZodSchema } from "zod";
import { z } from "zod";
import { MultiSelectFilterOptionSchema } from "../components/menus/dropdownMenus/BaseFilterMultiSelectOptionDropdownMenu.vue";
import type {
  BaseSearchFilter,
  Filter,
  MultiSelectFilter,
  SingleSelectFilter,
} from "../components/menus/filterMenus/BaseFilterMenu.vue";
import {
  BaseSearchSchema,
  MultiSelectFilterSchema,
  MultiSelectFilterType,
  SearchFilterType,
  SelectFilterType,
  SingleSelectFilterSchema,
} from "../components/menus/filterMenus/BaseFilterMenu.vue";
import BaseIndexContainer from "./BaseIndexContainer.vue";

const props = withDefaults(
  defineProps<{
    // eslint-disable-next-line no-undef, vue/require-prop-comment
    schema: ZodSchema<T>;
    /** The url to fetch data from. */
    fetchUrl: string;
    /** Query params. Must be reactive to cause accurate refresh */
    params?: Record<string, unknown> | Ref<Record<string, unknown>>;
    /** Key to reset filters */
    resetFiltersKey?: number;
    /** Filters to hide via frontend prop. */
    hideFilters?: string[];

    /** The url to fetch filters from. */
    filterUrl?: string | null;
  }>(),
  {
    resetFiltersKey: 0,
    filterUrl: null,
    hideFilters: () => [],
    params: () => ({}),
  }
);

const indexContainer = ref();

const refParams = ref(unref(props.params));

// eslint-disable-next-line @typescript-eslint/ban-types
const buildFiltersParams = (filters: Filter[]): Record<string, unknown> => {
  const params: Record<string, unknown> = {};

  /** built filters for spatie */
  filters.forEach((filter) => {
    if (filter.type === "select" || filter.type === "search") {
      params[filter.key] = filter.value;
    }

    if (filter.type === "multiselect") {
      const selectedOptions = filter.options.filter((option) => option.value === true);

      if (selectedOptions.length > 0)
        params[filter.key] = selectedOptions.map((option) => option.key.toString()).join(",");
    }
  });

  return params;
};

const partialMultiSelectFilterSchema = MultiSelectFilterSchema.merge(
  z.object({
    value: z.array(z.unknown()),
    options: z.array(MultiSelectFilterOptionSchema.omit({ value: true })),
  })
);

const partialSingleSelectFilterSchema = SingleSelectFilterSchema.merge(
  z.object({
    value: z.unknown(),
  })
);

const partialFiltersSchema = z.union([
  partialSingleSelectFilterSchema,
  partialMultiSelectFilterSchema,
  BaseSearchSchema,
]);

const { doFetch: doFetchFilters, parsedResponseData: filtersParsedResponseData } = useFetch(
  typeof props.filterUrl === "string" ? props.filterUrl : `${props.fetchUrl}/filters`,
  z.object({
    data: z.array(partialFiltersSchema),
  }),
  {
    onSchemaParseFailure: "pushNotification",
    // Not being able to pull filters shouldn't block the index page
    onFailure: "pushNotification",
  }
);

const hasFetchedFilters = ref<boolean>(false);

const filters = ref<Filter[]>([]);
const originalFilters = ref<Filter[]>([]);

watch(
  () => props.params,
  async () => {
    refParams.value = unref(props.params);
  },
  { deep: true, immediate: true }
);

watch(
  () => props.resetFiltersKey,
  () => {
    filters.value = originalFilters.value;
  }
);

const mergedParams = computed(() => {
  const encapsulatedParams: Record<string, unknown> = refParams.value;

  const filterParams = buildFiltersParams(filters.value);

  encapsulatedParams["filter"] = filterParams;

  return encapsulatedParams;
});

/**
 * Fetch and prepare filters.
 */
async function fetchAndPrepareFilters(): Promise<void> {
  try {
    await doFetchFilters();
  } catch (e) {
    return;
  } finally {
    hasFetchedFilters.value = true;
  }

  let data = filtersParsedResponseData.value?.data;

  /* istanbul ignore if -- @preserve */
  if (data === undefined) {
    data = [];
  }

  const rawResponseFilters = data;

  const responseFilters: Filter[] = [];

  rawResponseFilters.forEach((rawFilter) => {
    if (rawFilter.type === MultiSelectFilterType) {
      /* istanbul ignore if -- @preserve */
      if (!Array.isArray(rawFilter.value)) {
        throw new Error("Multiselect filter value must be an array");
      }

      /* istanbul ignore if -- @preserve */
      if (!Array.isArray(rawFilter.options)) {
        throw new Error("Multiselect filter options must be an array");
      }

      const multiSelectFilter: MultiSelectFilter = {
        key: rawFilter.key,
        label: rawFilter.label,
        position: rawFilter.position,
        type: rawFilter.type,
        /** @todo add nowrap as filter option on backend */
        noWrap: true,
        options: rawFilter.options.map((option) => ({
          key: option.key,
          label: option.label,
          value: rawFilter.value.includes(option.key),
        })),
        value: rawFilter.value,
      };

      responseFilters.push(multiSelectFilter);
    }

    if (rawFilter.type === SelectFilterType) {
      const selectFilter: SingleSelectFilter = {
        key: rawFilter.key,
        label: rawFilter.label,
        position: rawFilter.position,
        type: rawFilter.type,
        options: rawFilter.options,
        value: rawFilter.value,
        noWrap: true,
      };

      responseFilters.push(selectFilter);
    }

    if (rawFilter.type === SearchFilterType) {
      const searchFilter: BaseSearchFilter = {
        key: rawFilter.key,
        label: rawFilter.label,
        position: rawFilter.position,
        options: null,
        type: rawFilter.type,
        noWrap: true,
        value: typeof rawFilter.value === "string" || rawFilter.value === null ? rawFilter.value : null,
      };

      responseFilters.push(searchFilter);
    }
  });

  originalFilters.value = responseFilters;
  filters.value = responseFilters;
}

onMounted(async () => {
  await fetchAndPrepareFilters();
});

const filteredFilters = computed(() => {
  return filters.value.filter((filter) => !props.hideFilters.includes(filter.key));
});

/**
 * Handle an update filters call from the slot.
 * @param slotFilters Filters to replace the current
 */
function handleSlotUpdateFilters(slotFilters: Filter[]): void {
  filters.value = slotFilters;
}

/**
 * Call the index container fetch method.
 */
function fetch(): void {
  indexContainer.value?.fetch();
}

defineExpose({
  fetch,
});
</script>
