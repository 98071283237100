<template>
  <!-- Email Input + Label -->
  <label :for="id" class="block" data-testid="email-label" :class="labelClass">
    <span
      v-bind="{
        ...(hideLabel ? { class: 'sr-only' } : { class: '' }),
      }"
    >
      {{ label }}
    </span>

    <div class="relative rounded-md shadow-sm" :class="{ 'mt-2': !hideLabel }">
      <input v-bind="attributes" @input="handleInput($event)" />
    </div>
  </label>
  <!-- Error message -->
  <p
    v-show="hasErrorMessage"
    :id="errorMessageId"
    :aria-hidden="!hasErrorMessage"
    class="mt-2 text-sm text-red-600"
    data-testid="email-error"
  >
    {{ errorMessage }}
  </p>
</template>

<script lang="ts">
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import type { PropType } from "vue";

/**
 * Email input with label
 * @author Jamie Wood
 */
export default {
  name: "BaseInputEmail",

  expose: [],

  // Prevents inherited attributes so we can control binding
  inheritAttrs: false,

  props: {
    /**
     * Id Html attribute
     */
    id: {
      required: true,
      type: String,
      validator: (id) => assertStringIsNotBlank(id),
    },

    /**
     * Name HTML attribute
     */
    name: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
      validator: (name) => assertStringIsNotBlank(name),
    },

    /**
     * Autocomplete HTML attribute to assist users when filling out forms
     */
    autoComplete: {
      required: false,
      default: "username",
      type: String as PropType<"email" | "username">,
      validator: (autocomplete) => typeof autocomplete === "string" && ["email", "username"].includes(autocomplete),
    },

    /**
     * HTML attribute for input placeholder text
     */
    placeholder: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * Disabled HTML attribute to prevent browser interaction
     */
    disabled: {
      required: false,
      type: Boolean,
    },

    /**
     * Input label text
     */
    label: {
      required: false,
      type: String,
      default: "Email",
      validator: (label) => assertStringIsNotBlank(label),
    },

    /**
     * Whether to hide the label
     */
    hideLabel: {
      required: false,
      type: Boolean,
    },

    /**
     * Required HTML attribute for specifying if input value is required
     */
    required: {
      required: false,
      type: Boolean,
    },

    /**
     * Model value prop for v-model two-way binding
     */
    modelValue: {
      required: false,
      type: String as PropType<string | null | undefined>,
      default: undefined,
    },

    /**
     * Error message to render with the input
     *
     * Should only be used if there is a error to display
     */
    errorMessage: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * Id HTML attribute for error message
     */
    errorMessageId: {
      required: false,
      type: String,
      default: "email-error",
      validator: (id) => assertStringIsNotBlank(id),
    },

    /**
     * Class for the field label.
     */
    labelClass: {
      type: String,
      required: false,
      default: "text-sm font-medium text-gray-700",
    },
  },

  emits: {
    "update:modelValue": (input: string) => {
      return typeof input === "string";
    },
  },

  setup() {
    const { updateModelValue } = useModelValue();
    return {
      updateModelValue,
    };
  },

  computed: {
    attributes() {
      return {
        ...this.$attrs,
        ...(this.name !== undefined ? { name: this.name } : {}),
        ...(this.disabled === true ? { disabled: this.disabled } : {}),
        ...(this.required === true ? { required: this.required } : {}),
        "aria-invalid": this.hasErrorMessage,
        "aria-describedby": this.errorMessageId,
        id: this.id,
        value: this.modelValue,
        autoComplete: this.autoComplete,
        placeholder: this.placeholder ?? "you@example.com",
        type: "email",
        class: ["class" in this.$attrs ? this.$attrs["class"] : "", this.inputClass, this.errorInputClass] as string[],
      };
    },

    hasErrorMessage() {
      return this.errorMessage !== undefined;
    },

    inputClass() {
      return "block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 focus:outline-none disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500";
    },

    errorInputClass() {
      return this.hasErrorMessage === true
        ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-inset focus:ring-red-500"
        : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-600";
    },
  },

  methods: {
    handleInput($event: Event) {
      const input = $event.target as HTMLInputElement | null;

      /* istanbul ignore next -- @preserve */
      if (!input || !("value" in input)) {
        return;
      }

      this.updateModelValue(input.value);
    },
  },
};
</script>
