<template>
  <MediaImage v-if="avatar" :media="avatar" :custom-conversions="['avatar_url']"> </MediaImage>

  <BaseAvatarPlaceholder v-else :name="name"></BaseAvatarPlaceholder>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import MediaImage from "@/media/components/MediaImage.vue";
import BaseAvatarPlaceholder from "@/base/components/emptyStates/BaseAvatarPlaceholder.vue";
import type Media from "@/media/interfaces/Media";

export default defineComponent({
  name: "BaseAvatar",
  expose: [],
  components: {
    MediaImage,
    BaseAvatarPlaceholder,
  },

  props: {
    /** Name to use for placeholder if an avatar isnt present */
    name: {
      type: String as PropType<string>,
      required: true,
    },

    /** Avatar media object */
    avatar: {
      type: Object as PropType<Media | null | undefined>,
      required: false,
      default: undefined,
    },
  },
});
</script>
