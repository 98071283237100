import z from "zod";

/**
 * Validate a number prop.
 * @param data The data to validate.
 * @returns Whether the data is valid.
 */
export default function NumberPropValidator(data: number): boolean {
  return z.number().safeParse(data).success;
}
