import isObject from "lodash/isObject";
import type { ComputedRef, PropType, Ref } from "vue";
import { computed } from "vue";
/**
 * @todo add track key for comparing selected values as comparing objects just checks if their references
 * are the same
 */
export type ValueKey = PropertyKey;

export type LabelKey = PropertyKey;

export const valueKeyProp = {
  type: [String, Number] as PropType<ValueKey | undefined>,
  required: false,
  validator(value: unknown) {
    return (typeof value === "number" && Number.isInteger(value)) || typeof value === "string" || value === undefined;
  },
};

export const labelKeyProp = {
  type: [String, Number] as PropType<LabelKey | undefined>,
  required: false,
  validator(value: unknown) {
    return (typeof value === "number" && Number.isInteger(value)) || typeof value === "string" || value === undefined;
  },
};

export const optionsProp = {
  type: [Object, Array] as PropType<unknown>,
  required: true,
};
/**
 * Reusable code for aggregate inputs such as selects.
 * @param options Options
 * @param valueKey Value key
 * @param labelKey Label key
 * @param modelValue Value
 * @returns Composable
 */
export function useAggregateInput<Os = unknown, O = unknown, V = unknown>(
  options: Ref<Os>,
  valueKey: ValueKey | undefined,
  labelKey: LabelKey | undefined,
  modelValue: Ref<V>
): {
  mappedOptions: ComputedRef<O[]>;
  mappedValueKey: ComputedRef<ValueKey | undefined>;
  mappedLabelKey: ComputedRef<LabelKey | undefined>;
  getOptionLabel: (option: O) => string;
  getOptionValue: (option: O) => V | unknown | undefined;
  isOptionSelected: (option: O) => boolean;
  selectedOptionIndex: ComputedRef<number | undefined>;
  selectedOptionValue: ComputedRef<V | unknown | undefined>;
} {
  const mappedOptions = computed<O[]>((): O[] => {
    if (isObject(options.value) && !Array.isArray(options.value)) {
      /** Allow for {key: value} based objects */
      return Object.entries(options.value) as O[];
    }

    return options.value as O[];
  });

  const mappedValueKey = computed<ValueKey | undefined>((): ValueKey | undefined => {
    return isObject(options.value) && !Array.isArray(options.value) && valueKey == null ? 0 : valueKey;
  });

  const mappedLabelKey = computed<LabelKey | undefined>((): LabelKey | undefined => {
    return isObject(options.value) && !Array.isArray(options.value) && labelKey == null ? 1 : labelKey;
  });

  const getOptionLabel = (option: O): string => {
    const computedMappedLabelKey = mappedLabelKey.value;

    if (computedMappedLabelKey != null) {
      if (typeof option !== "object" || option == null) {
        throw new TypeError("Options must be objects when a label key is set");
      }
      if (!(computedMappedLabelKey in option)) {
        throw new TypeError(`Option doesn't have the label key '${String(computedMappedLabelKey)}'`);
      }
      const label: unknown = (option as Record<LabelKey, unknown>)[computedMappedLabelKey];
      if (typeof label !== "string") {
        throw new TypeError("Option label must be a string");
      }
      return label;
    }

    if (typeof option !== "string") {
      throw new TypeError("Option as a label must be a string");
    }

    return option;
  };

  const getOptionValue = (option: O): V | unknown | undefined => {
    const computedMappedValueKey = mappedValueKey.value;

    if (computedMappedValueKey != null) {
      if (typeof option !== "object" || option == null) {
        throw new TypeError("Options must be objects when a value key is set");
      }
      if (!(computedMappedValueKey in option)) {
        throw new TypeError(`Option doesn't have the value key '${String(computedMappedValueKey)}'`);
      }
      return (option as Record<ValueKey, V>)[computedMappedValueKey];
    }
    /** Cast option to value as can be anything */
    return option as unknown as V;
  };

  const isOptionSelected = (option: O): boolean => {
    /** @todo remove when track by key is implemented */
    return JSON.stringify(getOptionValue(option)) === JSON.stringify(modelValue.value);
  };

  const selectedOptionIndex = computed((): number | undefined => {
    const index = mappedOptions.value.findIndex((option): boolean => {
      return isOptionSelected(option);
    });

    if (index === -1) {
      return undefined;
    }

    return index;
  });

  const selectedOptionValue = computed((): V | unknown | undefined => {
    if (selectedOptionIndex.value === undefined) {
      return undefined;
    }

    const selectedOption = mappedOptions.value[selectedOptionIndex.value];

    /* istanbul ignore if -- @preserve */
    if (selectedOption === undefined) {
      return undefined;
    }

    return getOptionValue(selectedOption);
  });

  return {
    isOptionSelected,
    mappedOptions,
    mappedValueKey,
    mappedLabelKey,
    getOptionLabel,
    getOptionValue,
    selectedOptionIndex,
    selectedOptionValue,
  };
}
export default useAggregateInput;
