import isFunction from "lodash/isFunction";
import type { Directive, DirectiveBinding } from "vue";

type DirectiveValue = (event: Event) => unknown;

/**
 * Handler for the global click event.
 * @param element The element which can be clicked away from
 * @param bind The directive binding
 * @param event The mouse event for the click
 */
function handleClick(element: HTMLElement, bind: DirectiveBinding<DirectiveValue>, event: Event): void {
  /** Self remove if the origin element is not in the dom (means the parent component has unmounted) */
  if (!document.body.contains(element)) {
    // eslint-disable-next-line jsdoc/check-tag-names
    /** @ts-expect-error Drop type checking for handleClick as deviation arguments element and bind are required */
    globalThis.removeEventListener("click", handleClick);
    return;
  }

  const path: EventTarget[] = event.composedPath();

  if (!path.includes(element)) {
    bind.value(event);
  }
}

export const ClickAway: Directive<HTMLElement, DirectiveValue> = {
  created(element, bind) {
    if (!isFunction(bind.value)) {
      throw new TypeError("Click away directive bind must be a function.");
    }

    globalThis.addEventListener("click", handleClick.bind(this, element, bind));
  },
};

export default ClickAway;
