import * as amplitude from "@amplitude/analytics-browser";
import * as sentry from "@sentry/vue";
import AnalyticEventsEnum from "../enums/AnalyticEventsEnum.ts";

/**
 * Enum for the analytic events.
 * @param event The event to dispatch.
 * @param eventProperties The properties to send with the event.
 */
function analyticTrack(event: AnalyticEventsEnum, eventProperties: Record<string, unknown> = {}): void {
  if (!Object.values(AnalyticEventsEnum).includes(event)) {
    sentry.captureMessage(`The event ${event} is not a valid event`);

    return;
  }

  const properties = {
    ...eventProperties,
  };

  try {
    amplitude.track(event, properties);
  } catch (error) {
    sentry.captureException(error);
  }
}

export default analyticTrack;
