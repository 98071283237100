<template>
  <div class="flex items-center justify-between px-4 py-3 sm:px-6 w-full">
    <div class="mr-3">
      <p class="text-sm text-gray-700">
        <template v-if="totalResults <= perPage">
          Showing all
          <span class="font-medium">{{ totalResults }}</span>
          results
        </template>

        <template v-else>
          Showing
          <span class="font-medium">{{ lowerResultNumber }}</span>
          to
          <span class="font-medium">{{ upperResultNumber }}</span>
          of
          <span class="font-medium">{{ totalResults }}</span>
          results
        </template>
      </p>
    </div>

    <div>
      <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
        <button
          type="button"
          class="disabled:bg-gray-50 disabled:cursor-not-allowed relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          :disabled="page === 1"
          @click="previous()"
        >
          <span class="sr-only">Previous</span>

          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" :d="previousSvgPath" clip-rule="evenodd" />
          </svg>
        </button>

        <template v-for="i in pageButtonsToDisplay" :key="i">
          <button
            type="button"
            aria-current="page"
            class="disabled:cursor-not-allowed"
            :disabled="i === currentPage"
            :class="pageLinkCss(i)"
            @click="navigate(i)"
          >
            {{ i }}
          </button>
        </template>

        <button
          type="button"
          class="disabled:bg-gray-50 disabled:cursor-not-allowed relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          :disabled="page === totalPages"
          @click="next()"
        >
          <span class="sr-only">Next</span>

          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" :d="nextSvgPath" clip-rule="evenodd" />
          </svg>
        </button>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { computed, defineComponent, ref, toRef, watch } from "vue";
import useDebounce from "@/base/composables/DebounceComposable.ts";

/**
 * The base pagination component intended for use with tables
 */
export default defineComponent({
  name: "BaseTablePagination",
  expose: [],

  props: {
    /**
     * The current page
     */
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },

    /**
     * The current page size
     */
    perPage: {
      type: Number,
      required: false,
      default: 20,
    },

    /**
     * The total number of results
     */
    totalResults: {
      type: Number,
      required: true,
    },

    /** Debounce page navigation time in milliseconds */
    debounce: {
      required: false,
      type: Number as PropType<number | undefined>,
      default: 0,
      validator: (val: number) => val >= 0,
    },
  },

  emits: {
    paginate: (page: number, total: number) => page <= total && page > 0,
  },

  setup(props, { emit }) {
    const page = ref(props.currentPage);

    const totalResultsRef = ref(props.totalResults);
    const perPageRef = ref(props.perPage);

    const getTotalPages = (totalResults: number, perPage: number): number => {
      return totalResults !== 0 ? Math.ceil(totalResults / perPage) : 1;
    };

    /**
     * The total number of pages
     * @returns number The total number of pages
     */
    const totalPages = computed(() => getTotalPages(totalResultsRef.value, perPageRef.value));

    /**
     * Emits the paginate event
     * @param updatePage The page to navigate to
     * @param actualTotalPages Local total pages override
     */
    const paginate = (updatePage: number, actualTotalPages?: number): void => {
      emit("paginate", updatePage, actualTotalPages ?? totalPages.value);
    };

    watch(
      () => [props.totalResults, props.perPage],
      ([totalResults, perPage]) => {
        const actualTotalPages = getTotalPages(
          totalResults ?? /* istanbul ignore next -- @preserve */ totalResultsRef.value,
          perPage ?? /* istanbul ignore next -- @preserve */ perPageRef.value
        );

        if (page.value > actualTotalPages) {
          page.value = actualTotalPages;
          paginate(actualTotalPages, actualTotalPages);
        }
      }
    );

    const { invokeDebouncedCallback: invokeDebouncedPaginate } = useDebounce(
      toRef(props.debounce),
      paginate,
      "debounceAnyInvoke"
    );

    return {
      invokeDebouncedPaginate,
      paginate,
      page,
      totalPages,
    };
  },

  data() {
    return {
      previousSvgPath:
        "M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z",

      nextSvgPath:
        "M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z",
    };
  },

  computed: {
    pageButtonsToDisplay() {
      const currentPage = this.page;
      const { totalPages } = this;
      const buttons = [] as number[];

      const numberOfPagesAhead = totalPages - currentPage;
      const numberOfPagesBehind = currentPage > 1 ? currentPage - 1 : 0;

      // No pages ahead or behind
      if (numberOfPagesAhead === 0 && numberOfPagesBehind === 0) {
        // Only one page
        buttons.push(currentPage);
        return buttons;
      }

      // Pages ahead and behind
      if (numberOfPagesAhead !== 0 && numberOfPagesBehind !== 0) {
        buttons.push(currentPage - 1, currentPage, currentPage + 1);
        return buttons;
      }

      // Only pages ahead
      if (numberOfPagesBehind === 0 && numberOfPagesAhead !== 0) {
        buttons.push(currentPage, currentPage + 1);

        if (numberOfPagesAhead > 1) {
          buttons.push(currentPage + 2);
        }

        return buttons;
      }

      // Only pages behind
      /* istanbul ignore else -- @preserve */
      if (numberOfPagesAhead === 0 && numberOfPagesBehind !== 0) {
        buttons.push(currentPage - 1, currentPage);

        if (numberOfPagesBehind > 1) {
          buttons.unshift(currentPage - 2);
        }

        return buttons;
      }

      /* istanbul ignore next -- @preserve */
      return buttons;
    },

    lowerResultNumber(): number {
      return (this.page - 1) * this.perPage + 1;
    },

    upperResultNumber(): number {
      const upper = this.page * this.perPage;
      return upper > this.totalResults ? this.totalResults : upper;
    },
  },

  watch: {
    currentPage(value) {
      this.page = value;
    },
  },

  methods: {
    navigate(pageNumber: number) {
      this.invokeDebouncedPaginate((this.page = pageNumber));
    },

    next() {
      this.invokeDebouncedPaginate((this.page += 1));
    },

    previous() {
      this.invokeDebouncedPaginate((this.page -= 1));
    },

    pageLinkCss(page: number): string {
      return this.page === page
        ? "relative z-10 inline-flex items-center bg-blue-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        : "disabled:bg-gray-50 relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0";
    },
  },
});
</script>
