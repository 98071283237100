import { z } from "zod";

const RouteLocationRawSchema = z.union([
  z.string(),
  z.object({
    name: z.string().optional(),
    params: z.record(z.string(), z.unknown()).optional(),
  }),
]);

export default RouteLocationRawSchema;
