<template>
  <div class="bg-black text-white h-5 flex items-center justify-center text-xs font-semibold">
    <FontAwesomeIcon :icon="['fas', 'wifi']" class="mr-2" />

    <p class="text-center sm:block hidden">No Internet Connection. Please check your connection.</p>

    <p class="text-center sm:hidden block">No Internet Connection.</p>
  </div>
</template>

<script lang="ts">
import AnalyticEventsEnum from "@/analytics/enums/AnalyticEventsEnum.ts";
import analyticTrack from "@/analytics/functions/AnalyticEvent.ts";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineComponent } from "vue";

library.add(faWifi);

export default defineComponent({
  name: "BaseBannerOffline",
  components: {
    FontAwesomeIcon,
  },

  expose: [],

  mounted() {
    analyticTrack(AnalyticEventsEnum.appOffline);
  },
});
</script>
