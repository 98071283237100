<template>
  <!--
    Set notification from first notification in stack

    - If there are no notifications, then hide the notification with some default values
    - Do not hide the notification if there are notifications as
    then we wont get transition animations when the notification changes
    - If there are notifications, then show the first notification
  -->
  <BaseNotification
    :title="firstNotification?.title ?? ''"
    :message="firstNotification?.message ?? undefined"
    :type="firstNotification?.type ?? 'success'"
    :duration="firstNotification?.duration ?? 5000"
    :show="showNotification"
    @close="removeFirstNotification()"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { z } from "zod";
import BaseNotification from "./BaseNotification.vue";
import type StackNotificationInterface from "./interfaces/StackNotificationInterface.ts";

const NotificationSchema = z.object({
  title: z.string(), // Title of the notification
  message: z.string().optional(), // Message of the notification
  type: z.union([z.literal("success"), z.literal("error")]), // Type of the notification
  duration: z.number().gte(0).optional(), // Duration of the notification
  key: z.union([z.string(), z.number()]), // Unique key of the notification
});

/**
 * A stack of notifications
 *
 * - The notifications are displayed one at a time
 * - Notifications are automatically closed after the specified duration
 * - Newest notifications are displayed first
 */
export default defineComponent({
  name: "BaseNotificationStack",
  expose: ["addNotification"],
  components: {
    BaseNotification,
  },

  props: {
    /**
     * The maximum notifications until the stack is full
     *
     * - If set to 0, then the stack is never full
     * - Old notifications are removed from the stack when the stack is full
     * - Error notifications are not removed from the stack
     */
    maxNotifications: {
      type: Number,
      default: 3,
      validator: (value: number) => {
        return value >= 0;
      },
    },
  },

  data() {
    return {
      /** Whether or not the first notification is visible */
      showNotification: false,
      /** The stack of notifications */
      notificationStack: new Array<StackNotificationInterface>(),
    };
  },

  computed: {
    /**
     * The notification to display
     * @returns the notification to display or undefined if there are no notifications
     */
    firstNotification() {
      return this.notificationStack.length > 0 ? this.notificationStack[0] : undefined;
    },
  },

  methods: {
    /**
     * Add a notification to the stack
     *
     * - All new notifications are added to the front of the stack so the user always has the latest alerts
     * @param notification the notification to add
     */
    async addNotification(notification: StackNotificationInterface): Promise<void> {
      const parsedNotification = NotificationSchema.parse(notification);

      await this.handleFirstNotificationChange(() => {
        // 1. Prepend the parsed notification to this.notificationStack
        this.notificationStack.unshift(parsedNotification);

        // 2. Check if the stack is full
        if (this.maxNotifications > 0 && this.notificationStack.length > this.maxNotifications) {
          let index = this.maxNotifications;
          // 3. Remove all notifications after the max notifications if they are not errors
          for (index; index <= this.notificationStack.length - 1; index += 1) {
            if (this.notificationStack[index]?.type !== "error") {
              this.notificationStack.splice(index, 1);
            }
          }
        }
      });
    },

    /**
     * Remove the first notification from the stack
     *
     * - This is called when the notification is closed
     */
    async removeFirstNotification() {
      await this.handleFirstNotificationChange(() => {
        this.notificationStack.shift();
      });
    },

    /**
     * Refresh the first notification by hiding it and then showing it again
     * @param callback called when the timeout has finished for the transition animation
     */
    async handleFirstNotificationChange(callback: () => void) {
      this.showNotification = false;
      /** Wait for transition and toggle notification for accessibility */
      await this.$nextTick().then(() => {
        setTimeout(() => {
          callback();
          if (this.notificationStack[0]) {
            this.showNotification = true;
          }
        }, 150);
      });
    },
  },
});
</script>
