<template>
  <div :class="scrollable ? 'overflow-x-auto' : ''" data-testid="table-container" v-bind="$attrs">
    <table class="w-full divide-y divide-gray-300">
      <slot name="caption" />

      <thead :class="areAllHeadersHidden ? 'border-none' : ''">
        <tr>
          <!-- Table headers -->
          <template v-for="(header, headerIndex) in headers" :key="`table-header-${header.key}`">
            <th :class="getBaseHeaderClass(headerIndex, header)" scope="col">
              {{ header.headerText }}
            </th>
          </template>
          <!-- Button table header -->
          <th
            v-if="displayButton"
            :class="[
              areAllHeadersHidden ? 'sr-only' : 'relative py-3.5 pl-3 pr-4 sm:pr-0',
              buttonMobileRowClick && 'hidden sm:table-cell',
            ]"
          >
            <span class="sr-only">{{ buttonHeaderScreenReaderText }}</span>
          </th>
        </tr>
      </thead>

      <tbody class="divide-y divide-gray-200" :class="areAllHeadersHidden && !$slots['caption'] ? 'border-none' : ''">
        <template v-if="loading && loadingState != null">
          <BaseTableLoadingRows
            v-bind="loadingState.rowsCount != null && { rowsCount: loadingState.rowsCount }"
            cell-class-list="px-3 sm:px-0 py-4"
          ></BaseTableLoadingRows>
        </template>

        <template v-else-if="rows.length > 0">
          <template v-for="section in table">
            <!-- section header -->
            <tr
              v-if="section.showSection && (section.showHeaderIfNoRows === false ? section.rowCount !== 0 : true)"
              :key="section.text"
              class="border-t border-gray-200"
            >
              <th
                colspan="100%"
                scope="colgroup"
                class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
              >
                {{ section.text }}
              </th>
            </tr>

            <template v-for="group in section.groups">
              <!-- group header -->
              <tr v-if="group.showGroupHeader" :key="group.text" class="border-t border-gray-200">
                <th
                  colspan="100%"
                  scope="colgroup"
                  class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                >
                  {{ group.text }}
                </th>
              </tr>

              <!-- grouped rows -->
              <tr
                v-for="(rowItem, rowIndex) in handleGroupedRows(section, group)"
                :key="`table-row-${rowIndex}`"
                :class="
                  buttonMobileRowClick &&
                  displayButton &&
                  'cursor-pointer sm:cursor-auto hover:bg-gray-50 sm:hover:bg-transparent'
                "
                @click="
                  disableRowClick === false &&
                    buttonMobileRowClick &&
                    displayButton &&
                    handleRowClick({ rowItem: rowItem, index: rowIndex })
                "
              >
                <!-- Content table cell -->
                <td
                  v-for="(header, headerIndex) in headers"
                  :key="`table-row-${rowIndex}-cell-${header.key}-${rowItem.key}`"
                  :class="
                    !header.passClassesToCell
                      ? getTableCellClass(rowItem, header, headerIndex)
                      : getTableCellAdditionalClasses(rowItem, header)
                  "
                >
                  <slot
                    :classes="header.passClassesToCell ? getTableCellClass(rowItem, header, headerIndex) : ''"
                    :name="`row-${header.key}`"
                    :row-item="rowItem"
                    :index="rowIndex"
                    :header="header"
                  >
                    {{ getRowItemValue(rowItem, header.key) }}
                  </slot>
                </td>
                <!-- Button table cell -->
                <td
                  v-if="displayButton"
                  class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                  :class="buttonMobileRowClick && 'hidden sm:table-cell'"
                >
                  <button
                    data-testid="base-table-view-button"
                    type="button"
                    :class="buttonClass()"
                    :disabled="disableRowClick"
                    @click="disableRowClick === false && $emit('clickRow', { rowItem: rowItem, index: rowIndex })"
                  >
                    <template v-if="replaceButtonWithChevron">
                      <FontAwesomeIcon
                        data-testid="base-table-chevron-icon"
                        :icon="['fat', 'chevron-right']"
                        size="lg"
                      />
                    </template>

                    <template v-else>
                      {{ buttonText }}
                    </template>

                    <span class="sr-only">{{
                      typeof buttonCellScreenReaderText === "function"
                        ? buttonCellScreenReaderText(rowItem)
                        : buttonCellScreenReaderText
                    }}</span>
                  </button>
                </td>
              </tr>
            </template>

            <tr
              v-if="section.paginator?.paginate && section.paginator.lastPage !== 1"
              :key="'paginator-' + section.text"
              class="border-t border-gray-200"
            >
              <th
                colspan="100%"
                scope="colgroup"
                class="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
              >
                <BaseShowMorePaginator
                  :key="`paginator-${section.text}`"
                  :current-page="section.paginator.currentPage"
                  :last-page="section.paginator.lastPage"
                  @update:current-page="section.paginator.currentPage = $event"
                />
              </th>
            </tr>
          </template>
        </template>

        <template v-else>
          <tr>
            <td v-if="emptyState.type === 'simple'" colspan="100%" class="p-4">
              <BaseEmptyState
                v-if="emptyState.actionable"
                :action-text="emptyState.actionText"
                :actionable="emptyState.actionable"
                :description="emptyState.description"
                :title="emptyState.title"
                :icon="emptyState.icon"
                @click="handleEmptyStateActionClick()"
              ></BaseEmptyState>

              <BaseEmptyState
                v-else
                :description="emptyState.description"
                :title="emptyState.title"
                :icon="emptyState.icon"
              ></BaseEmptyState>
            </td>

            <td v-else class="text-sm whitespace-nowrap py-4 pl-4 pr-3 sm:pl-0 font-medium text-gray-900">
              {{ emptyState.text }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <slot name="pagination" />
</template>

<script lang="ts">
import type {
  BaseEmptyStateActionable,
  BaseEmptyStateNonActionable,
} from "@/base/components/emptyStates/BaseEmptyState.vue";
import BaseEmptyState from "@/base/components/emptyStates/BaseEmptyState.vue";
import BaseShowMorePaginator from "@/base/components/pagination/BaseShowMorePaginator.vue";
import ScreenSizes from "@/base/enums/ScreenSizesEnum.ts";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import { isViewportLargerOrEqualToScreenSize } from "@/base/functions/viewport/ViewportFunctions.ts";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { get } from "lodash";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseTableLoadingRows from "../../loading/BaseTableLoadingRows.vue";

library.add(faChevronRight);

interface BaseEmptyStateActionableWithType extends BaseEmptyStateActionable {
  type: "simple";
}

interface BaseEmptyStateNonActionableWithType extends BaseEmptyStateNonActionable {
  type: "simple";
}

interface BaseTextEmptyState {
  type: "text";
  text: string;
}

interface GroupBy {
  /** The row item key to group by */
  key: string;
}

/**
 * Prop Section defined on the component
 *
 * - Used to configure the table into multiple sections
 */
interface PropSection {
  /** The section unique identifier */
  key: string;

  /** The section header text */
  sectionHeaderText: string;

  /** Show section header if there are no rows for that section */
  showHeaderIfNoRows?: boolean;

  /** Paginate the section */
  paginator?: {
    /** The number of rows per page */
    rowsPerPage: number;
  };
}

interface LocalPropSection extends PropSection {
  showSection?: boolean;
}

/** A row group contains multiple rows grouped by row item value */
interface Group {
  /** The group header text */
  text: string;
  /** Show group header */
  showGroupHeader: boolean;
  /** Grouped rows */
  rows: RowItem[];
}

/**
 * A section contains multiple row groups
 *
 * - If no prop sections are provided then a default section will be created
 * - If no prop group by then a default ungrouped group will be created to group all rows
 */
interface Section {
  /** The section unique identifier */
  key: string;

  /** The section header text */
  text: string;

  /** Show section header */
  showSection: boolean;

  /** Show section header if there are no rows for that section */
  showHeaderIfNoRows: boolean;

  /** Rows grouped by group */
  groups: Group[];

  /** The number of rows in the section */
  rowCount: number;

  /** Paginate the section */
  paginator:
    | {
        /** Should paginate */
        paginate: boolean;

        /** The current page */
        currentPage: number;

        /** The last page */
        lastPage: number;

        /** The number of rows per page */
        rowsPerPage: number;
      }
    | undefined;
}

export type EmptyState = BaseEmptyStateActionableWithType | BaseEmptyStateNonActionableWithType | BaseTextEmptyState;

interface BaseRowsLoadingState {
  type: "rows";
  rowsCount?: number | undefined;
}

export type LoadingState = BaseRowsLoadingState;

export interface RowItem {
  [RowItemKey: string]: unknown;
  sectionKey?: string | undefined;
  key: number | string;
}
type Rows = RowItem[];
type RowItemKey = string & keyof RowItem;
export interface TableHeader {
  headerText: string;
  key: RowItemKey;
  isBold?: boolean | undefined;
  /** Hide the table header */
  isHidden?: boolean;
  /** Allow the text to be wrapped in the table cells */
  wrapText?: boolean;
  /** Additional classes to add to the table row */
  additionalTableCellClasses?: string | ((rowItem: RowItem) => string[] | string | undefined);
  /**
   * Flag to tell the table to pass the classes to the child so it can style itself.
   * This is useful for say a label that wants maximum area clickable area through
   * padding for a better user experience.
   */
  passClassesToCell?: boolean;
}

export type ButtonCellScreenReaderTextCallback = (rowItem: RowItem) => string;

export interface ClickRowPayload {
  /** The row item of the clicked row */
  rowItem: RowItem;
  /** The key index of the clicked row */
  index: number;
}

/**
 * Base Table Component
 *
 * - Used to list items in a table
 * - Each item has a button
 * - Exposes named slots so you can override the table cell content
 */
export default defineComponent({
  name: "BaseTable",
  expose: [],

  components: {
    BaseEmptyState,
    BaseShowMorePaginator,
    FontAwesomeIcon,
    BaseTableLoadingRows,
  },

  inheritAttrs: false,

  props: {
    /**
     * An array of table header row cells
     *
     * Also used to get data for the table cells
     */
    headers: {
      required: true,
      type: Array as PropType<TableHeader[]>,
    },

    /**
     * An array of table rows
     *
     * Contains all the data for each row
     */
    rows: {
      required: true,
      type: Array as PropType<Rows>,
    },

    /**
     * An array of table sections
     *
     * - Used to divide the table into sections
     */
    sections: {
      required: false,
      type: Array as PropType<PropSection[] | undefined>,
      default: undefined,
    },

    /**
     * Group the table by a row item key value
     *
     * - If in sectioned mode the groups will appear for each section
     */
    groupBy: {
      required: false,
      type: Object as PropType<GroupBy | undefined>,
      default: undefined,
    },

    /**
     * bool flag to swap the button text with a chevron icon
     */
    replaceButtonWithChevron: {
      required: false,
      type: Boolean,
    },

    /**
     * The text for the action button next to each table row
     */
    buttonText: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
      validator: (text) => assertStringIsNotBlank(text),
    },

    /**
     * Accessible screen reader text that explains what the button table header is
     */
    buttonHeaderScreenReaderText: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
      validator: (text) => assertStringIsNotBlank(text),
    },

    /**
     * Accessible screen reader text that describes the table rows button
     *
     * - Can receive a callback so you can provide information about that specific row
     */
    buttonCellScreenReaderText: {
      required: false,
      type: [Function, String] as PropType<ButtonCellScreenReaderTextCallback | string | undefined>,
      default: undefined,
    },

    /**
     * If a button is defined, when entering mobile
     * the button will hide and the whole row will become clickable.
     */
    buttonMobileRowClick: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },

    /**
     * Configuration for what should be displayed if there are no items.
     */
    emptyState: {
      type: Object as PropType<EmptyState>,
      required: false,
      default: () => ({
        type: "text",
        text: "No Items Found",
      }),
    },

    /** Don`t allow row click event emits */
    disableRowClick: {
      type: Boolean,
      required: false,
    },

    /** Whether the table should be in a loading state */
    loading: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },

    /** The loading state for the table */
    loadingState: {
      type: Object as PropType<LoadingState | undefined>,
      required: false,
      default: undefined,
    },

    /** Enable overflow scrolling on the table */
    scrollable: {
      type: Boolean,
      required: false,
    },
  },

  exposes: [],
  emits: {
    clickRow: (_payload: ClickRowPayload): boolean => true,
    clickEmptyStateAction: () => true,
  },

  data() {
    return {
      table: [] as Section[],
    };
  },

  computed: {
    isGroupedOrSectioned() {
      return ![0, undefined].includes(this.sections?.length) || this.groupBy !== undefined;
    },

    areAllHeadersHidden() {
      return this.headers.every((header) => header.isHidden === true);
    },

    displayButton() {
      return this.buttonText !== undefined;
    },
  },

  watch: {
    rows: {
      deep: true,
      handler() {
        this.createTable();
      },
    },
  },

  created() {
    this.createTable();
  },

  methods: {
    handleRowClick(payload: ClickRowPayload) {
      /* istanbul ignore if -- @preserve */
      if (!this.buttonMobileRowClick || !this.displayButton) return;

      if (isViewportLargerOrEqualToScreenSize(ScreenSizes.sm)) {
        return;
      }

      this.$emit("clickRow", payload);
    },

    getRowItemValue(rowItem: RowItem, key: RowItemKey) {
      return get(rowItem, key);
    },

    handleGroupedRows(section: Section, group: Group): RowItem[] {
      if (section.paginator === undefined || !section.paginator.paginate) {
        return group.rows;
      }

      const maxItemsIndex = section.paginator.currentPage * section.paginator.rowsPerPage;

      return group.rows.slice(0, maxItemsIndex);
    },

    buttonClass() {
      return this.replaceButtonWithChevron
        ? "tw-gray-500 disabled:cursor-not-allowed"
        : "text-blue-600 hover:text-blue-900 disabled:cursor-not-allowed disabled:text-blue-300";
    },

    getBaseHeaderClass(headerIndex: number, header: TableHeader) {
      const isFirstItem = headerIndex === 0;

      if (this.areAllHeadersHidden) {
        return ["sr-only"];
      }

      const classes = ["text-left text-sm font-semibold text-gray-900"];

      if (isFirstItem) {
        classes.push("py-3.5 pl-4 pr-3");
      } else {
        classes.push("px-3 py-3.5");
      }

      classes.push(this.getSectionPaddingOffsetClass(isFirstItem));

      classes.push(header.isHidden === true ? "sr-only" : "");

      return classes;
    },

    getTableCellAdditionalClasses(rowItem: RowItem, header: TableHeader) {
      const classes = ["text-sm"];

      // Handle additional table classes.

      // Add classes to string to array
      if (typeof header.additionalTableCellClasses === "string") {
        classes.push(header.additionalTableCellClasses);
      }

      // Call function to get classes
      if (typeof header.additionalTableCellClasses === "function") {
        const additionalClasses = header.additionalTableCellClasses(rowItem);

        // Add classes to string to array
        if (typeof additionalClasses === "string") {
          classes.push(additionalClasses);
        }

        // Merge array classes if array
        if (Array.isArray(additionalClasses)) {
          classes.push(...additionalClasses);
        }
      }

      return classes;
    },

    getTableCellClass(rowItem: RowItem, header: TableHeader, headerIndex: number) {
      let classes = ["text-sm"];
      const isFirstItem = headerIndex === 0;

      if (isFirstItem) {
        classes.push("py-4 pl-4 pr-3");
      } else {
        classes.push("px-3 py-4");
      }

      if (header.passClassesToCell !== true) {
        classes = classes.concat(this.getTableCellAdditionalClasses(rowItem, header));
      }

      classes.push(this.getSectionPaddingOffsetClass(isFirstItem));

      classes.push(header.wrapText === true ? "" : "whitespace-nowrap");

      classes.push(header.isBold === true ? "font-medium text-gray-900" : "text-gray-500");

      return classes;
    },

    getSectionPaddingOffsetClass(isFirstItem: boolean) {
      return this.isGroupedOrSectioned && isFirstItem ? "sm:pl-3" : "sm:pl-0";
    },

    handleEmptyStateActionClick() {
      this.$emit("clickEmptyStateAction");
    },

    /**
     * Create table rows grouped by section and group
     */
    createTable() {
      const localGroupBy = this.groupBy;
      const localPropSections: LocalPropSection[] = this.sections ?? [];
      const hasGroupBy = localGroupBy !== undefined;

      /**
       * Create a default section
       *
       * This is so the table will still render if no sections are provided or there are rows without sections
       */
      localPropSections.unshift({
        key: "default",
        sectionHeaderText: "Default",
        showHeaderIfNoRows: false,
        showSection: false,
      });

      const sectionKeys = localPropSections.map((section) => section.key);

      // map sections from prop sections to sections
      const sections = localPropSections.map((propSection) => {
        /**
         * If default section then filter rows that have no section key
         * If default section and section key does not exist then include row in default section
         *
         * If not default section then filter rows that have the section key
         */
        const sectionRows = this.rows.filter((row) => {
          if (propSection.key === "default") {
            return row.sectionKey === undefined || !sectionKeys.includes(row.sectionKey);
          }

          return row.sectionKey === propSection.key;
        });

        let sectionGroups: Group[] = [];

        /**
         * Create ungrouped group
         *
         * - if no group by then add all rows to ungrouped group
         * - if group by then add all rows that have a null or undefined row item values
         */
        const ungroupedGroup = {
          text: "ungrouped",
          showGroupHeader: false,
          rows: sectionRows.filter((row) => {
            if (hasGroupBy) {
              return row[localGroupBy.key] === null || row[localGroupBy.key] === undefined;
            }

            return true;
          }),
        };

        if (hasGroupBy) {
          const groupBySet = new Set(sectionRows.map((row) => row[localGroupBy.key]));

          // create groups from unique row item values
          sectionGroups = Array.from(groupBySet)
            // remove null and undefined keys as we want to group them together
            .filter((key) => {
              return key !== null && key !== undefined;
            })
            .map((key) => {
              const mappedKey = typeof key === "number" || typeof key === "boolean" ? key.toString() : key;
              const localText = typeof mappedKey === "string" ? mappedKey : "";

              return {
                text: localText,
                showGroupHeader: true,
                rows: sectionRows.filter((row) => row[localGroupBy.key] === key),
              };
            });
        }

        const showSection = propSection.showSection ?? true;

        const section: Section = {
          key: propSection.key,
          text: propSection.sectionHeaderText,
          showSection,
          showHeaderIfNoRows: propSection.showHeaderIfNoRows ?? true,
          groups: [ungroupedGroup, ...sectionGroups],
          rowCount: sectionRows.length,
          paginator:
            propSection.paginator !== undefined
              ? {
                  ...propSection.paginator,
                  currentPage: 1,
                  lastPage:
                    /* istanbul ignore next */ Math.ceil(sectionRows.length / propSection.paginator.rowsPerPage) || 1,
                  paginate: this.groupBy === undefined && showSection,
                }
              : undefined,
        };

        return section;
      });

      this.table = sections;
    },
  },
});
</script>
