import Vapor from "laravel-vapor";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";

interface UseVaporAsset {
  /**
   * Get the URL to a Vapor asset using the given path.
   * @param path The path to the asset.
   * @returns The cloudfront URL to the asset.
   */
  getVaporAssetUrl: (path: string) => string;
}

/**
 * Composable for getting vapor cloudfront assets
 * @see https://docs.vapor.build/projects/deployments.html#referencing-assets-from-javascript
 * @returns getVaporAsset function
 * @example returns resources/images/logo.png -> https://cloudfront-url.com/resources/images/logo.png
 */
export default function useVaporAsset(): UseVaporAsset {
  const getVaporAssetUrl = (path: string): string => {
    if (!assertStringIsNotBlank(path)) {
      throw new Error("Path must be a non-blank string");
    }

    return Vapor.asset(path);
  };

  return {
    getVaporAssetUrl,
  };
}
