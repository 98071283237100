import HttpClientErrorDataClass from "@/app/http/classes/HttpClientErrorDataClass.ts";
import useNotificationStore from "@/base/stores/NotificationStore.ts";
import type { ErrorBagInterface } from "@/validation/classes/ErrorBag.ts";
import ErrorBag from "@/validation/classes/ErrorBag.ts";
import * as Sentry from "@sentry/vue";
import type { Ref } from "vue";
import { computed, ref, unref } from "vue";
import type { ZodSchema } from "zod";

type ResponseData = Ref<unknown>;
type DoPost = (data: unknown) => Promise<unknown>;
type ResponseErrorBag = Ref<ErrorBagInterface>;
export type ResetResponseErrorBag = () => void;
type ResponseErrorMessage = Ref<string | undefined>;
type ResponseMessage = Ref<string | undefined>;
type IsPosting = Ref<boolean>;

interface UsePostExportsInterface {
  /**
   * The json data from the response
   */
  responseData: ResponseData;
  /**
   * The axios error response data errors parsed into a error bag
   */
  responseErrorBag: ResponseErrorBag;
  /**
   * Resets the response error bag errors so it is empty
   */
  resetResponseErrorBag: ResetResponseErrorBag;
  /**
   * The axios error response data message
   */
  responseErrorMessage: ResponseErrorMessage;
  /**
   * The axios response data message
   */
  responseMessage: ResponseMessage;
  /**
   * Used to determine if post is in progress
   */
  isPosting: IsPosting;
}

interface UsePostComposableOptions {
  /** What should happen on failure */
  onFailure?: "pushNotification";
}

interface SchemaPostOptions extends UsePostComposableOptions {
  /** What to do on schema parse failure */
  onSchemaParseFailure: "pushNotification";
}

export default function usePost<ParsedData>(
  url: Ref<string> | string,
  schema?: ZodSchema<ParsedData>,
  options?: SchemaPostOptions
): UsePostExportsInterface & {
  /**
   * Perform a post request
   * @param data the post data
   * @throws {AxiosError} throws axios error
   * @throws {Error} if we fail to parse error data
   * @returns the promise containing the api endpoint response data
   */
  doPost: (data: unknown) => Promise<ParsedData>;
  /** Parsed response data from schema */
  parsedResponseData: Ref<ParsedData | undefined>;
};

export default function usePost(
  url: Ref<string> | string,
  schema?: undefined,
  options?: UsePostComposableOptions
): UsePostExportsInterface & {
  /**
   * Perform a put request
   * @param data the put data
   * @throws {AxiosError} will rethrow any axios errors so they get logged
   * @throws {Error} if we fail to parse error data
   * @returns the promise containing the api endpoint response data
   */
  doPost: (data: unknown) => Promise<unknown>;
  /** No schema provided so parsed response data is undefined */
  parsedResponseData: Ref<undefined>;
};

/**
 * Consume `usePost` composable
 * @param url the url to post to
 * @param schema the schema to parse the response data with
 * @param options the options to pass to the composable
 * @returns exposed composable methods and variables
 */
export default function usePost<ParsedData>(
  url: Ref<string> | string,
  schema: ZodSchema<ParsedData> | undefined = undefined,
  options: UsePostComposableOptions = {}
): UsePostExportsInterface & {
  doPost: (data: unknown) => Promise<ParsedData | unknown>;
  /** No schema provided so parsed response data is undefined */
  parsedResponseData: Ref<ParsedData | undefined>;
} {
  const responseData: ResponseData = ref(undefined);
  const responseErrorBag: ResponseErrorBag = ref(ErrorBag.new({}));
  const responseErrorMessage: ResponseErrorMessage = ref(undefined);
  const responseMessage: ResponseMessage = ref(undefined);
  const parsedResponseData: Ref<ParsedData | undefined> = ref(undefined);
  const isPosting: IsPosting = ref(false);
  const { push } = useNotificationStore();
  const computedUrl = computed(() => (typeof url === "string" ? url : url.value));

  const resetResponseErrorBag: ResetResponseErrorBag = function resetResponseErrorBag(): void {
    responseErrorBag.value = ErrorBag.new({});
  };

  const doPost: DoPost = async function doPost(data: unknown): Promise<ParsedData | unknown> {
    // reset state before posting
    responseData.value = undefined;
    responseMessage.value = undefined;
    responseErrorMessage.value = undefined;
    parsedResponseData.value = undefined;
    resetResponseErrorBag();
    isPosting.value = true;

    let response;

    try {
      response = await window.httpClient.post(computedUrl.value, data);

      responseMessage.value = response.data.message ?? undefined;
      responseData.value = response.data;
    } catch (error) {
      isPosting.value = false;

      const dataClass = HttpClientErrorDataClass.new(error as Error);

      responseErrorBag.value = dataClass.responseErrorBag;
      responseErrorMessage.value = dataClass.responseErrorMessage;

      if (options.onFailure === "pushNotification") {
        push({
          title: "Error",
          message: dataClass.responseErrorMessage ?? "An error occurred",
          type: "error",
          duration: 5000,
        });
      }

      return Promise.reject(error);
    }

    isPosting.value = false;

    const schemaValue = unref(schema);

    if (schemaValue !== undefined) {
      const schemaResponse = schemaValue.safeParse(responseData.value);

      if (!schemaResponse.success) {
        /** Push Notification on schema failure */
        if ("onSchemaParseFailure" in options) {
          push({
            title: "Error",
            message: "Could not parse data",
            type: "error",
            duration: 5000,
          });
        }

        /** Log error to sentry */
        Sentry.captureException(schemaResponse.error);

        // eslint-disable-next-line no-console
        console.error(schemaResponse.error);

        // Allow user to continue in production.
        if (window.baseAppVariables.isProduction === false) {
          throw schemaResponse.error;
        }
      }

      parsedResponseData.value = schemaResponse.success ? schemaResponse.data : response.data;

      return parsedResponseData.value;
    }

    return responseData.value;
  };

  return {
    responseData,
    parsedResponseData,
    responseMessage,
    doPost,
    responseErrorBag,
    resetResponseErrorBag,
    responseErrorMessage,
    isPosting,
  };
}
