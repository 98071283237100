import { getCurrentInstance, ref } from "vue";

/**
 * Composable for form submit listener
 *
 * Used to override the default form submit behavior so we can fire a submitted event with each attempt
 * and also add custom validation
 * @event submitAttempted emitted when the form is submitted
 * @author Jamie Wood
 * @param onSubmitCallback gets called on successful submit
 * @param validationCallback custom validation callback
 * @returns isFormValid boolean ref
 */
export default function useFormOnSubmitListenerComposable(
  onSubmitCallback: () => void,
  validationCallback: () => boolean
): {
  isFormValid: typeof isFormValid;
  addFormSubmitListener: (form: HTMLFormElement) => void;
  submitForm: (form: HTMLFormElement) => void;
  onSubmit: (event: Event) => void;
} {
  const isFormValid = ref<boolean>(false);
  const context = getCurrentInstance();

  const onSubmit = (event: Event): void => {
    if (event.target === null || !(event.target instanceof HTMLFormElement)) {
      return;
    }

    const { target } = event;

    event.preventDefault();

    context?.emit("submitAttempted");

    const customValidated = validationCallback();
    const htmlFormValidated = target.checkValidity();
    isFormValid.value = customValidated && htmlFormValidated;

    if (isFormValid.value) {
      onSubmitCallback();
    }
  };

  const addFormSubmitListener = (form: HTMLFormElement): void => {
    form.addEventListener("submit", onSubmit);
  };

  const submitForm = (form: HTMLFormElement): void => {
    const submitEvent = new Event("submit", { bubbles: true, cancelable: true });

    form.dispatchEvent(submitEvent);
  };

  return {
    isFormValid,
    addFormSubmitListener,
    submitForm,
    onSubmit,
  };
}
