<template>
  <BaseNotificationStack ref="notificationStack" :max-notifications="3" />
</template>

<script lang="ts">
import { storeToRefs } from "pinia";
import { defineComponent, ref, watch } from "vue";
import BaseNotificationStack from "@/base/components/notifications/BaseNotificationStack.vue";
import useNotificationStore from "@/base/stores/NotificationStore.ts";

/**
 * Handles global application notifications
 *
 * - Listens to the latest notification from the notification store and renders them in the notification stack.
 */
export default defineComponent({
  name: "TheBaseNotificationStoreStack",
  components: {
    BaseNotificationStack,
  },

  expose: [],
  setup() {
    const { latest } = storeToRefs(useNotificationStore());
    const notificationStack = ref<InstanceType<typeof BaseNotificationStack> | undefined>(undefined);

    watch(latest, async () => {
      /* istanbul ignore if -- @preserve */
      if (latest.value === undefined) {
        return;
      }

      await notificationStack.value?.addNotification(latest.value);
    });

    return {
      latest,
      notificationStack,
    };
  },
});
</script>
