/* eslint-disable import/prefer-default-export */
import type ScreenSizes from "@/base/enums/ScreenSizesEnum.ts";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../../tailwind.config.ts";

/**
 * Gets the width of the screen size.
 * @param screenSize The screen size to get the width of.
 * @returns The width of the screen size.
 */
function getViewportWidth(screenSize: ScreenSizes): number {
  const fullConfig = resolveConfig(tailwindConfig);

  return Number.parseInt(fullConfig.theme["screens"][screenSize], 10);
}

/**
 * Checks if the viewport is larger or equal to the screen size.
 * @param screenSize - The screen size to check if the viewport is larger or equal to.
 * @returns - If the viewport is below the screen size.
 */
function isViewportLargerOrEqualToScreenSize(screenSize: ScreenSizes): boolean {
  return window.innerWidth >= getViewportWidth(screenSize);
}

export { getViewportWidth, isViewportLargerOrEqualToScreenSize };
