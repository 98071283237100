<template>
  <PureMediaContainer
    :accept="accept"
    :disabled="uploadingMedia"
    :id-prefix="idPrefix"
    :media="media"
    :loading="uploadingMedia"
    :max="max"
    :input-type="inputType"
    :error-message="errorMessage"
    :error-id="errorId"
    :hide-input="hideInput"
    @add-files="onFileInput"
    @remove-vapor-media="handleRemove"
  >
    <template #inline-input="slotProps">
      <slot name="inline-input" v-bind="slotProps"></slot>
    </template>
  </PureMediaContainer>
</template>

<script lang="ts">
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import useNotificationStore from "@/base/stores/NotificationStore.ts";
import PureMediaContainer, { PureMediaContainerProps } from "@/media/components/PureMediaContainer.vue";
import { defineComponent } from "vue";
import useVaporStore from "../composables/VaporStoreComposable.ts";
import type VaporMedia from "../interfaces/VaporMediaInterface.ts";
import type VaporStoreResponse from "../interfaces/VaporStoreResponseInterface.ts";

/**
 * Component for handling the uploading and previewing of temporary media.
 * This is to be used on model create.
 */
export default defineComponent({
  name: "TemporaryMediaContainer",
  expose: ["validate"],
  components: {
    PureMediaContainer,
  },

  props: {
    /**
     * The prefix for the id attribute of elements in this component.
     */
    idPrefix: PureMediaContainerProps.idPrefix,

    /** List of acceptable comma separated mime types */
    accept: PureMediaContainerProps.accept,

    /** The max number of media that can be in the gallery */
    max: PureMediaContainerProps.max,

    /**
     * Controls the different file input types.
     * Inline will place a box with a plus icon in the previews while block is a separate file input.
     * Inline should only be used when the preview layout is inline wrap.
     */
    inputType: PureMediaContainerProps.inputType,

    /**
     * Error message to render with the input
     *
     * Should only be used if there is a error to display
     */
    errorMessage: PureMediaContainerProps.errorMessage,

    /**
     * Id HTML attribute for error message
     */
    errorId: PureMediaContainerProps.errorId,

    /**
     * Should the input be hidden.
     * Only applies in inline mode.
     */
    hideInput: PureMediaContainerProps.hideInput,
  },

  emits: {
    "update:modelValue": (_media: VaporMedia[]) => true,
  },

  setup() {
    const { store: storeVaporFile, uploadProgress: storeVaporFileUploadProgress } = useVaporStore();
    const { push: pushNotification } = useNotificationStore();
    const { updateModelValue } = useModelValue<VaporMedia[]>();

    return {
      storeVaporFile,
      storeVaporFileUploadProgress,
      pushNotification,
      updateModelValue,
    };
  },

  data() {
    return {
      media: [] as VaporMedia[],
      uploadingMedia: false,
    };
  },

  methods: {
    validate() {
      return true;
    },

    handleRemove(media: VaporMedia) {
      const mediaIndex = this.media.findIndex((findMedia) => findMedia.uuid === media.uuid);

      /* istanbul ignore if -- @preserve */
      if (mediaIndex === -1) {
        throw new Error("Unable to find media to remove");
      }

      this.media.splice(mediaIndex, 1);
      this.updateModelValue(this.media);
    },

    async onFileInput(files: File[], resolve: () => void) {
      const file = files[0];

      /* istanbul ignore if -- @preserve */
      if (file == null) return;

      this.uploadingMedia = true;

      let res: VaporStoreResponse;

      try {
        res = await this.storeVaporFile(file);
      } catch (_e) {
        return;
      } finally {
        this.uploadingMedia = false;
      }

      this.media.push({
        ...res,
        file,
      });

      this.updateModelValue(this.media);
      resolve();
    },
  },
});
</script>
