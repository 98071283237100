<template>
  <ul role="status" :aria-label="screenReaderText" :class="containerClass" aria-live="polite">
    <li v-for="index in numberOfItems" :key="index" class="animate-pulse" :class="rowClass">
      <div class="flex items-center justify-between w-full">
        <div>
          <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>

          <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>

        <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseLoadingRows",
  expose: [],
  props: {
    /** Text for screen readers */
    screenReaderText: {
      type: String,
      required: false,
      default: "Loading",
    },

    /** The number of items to display */
    numberOfItems: {
      type: Number,
      required: false,
      default: 3,
      validator: (value: number) => value > 0 && value <= 100,
    },

    /** Class list to pass to the row */
    rowClass: {
      type: String,
      required: false,
      default: "w-full",
    },

    /** Class list to pass to the container */
    containerClass: {
      type: String,
      required: false,
      default: "",
    },
  },
});
</script>
