import { handleErrorWithoutFailingInProduction } from "@/app/error/ErrorHandler.ts";
import HttpClientErrorDataClass from "@/app/http/classes/HttpClientErrorDataClass.ts";
import useNotificationStore from "@/base/stores/NotificationStore.ts";
import NavigateToErrorPageFromAxiosError from "@/routing/functions/NavigateToErrorPage.ts";
import type { ErrorBagInterface } from "@/validation/classes/ErrorBag.ts";
import ErrorBag from "@/validation/classes/ErrorBag.ts";
import type { AxiosError } from "axios";
import type { Ref } from "vue";
import { ref, unref } from "vue";
import { useRouter } from "vue-router";
import type { ZodSchema } from "zod";

type ResponseData = Ref<unknown>;
type ResponseErrorBag = Ref<ErrorBagInterface>;
type ResponseErrorMessage = Ref<string | undefined>;
type IsFetching = Ref<boolean>;

interface BaseFetchOptions {
  /** What to do on failure */
  onFailure?: "navigateToErrorPage" | "pushNotification";
}

interface FetchOptionsWithSchema extends BaseFetchOptions {
  /** What to do on schema parse failure */
  onSchemaParseFailure?: "pushNotification";
}

export interface UseFetchExportsInterface<T> {
  /**
   * The json data from the response
   */
  responseData: ResponseData;
  /**
   * The axios error response data errors parsed into a error bag
   */
  responseErrorBag: ResponseErrorBag;
  /**
   * The axios error response data message
   */
  responseErrorMessage: ResponseErrorMessage;
  /**
   * Used to determine if fetch is in progress
   */
  isFetching: IsFetching;

  parsedResponseData: Ref<T | undefined>;

  /**
   * Perform the fetch action
   * @throws {AxiosError} if request fails
   * @returns response data or undefined if request fails
   */
  doFetch: () => Promise<T>;
}

export default function useFetch<ParsedData>(
  url: Ref<string> | string,
  schema?: ZodSchema<ParsedData>,
  options?: FetchOptionsWithSchema,
  params?: Record<string, unknown> | Ref<Record<string, unknown>> | undefined
): UseFetchExportsInterface<ParsedData>;

export default function useFetch(
  url: Ref<string> | string,
  schema?: undefined,
  options?: BaseFetchOptions,
  params?: Record<string, unknown> | Ref<Record<string, unknown>> | undefined
): UseFetchExportsInterface<unknown>;

/**
 * Consume `useFetch` composable
 *
 * - Does not throw axios error if server responses with error
 * @param url the url to post to
 * @param schema the schema to validate the response data against
 * @param options the options to pass to the composable
 * @param params the query params to pass to the url
 * @returns exposed composable methods and variables
 */
export default function useFetch<ParsedData>(
  url: Ref<string> | string,
  schema?: ZodSchema<ParsedData>,
  options: BaseFetchOptions | FetchOptionsWithSchema = {},
  params?: Record<string, unknown> | Ref<Record<string, unknown>> | undefined
): UseFetchExportsInterface<ParsedData | unknown> {
  const responseData: ResponseData = ref(undefined);
  const parsedResponseData: Ref<ParsedData | undefined> = ref(undefined);
  const responseErrorBag: ResponseErrorBag = ref(ErrorBag.new({}));
  const responseErrorMessage: ResponseErrorMessage = ref(undefined);
  const isFetching: IsFetching = ref(false);
  const { push } = useNotificationStore();
  const router = useRouter();

  const doFetch = async function doFetch(): Promise<ParsedData | unknown> {
    // reset state before fetching..
    responseData.value = undefined;
    parsedResponseData.value = undefined;
    responseErrorBag.value = ErrorBag.new({});
    responseErrorMessage.value = undefined;
    isFetching.value = true;

    // unref() unwraps potential refs
    const fetchUrl = unref(url);

    let resData;

    /** Make Http Request */
    try {
      const paramsValue = unref(params);
      const res = await window.httpClient.get(fetchUrl, { params: paramsValue });

      resData = res.data;
    } catch (error) {
      /** Handle axios error */

      isFetching.value = false;

      const httpClientErrorData = HttpClientErrorDataClass.new(error as Error);

      responseErrorBag.value = httpClientErrorData.responseErrorBag;
      responseErrorMessage.value = httpClientErrorData.responseErrorMessage;

      /** Push notification to user on failure */
      if (options.onFailure === "pushNotification") {
        push({
          title: "Error",
          message: responseErrorMessage.value ?? "An error occurred",
          type: "error",
          duration: 5000,
        });
      }

      /** Navigate to error page on failure */
      if (options.onFailure === "navigateToErrorPage") {
        await NavigateToErrorPageFromAxiosError(error as AxiosError, router);
      }

      return Promise.reject(error);
    }

    isFetching.value = false;

    responseData.value = resData;

    /** Handle zod schema parse */
    if (schema !== undefined) {
      const schemaValue = unref(schema);

      const schemaResponse = schemaValue.safeParse(resData);

      if (!schemaResponse.success) {
        handleErrorWithoutFailingInProduction(schemaResponse.error, { callErrorHandlerAnyway: true });
      }

      parsedResponseData.value = schemaResponse.success ? schemaResponse.data : resData;

      /** Return parsed data */
      return parsedResponseData.value;
    }

    /** Return non parsed data */
    return responseData.value;
  };

  return {
    responseData,
    parsedResponseData,
    doFetch,
    responseErrorBag,
    responseErrorMessage,
    isFetching,
  };
}
