<template>
  <div class="px-6 py-6 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg">
    <slot name="header" :header-classes="headerClasses">
      <h4 :class="headerClasses">{{ title }}</h4>
    </slot>

    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseCardDetail",
  expose: [],

  props: {
    /** The title of the card */
    title: {
      required: true,
      type: String,
    },
  },

  computed: {
    headerClasses(): string {
      return "text-sm font-semibold leading-6 text-gray-900 mb-5";
    },
  },
});
</script>
