<template>
  <p :id="htmlId + '-label'" class="block" :class="{ 'sr-only': hideLabel, [displayLabelClass]: true }">
    {{ htmlLabel }}
  </p>

  <div v-click-away="onClickAway" class="w-full flex flex-col items-center relative">
    <div class="w-full">
      <div
        class="my-2 p-1 flex border border-gray-200 bg-white rounded"
        :class="{ '!bg-gray-50 cursor-not-allowed': disabled }"
        data-testid="multi-select-custom-container"
      >
        <div
          :id="htmlId"
          class="flex flex-auto flex-wrap"
          tabindex="0"
          role="combobox"
          type="button"
          aria-haspopup="listbox"
          :aria-expanded="isOpen"
          aria-controls="listbox"
          :aria-labelledby="htmlId + '-label'"
          :aria-invalid="hasErrorMessage"
          :aria-describedby="errorId ?? 'multi-select-error'"
          :aria-disabled="disabled ?? false"
          @click="disabled === false && toggleIsOpen()"
          @keydown.enter="disabled === false && toggleIsOpen()"
        >
          <div v-if="showLoadingState" class="text-gray-400 text-sm py-1 px-2">Loading...</div>

          <div
            v-for="selectedOption in selectedOptions"
            :key="selectedOption.label"
            class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-blue-700 border border-blue-300"
            data-testid="selected-option"
            @click.stop
          >
            <div class="text-xs font-normal leading-none max-w-full flex-initial">{{ selectedOption.label }}</div>

            <button
              class="flex flex-auto flex-row-reverse"
              type="button"
              @click.stop="handleOptionRemoved(selectedOption as BaseInputMultiSelectOption<T>)"
              @keydown.enter.stop="handleOptionRemoved(selectedOption as BaseInputMultiSelectOption<T>)"
            >
              <p class="sr-only">Deselect {{ selectedOption.label }}</p>

              <FontAwesomeIcon
                class="cursor-pointer hover:text-blue-400 rounded-full w-2.5 h-2.5 ml-2"
                :icon="['fas', 'x']"
                fixed-width
                aria-hidden="true"
              />
            </button>
          </div>

          <div
            v-if="showLoadingState === false && emptyMessage !== undefined && selectedOptions.length === 0"
            class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-blue-700 border border-blue-300"
          >
            <div class="text-xs font-normal leading-none max-w-full flex-initial">{{ emptyMessage }}</div>
          </div>

          <div v-if="showLoadingState === false && options.length === 0" class="text-gray-400 text-sm py-1 px-2">
            No options available
          </div>
        </div>

        <div class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200">
          <button
            class="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none"
            type="button"
            @click="disabled === false && toggleIsOpen()"
          >
            <span class="sr-only">Open Menu</span>

            <FontAwesomeIcon
              v-show="isOpen"
              class="w-3 h-3"
              :icon="['fas', 'chevron-up']"
              fixed-width
              data-testid="multiselect-custom-chevron-up"
              aria-hidden="true"
            />

            <FontAwesomeIcon
              v-show="!isOpen"
              class="w-3 h-3"
              :icon="['fas', 'chevron-down']"
              fixed-width
              data-testid="multiselect-custom-chevron-down"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </div>

    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="isOpen"
        class="absolute shadow bg-white z-40 w-full top-full left-0 rounded max-h-64 overflow-y-auto"
      >
        <ul class="flex flex-col w-full" role="listbox" tabindex="-1">
          <li v-if="showLoadingState" class="text-gray-400 text-sm py-1 px-2">Loading...</li>

          <li v-else-if="showLoadingState === false && options.length === 0" class="text-gray-400 text-sm py-1 px-2">
            No options available
          </li>

          <li
            v-for="(option, index) in props.options"
            :key="option.label"
            class="cursor-pointer w-full border-gray-100 border-b"
            :aria-selected="isOptionSelected(option)"
            :class="[isOptionActive(index) ? 'bg-blue-600 text-white' : 'text-gray-900']"
            tabindex="0"
            role="option"
            @click="handleOptionAdded(option)"
            @keydown.enter="handleOptionAdded(option)"
            @mouseover="activeIndex = index"
            @mouseleave="activeIndex = undefined"
            @focusin="activeIndex = index"
            @focusout="activeIndex = undefined"
          >
            <div class="relative w-full items-center pl-8 pr-4 py-2 truncate">
              <span
                data-testid="multiselect-custom-option-label"
                :class="[isOptionActive(index) || isOptionSelected(option) ? 'font-semibold' : 'font-normal']"
                class="text-sm"
              >
                {{ option.label }}
              </span>

              <div
                :class="[isOptionActive(index) ? 'text-white' : 'text-blue-600']"
                data-testid="multiselect-custom-check-container"
                class="absolute inset-y-0 left-0 flex items-center pl-1.5"
              >
                <FontAwesomeIcon
                  v-if="isOptionSelected(option)"
                  class="h-4 w-4"
                  :icon="['fas', 'check']"
                  fixed-width
                  aria-hidden="true"
                  data-testid="multiselect-custom-check"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>

  <p
    v-if="hasErrorMessage"
    :id="errorId ?? 'multi-select-error'"
    class="text-red-900 text-sm mt-1"
    data-testid="multi-select-error"
  >
    {{ errorMessage }}
  </p>
</template>

<script lang="ts" setup generic="T extends string | number | undefined | null">
import useIsOpenComposable from "@/base/composables/IsOpenComposable.ts";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import { ClickAway } from "@/base/directives/ClickAwayDirective.ts";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faChevronDown, faChevronUp, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, defineEmits, defineExpose, defineProps, ref, watch } from "vue";

export interface BaseInputMultiSelectOption<T> {
  htmlValue: T;
  label: string;
}

const props = defineProps<{
  /**
   * The options to display in the drop down menu.
   */
  options: BaseInputMultiSelectOption<T>[];

  /**
   * The array of selected values. This is a v-model prop.
   */
  modelValue?: T[] | null | undefined;

  /** The label for the select */
  htmlLabel: string;

  /** Whether to show the label */
  hideLabel: boolean;

  /** The id for the combo box */
  htmlId: string;

  /** Whether the select is disabled */
  disabled?: boolean | undefined;

  /** The error id html attribute */
  errorId?: string | undefined;

  /** The error message to display */
  errorMessage?: string | undefined;

  /** Display an item in the list when no options are selected */
  emptyMessage?: string | undefined;

  /** Whether to show the loading state */
  showLoadingState?: boolean | undefined;

  /** The label class */
  labelClass?: string | undefined;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: T[]): void;
  (e: "toggleOpen", isOpen: boolean): void;
}>();

defineExpose({});

defineOptions({
  directives: {
    ClickAway,
  },
});

library.add(faChevronDown, faChevronUp, faX, faCheck);

/** Model value */
const { updateModelValue } = useModelValue<T[]>();

const toggleOptionInModelValue = (option: BaseInputMultiSelectOption<T>): void => {
  let newModelValue = props.modelValue;

  if (props.disabled) {
    return;
  }

  if (newModelValue === null || newModelValue === undefined) {
    newModelValue = [];
  }

  if (newModelValue.includes(option.htmlValue)) {
    newModelValue = [...newModelValue.filter((value: unknown) => value !== option.htmlValue)];
  } else {
    newModelValue = [...newModelValue, option.htmlValue];
  }

  updateModelValue(newModelValue);
};

const handleOptionRemoved = (option: BaseInputMultiSelectOption<T>): void => {
  toggleOptionInModelValue(option);
};

const handleOptionAdded = (option: BaseInputMultiSelectOption<T>): void => {
  toggleOptionInModelValue(option);
};

/** Selected Options */
const selectedOptions = computed(() => {
  const local = [] as BaseInputMultiSelectOption<T>[];

  if (props.modelValue === null || props.modelValue === undefined) {
    return props.options.filter((option: BaseInputMultiSelectOption<T>) => option.htmlValue === props.modelValue);
  }

  /** Sort by modelValue so order is user click order. */
  props.modelValue.forEach((value: T) => {
    const foundOption = props.options.find(
      (localOption: BaseInputMultiSelectOption<T>) => localOption.htmlValue === value
    );

    /* istanbul ignore if -- @preserve */
    if (foundOption === undefined) {
      return;
    }

    local.push(foundOption);
  });

  return local;
});

const isOptionSelected = (option: BaseInputMultiSelectOption<T>): boolean => {
  return selectedOptions.value.includes(option);
};

/** Open select menu */
const { isOpen, toggleIsOpen, close } = useIsOpenComposable();

watch(isOpen, (newIsOpen) => {
  emit("toggleOpen", newIsOpen);
});

/** Active Options - i.e focused or hovered */

const activeIndex = ref<number | undefined>(undefined);

const isOptionActive = (index: number): boolean => {
  return activeIndex.value === index;
};

const hasErrorMessage = computed(() => {
  return props.errorMessage !== undefined;
});

const displayLabelClass = computed(() => {
  return props.labelClass ?? "text-sm font-medium text-gray-700";
});

const onClickAway = (): void => {
  close();
};
</script>
