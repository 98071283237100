import z from "zod";

const PaginationLinksSchema = z.object({
  /** The first page api url */
  first: z.string().url(),
  /** The last page api url */
  last: z.string().url(),
  /** The previous page api url */
  prev: z.string().url().nullable(),
  /** The next page api url */
  next: z.string().url().nullable(),
});

const PaginationMetaLinkSchema = z.object({
  /** The page api url */
  url: z.string().url().nullable(),
  /** The page label Prev, Next or a page number */
  label: z.string(),
  /** Is this link the active one */
  active: z.boolean(),
});

/** Parses a user object */
const PaginationMetaSchema = z.object({
  /** The current page number */
  current_page: z.number(),
  /** last pagination page */
  last_page: z.number(),
  /** Prev, Next and all page pagination links */
  links: z.array(PaginationMetaLinkSchema),
  /** base api path */
  path: z.string(),
  /** The currently used number of results per page */
  per_page: z.number(),
  /** Index of the first result in set */
  from: z.number().nullable(),
  /** Index of the last result in set */
  to: z.number().nullable(),
  /** Total number of results in set */
  total: z.number(),
});

export { PaginationLinksSchema, PaginationMetaLinkSchema, PaginationMetaSchema };
