import type { PropType } from "vue";
import type Breadcrumb from "../interfaces/BreadcrumbInterface.ts";

const BreadcrumbsProp = {
  /** The breadcrumbs. array of arrays keyed with link & text. Set link to '' to have no linking for that crumb */
  breadcrumbs: {
    default: () => [],
    required: false,
    type: Array as PropType<Breadcrumb[] | Readonly<Breadcrumb[]>>,
    validator(value: unknown) {
      return Array.isArray(value);
    },
  },
};

export default BreadcrumbsProp;
