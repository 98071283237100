import { sanitizeUrl } from "@braintree/sanitize-url";

/**
 * Sanitize url function
 * @see https://sonarcloud.io/organizations/devtropolis/rules?open=typescript%3AS6299&rule_key=typescript%3AS6299
 * @param url the url to sanitize
 * @returns the sanitized url
 */
type Sanitize = (url: string) => string;

/**
 * Sanitize url composable
 * @returns the sanitize url composable
 */
interface SanitizeUrlComposable {
  sanitize: Sanitize;
}

/**
 * Sanitize url composable
 * @returns the sanitize url composable
 */
export default function useSanitizeUrl(): SanitizeUrlComposable {
  const sanitize: Sanitize = (url: string): string => {
    if (typeof url !== "string") {
      throw new TypeError(`Expected a \`string\`, got \`${typeof url}\``);
    }

    return sanitizeUrl(url);
  };

  return {
    sanitize,
  };
}
