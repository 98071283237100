<template>
  <div>
    <template v-for="item in items" :key="item.key">
      <BaseNavMenuDropdownItem
        v-if="item.type === 'dropdown'"
        :label="item.label"
        :without-icon="item.withoutIcon"
        :items="item.items"
        :expanded="expandedKey === item.key"
        @update-expanded="handleExpandedUpdate($event as boolean, item.key)"
      >
        <template #icon="iconProps">
          <slot :name="item.key + '-icon'" v-bind="iconProps"> </slot>
        </template>
      </BaseNavMenuDropdownItem>

      <BaseNavMenuLinkItem v-else :label="item.label" :to="item.to" :without-icon="item.withoutIcon">
        <template #icon="iconProps"> <slot :name="item.key + '-icon'" v-bind="iconProps"> </slot> </template
      ></BaseNavMenuLinkItem>
    </template>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseNavMenuDropdownItem from "@/base/components/menus/navMenus/items/BaseNavMenuDropdownItem.vue";
import BaseNavMenuLinkItem from "@/base/components/menus/navMenus/items/BaseNavMenuLinkItem.vue";
import type { BaseNavMenuDropdownItem as BaseNavMenuDropdownItemInterface } from "@/base/interfaces/menus/navMenus/BaseNavMenuDropdownItem.ts";
import type { BaseNavMenuLinkItem as BaseNavMenuLinkItemInterface } from "@/base/interfaces/menus/navMenus/BaseNavMenuLinkItem.ts";

export default defineComponent({
  name: "BaseNavMenu",

  components: {
    BaseNavMenuDropdownItem,
    BaseNavMenuLinkItem,
  },

  props: {
    /**
     * The top level nav items.
     */
    items: {
      type: Array as PropType<(BaseNavMenuDropdownItemInterface | BaseNavMenuLinkItemInterface)[]>,

      required: true,
    },
  },

  data() {
    return {
      expandedKey: "",
    };
  },

  methods: {
    handleExpandedUpdate(expanded: boolean, key: string) {
      this.expandedKey = expanded ? key : /* istanbul ignore next -- @preserve */ this.expandedKey;
    },
  },

  expose: [],
});
</script>
