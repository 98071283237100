import HttpClientErrorDataClass from "@/app/http/classes/HttpClientErrorDataClass.ts";
import useNotificationStore from "@/base/stores/NotificationStore.ts";
import NavigateToErrorPageFromAxiosError from "@/routing/functions/NavigateToErrorPage.ts";
import type { ErrorBagInterface } from "@/validation/classes/ErrorBag.ts";
import ErrorBag from "@/validation/classes/ErrorBag.ts";
import * as Sentry from "@sentry/vue";
import type { AxiosError } from "axios";
import { v4 as uuidv4 } from "uuid";
import type { Ref } from "vue";
import { ref, unref } from "vue";
import { useRouter } from "vue-router";
import type { ZodSchema } from "zod";

type ResponseData = Ref<unknown | undefined>;
type ResponseErrorBag = Ref<ErrorBagInterface>;
type ResetResponseErrorBag = () => void;
type ResponseErrorMessage = Ref<string | undefined>;
type ResponseMessage = Ref<string | undefined>;
type IsPending = Ref<boolean>;

interface UsePutExportsInterface {
  /** The json data from the response */
  responseData: ResponseData;

  /** The axios error response data errors parsed into a error bag */
  responseErrorBag: ResponseErrorBag;

  /** Resets the response error bag errors so it is empty */
  resetResponseErrorBag: ResetResponseErrorBag;

  /** The axios error response data message */
  responseErrorMessage: ResponseErrorMessage;

  /** The axios response data message */
  responseMessage: ResponseMessage;

  /** Used to determine if put request is in progress */
  isPending: IsPending;
}

interface Request {
  url: string;
  data: unknown;
  isPending: boolean;
  uuid: string;
}

interface BasePutOptions {
  /** What to do on server failure */
  onFailure?: "navigateToErrorPage" | "pushNotification" | undefined;

  /** What to do on server request success */
  onSuccess?: "pushNotification" | undefined;
}

interface SchemaPutOptions extends BasePutOptions {
  /** What to do on schema parse failure */
  onSchemaParseFailure: "pushNotification" | undefined;
}

export type PutOptions = BasePutOptions | SchemaPutOptions;

export default function usePut<ParsedData>(
  url: Ref<string> | string,
  schema: ZodSchema<ParsedData>,
  options?: SchemaPutOptions
): UsePutExportsInterface & {
  /**
   * Perform a put request
   * @param data the put data
   * @throws {AxiosError} will rethrow any axios errors so they get logged
   * @throws {Error} if we fail to parse error data
   * @returns the promise containing the api endpoint response data
   */
  doPut: (data: unknown, perRequestOptions?: PutOptions) => Promise<ParsedData>;
  /** Parsed response data from schema */
  parsedResponseData: Ref<ParsedData>;
};

export default function usePut(
  url: Ref<string> | string,
  schema?: undefined,
  options?: BasePutOptions
): UsePutExportsInterface & {
  /**
   * Perform a put request
   * @param data the put data
   * @throws {AxiosError} will rethrow any axios errors so they get logged
   * @throws {Error} if we fail to parse error data
   * @returns the promise containing the api endpoint response data
   */
  doPut: (data: unknown, perRequestOptions?: BasePutOptions) => Promise<unknown>;
  /** No schema provided so parsed response data is undefined */
  parsedResponseData: Ref<undefined>;
};

/**
 * Consume `usePut` composable
 * @param url the url to put to
 * @param schema the schema to parse the response data with
 * @param options the options to pass to the composable
 * @returns exposed composable methods and variables
 */
export default function usePut<ParsedData>(
  url: Ref<string> | string,
  schema: ZodSchema<ParsedData> | undefined,
  options: BasePutOptions = {}
): UsePutExportsInterface & {
  doPut: (data: unknown, perRequestOptions: PutOptions) => Promise<ParsedData | unknown>;
  /** Parsed response data from schema */
  parsedResponseData: Ref<ParsedData | undefined>;
} {
  const responseData: ResponseData = ref(undefined);
  const parsedResponseData: Ref<ParsedData | undefined> = ref(undefined);
  const responseErrorBag: ResponseErrorBag = ref(ErrorBag.new({}));
  const responseErrorMessage: ResponseErrorMessage = ref(undefined);
  const responseMessage: ResponseMessage = ref(undefined);
  const outstandingRequests: Ref<Request[]> = ref([]);
  const isPending: IsPending = ref(false);
  const router = useRouter();

  const { push } = useNotificationStore();

  const resetResponseErrorBag: ResetResponseErrorBag = function resetResponseErrorBag(): void {
    responseErrorBag.value = ErrorBag.new({});
  };

  /**
   * Handle the is pending state after a request ends
   * @param outstandingRequestUuid the uuid of the request to handle
   */
  function handleIsPendingAfterRequestEnd(outstandingRequestUuid: string): void {
    const requestIndex = outstandingRequests.value.findIndex((request) => request.uuid === outstandingRequestUuid);

    outstandingRequests.value.splice(requestIndex, 1);

    if (outstandingRequests.value.length === 0) {
      isPending.value = false;
    }
  }

  const doPut = async function doPut(data: unknown, perRequestOptions: PutOptions = {}): Promise<ParsedData | unknown> {
    // reset state before putting
    responseData.value = undefined;
    parsedResponseData.value = undefined;
    responseMessage.value = undefined;
    responseErrorMessage.value = undefined;
    resetResponseErrorBag();
    isPending.value = true;

    const uuid = uuidv4();

    outstandingRequests.value.push({
      url: unref(url),
      data: unref(data),
      isPending: true,
      uuid,
    });

    let response;

    try {
      response = await window.httpClient.put(unref(url), unref(data));

      responseData.value = response.data;
      // set the response message from the response data message
      responseMessage.value = response.data.message ?? undefined;
    } catch (error) {
      handleIsPendingAfterRequestEnd(uuid);

      const dataClass = HttpClientErrorDataClass.new(error as Error);

      responseErrorBag.value = dataClass.responseErrorBag;
      responseErrorMessage.value = dataClass.responseErrorMessage;

      if (
        "onFailure" in perRequestOptions
          ? perRequestOptions.onFailure === "pushNotification"
          : options.onFailure === "pushNotification"
      ) {
        push({
          title: "Error",
          message: dataClass.responseErrorMessage ?? "An error occurred",
          type: "error",
          duration: 5000,
        });
      }

      if (options.onFailure === "navigateToErrorPage") {
        await NavigateToErrorPageFromAxiosError(error as AxiosError, router);
      }

      return Promise.reject(error);
    }

    handleIsPendingAfterRequestEnd(uuid);

    if (
      "onSuccess" in perRequestOptions
        ? perRequestOptions.onSuccess === "pushNotification"
        : options.onSuccess === "pushNotification"
    ) {
      push({
        title: "Success",
        message: responseMessage.value ?? "Successfully Updated",
        type: "success",
        duration: 5000,
      });
    }

    const schemaValue = unref(schema);

    if (schemaValue !== undefined) {
      const schemaResponse = schemaValue.safeParse(responseData.value);

      if (!schemaResponse.success) {
        /** Push Notification on schema failure */
        const composableOptionsOnSchemaParseFailure =
          "onSchemaParseFailure" in options ? options.onSchemaParseFailure === "pushNotification" : false;

        const hasPerOptionsOnSchemaParseFailure = "onSchemaParseFailure" in perRequestOptions;
        const perOptionsOnSchemaParseFailure = hasPerOptionsOnSchemaParseFailure
          ? perRequestOptions.onSchemaParseFailure === "pushNotification"
          : false;

        if (
          hasPerOptionsOnSchemaParseFailure ? perOptionsOnSchemaParseFailure : composableOptionsOnSchemaParseFailure
        ) {
          push({
            title: "Error",
            message: "Could not parse data",
            type: "error",
            duration: 5000,
          });
        }

        /** Log error to sentry */
        Sentry.captureException(schemaResponse.error);

        // eslint-disable-next-line no-console
        console.error(schemaResponse.error);

        // Allow user to continue in production.
        if (window.baseAppVariables.isProduction === false) {
          throw schemaResponse.error;
        }
      }

      parsedResponseData.value = schemaResponse.success ? schemaResponse.data : response.data;

      return parsedResponseData.value;
    }
    return responseData.value;
  };

  return {
    responseData,
    parsedResponseData,
    doPut,
    responseErrorBag,
    resetResponseErrorBag,
    responseErrorMessage,
    responseMessage,
    isPending,
  };
}
