import z from "zod";

/**
 * Validate a string prop.
 * @param data The data to validate.
 * @returns Whether the data is valid.
 */
export default function StringPropValidator(data: string): boolean {
  return z.string().safeParse(data).success;
}
