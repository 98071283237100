<!-- istanbul ignore file -- @preserve -->
<template>
  <div class="!col-span-full">
    <Vueform :model-value="formData" display-errors sync @update:model-value="handleInput">
      <EditorElement
        v-bind="editorElementProps"
        :add-classes="{
          EditorWrapper: {
            container: [
              hidingAllTools ? 'base-input-wysiwyg-hide-tools' : '',
              preview ? 'base-input-wysiwyg-preview' : '',
            ],
          },
        }"
      />
    </Vueform>
  </div>
</template>

<script lang="ts">
/** istanbul ignore file -- @preserve */
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import type { EditorElementProps } from "@vueform/vueform";
import type { DebouncedFunc } from "lodash";
import { debounce } from "lodash";
import type { PropType } from "vue";
import { defineComponent } from "vue";

/** Define here as not defined in vueform */
type WysiwygTool =
  | "attach"
  | "bold"
  | "bullet-list"
  | "code"
  | "decrease-nesting"
  | "heading"
  | "increase-nesting"
  | "italic"
  | "link"
  | "number-list"
  | "quote"
  | "redo"
  | "strike"
  | "undo";

export const WysiwygTools: Readonly<WysiwygTool[]> = Object.freeze([
  "bold",
  "italic",
  "strike",
  "link",
  "heading",
  "quote",
  "code",
  "bullet-list",
  "number-list",
  "decrease-nesting",
  "increase-nesting",
  "attach",
  "undo",
  "redo",
]);

const props = {
  /** the date as a string in the same format as valueFormat */
  modelValue: {
    type: [String] as PropType<string | null | undefined>,
    required: false,
    default: undefined,
  },

  /** Whether to disable the input */
  disabled: {
    type: Boolean as PropType<EditorElementProps["disabled"]>,
    required: false,
    default: undefined,
  },

  /** Html id for the input */
  id: {
    type: String as PropType<EditorElementProps["id"]>,
    required: false,
    default: undefined,
  },

  /** Html name for the input */
  name: {
    type: [String, Number] as PropType<EditorElementProps["name"]>,
    required: true,
  },

  /** Debounce for the text input */
  debounce: {
    type: [String, Number] as PropType<EditorElementProps["debounce"]>,
    required: false,
    default: undefined,
  },

  /** Tools to hide */
  hideTools: {
    type: [Array, Boolean] as PropType<typeof WysiwygTools | true>,
    required: false,
    default: undefined,
  },

  /** Tells the input to be in 'preview' mode */
  preview: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
};

/**
 * Date input with label
 * @author Aaron MacDougall
 */
export default defineComponent({
  name: "BaseInputWysiwyg",

  inheritAttrs: false,

  expose: ["validate"],

  props,

  emits: {
    "update:modelValue": (value: string) => typeof value === "string",
  },

  setup() {
    const { updateModelValue } = useModelValue<string>();

    return {
      updateModelValue,
    };
  },

  data() {
    /* istanbul ignore if -- @preserve */
    if (this.name == null) {
      throw new Error("name cannot be null");
    }

    return {
      formData: {
        [this.name]: this.modelValue,
      },

      debounceFunction: undefined as DebouncedFunc<(data: Record<string, string>) => void> | undefined,
    };
  },

  computed: {
    hidingAllTools() {
      return this.editorElementProps.hideTools?.length === WysiwygTools.length;
    },

    safeName(): number | string {
      /* istanbul ignore if -- @preserve */
      if (this.name == null) {
        throw new Error("Cannot get value from a null name");
      }

      return this.name;
    },

    editorElementProps(): EditorElementProps {
      const { name } = this;

      /* istanbul ignore if -- @preserve */
      if (name == null) {
        throw new Error("name cannot be null");
      }

      return {
        ...(this.disabled === true || this.preview
          ? {
              disabled: true,
            }
          : {}),

        ...this.optionalProp("id"),
        ...(this.hideTools !== undefined
          ? {
              hideTools: (this.hideTools === true ? WysiwygTools : this.hideTools) as string[],
            }
          : {}),

        name,
      };
    },

    usesDebounce() {
      return this.debounce !== undefined && this.debounce > 0;
    },
  },

  watch: {
    disabled() {
      this.syncAttributes().catch(/* istanbul ignore next -- @preserve */ () => {});
    },

    preview() {
      this.syncAttributes().catch(/* istanbul ignore next -- @preserve */ () => {});
    },
  },

  mounted() {
    this.syncAttributes().catch(/* istanbul ignore next -- @preserve */ () => {});
  },

  created() {
    if (!this.usesDebounce) {
      return;
    }

    this.debounceFunction = debounce((data: Record<string, string>) => {
      this.handleUpdate(data);
    }, this.debounce);
  },

  methods: {
    validate() {
      return true;
    },

    async syncAttributes() {
      await this.$nextTick();

      const editor = document.querySelector("trix-editor");

      if (editor) {
        if (this.disabled === true || this.preview) {
          editor.removeAttribute("contenteditable");
        } else if (!editor.hasAttribute("contenteditable")) {
          editor.setAttribute("contenteditable", "true");
        }
      }
    },

    optionalValue<K extends string, T>(name: K, value: T) {
      return value !== undefined ? { [name]: value } : /* istanbul ignore next -- @preserve */ {};
    },

    optionalProp<T extends keyof typeof props>(propName: T) {
      return this.optionalValue(propName, this.$props[propName]);
    },

    handleInput(data: Record<string, string>) {
      if (this.disabled === true) {
        return;
      }

      if (this.usesDebounce && this.debounceFunction) {
        this.debounceFunction(data);

        return;
      }

      this.handleUpdate(data);
    },

    handleUpdate(data: Record<string, string>) {
      const newValue = data[this.safeName];

      /* istanbul ignore if -- @preserve */
      if (typeof newValue !== "string") {
        throw new Error("Unable to get value");
      }

      this.updateModelValue(newValue);
    },
  },
});
</script>

<style lang="scss">
.base-input-wysiwyg-hide-tools,
.base-input-wysiwyg-preview {
  trix-toolbar {
    display: none;
  }
}

.base-input-wysiwyg-hide-tools:not(.base-input-wysiwyg-preview) {
  trix-editor {
    padding-top: var(--vf-py-input);
  }
}

.base-input-wysiwyg-preview {
  outline: none;
  border: none;
  box-shadow: none;

  trix-editor {
    padding: 0;
    background: var(--vf-bg-input);
    color: var(--vf-color-input);
  }
}
</style>
