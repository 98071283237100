<template>
  <div class="text-sm text-gray-500 whitespace-nowrap">{{ modelValue?.length ?? 0 }} / {{ maxLength }}</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

export default defineComponent({
  name: "BaseMaxCharacterCount",
  expose: [],
  props: {
    /**
     * The value to count the length of
     */
    modelValue: {
      type: [String, null, undefined] as PropType<string | null | undefined>,
      required: false,
      default: undefined,
    },

    /**
     * The max length of the model value
     */
    maxLength: {
      type: Number,
      required: true,
    },
  },
});
</script>
