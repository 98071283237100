import MediaSchema from "@/media/schemas/Media.ts";
import { PartialUserSchema } from "@/user/schemas/UserSchema.ts";
import z from "zod";

const ActivityFeedSchema = z.object({
  id: z.number(),

  actor: PartialUserSchema,

  verb: z.string(),
  description: z.string(),

  subject: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable(),

  target: z.object({
    id: z.number(),
    name: z.string(),
  }),

  impersonated_by: PartialUserSchema.nullable(),

  created_at: z.string(),

  comment: z.string().nullish(),

  media: z.array(MediaSchema),
});

export default ActivityFeedSchema;
