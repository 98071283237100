<template>
  <ul :aria-label="props.htmlAriaLabel" :class="containerClass">
    <li v-for="item in props.items" :key="getKeyItem(item)" :class="props.itemClass">
      <slot name="item" :item="item" />
    </li>

    <template v-if="props.showLoadingState === false && items.length === 0">
      <slot name="no-items">
        <li :item-class="props.itemClass">
          <p class="text-gray-500">{{ props.noItemsMessage }}</p>
        </li>
      </slot>
    </template>

    <li v-if="props.tooManyItemsLimit && items.length > props.tooManyItemsLimit" :item-class="props.itemClass">
      <p class="text-gray-500">{{ props.tooManyItemsMessage }}</p>
    </li>

    <BaseLoadingRows
      v-if="props.showLoadingState"
      container-class="divide-y divide-gray-200"
      row-class="w-full py-2"
      :screen-reader-text="props.loadingStateText"
    />
  </ul>
</template>

<script lang="ts" setup generic="T extends Record<string, unknown>, K extends keyof T">
import BaseLoadingRows from "@/base/components/loading/BaseLoadingRows.vue";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    /** Items to iterate by */
    items: T[];

    /** Key to use for the item. */
    itemKey: K;

    /** Aria label for the list */
    htmlAriaLabel?: string;

    /** Show the loading state */
    showLoadingState?: boolean;

    /** Text for screen readers */
    loadingStateText?: string;

    /**
     * Message to display if no items.
     */
    noItemsMessage?: string;

    /**
     * Enable list limit
     *
     * Displays message if exceeds limit.
     */
    tooManyItemsLimit?: number | null;

    /**
     * Message to display if too many items
     */
    tooManyItemsMessage?: string;

    /**
     * Class to restrict scrollable area.
     *
     * Used to enable scrollable area within a container.
     *
     * If not provided, no scrollable area is provided.
     */
    scrollableRestrictionClass?: string | null;

    /**
     * Class to apply to each item.
     */
    itemClass?: string;
  }>(),
  {
    tooManyItemsLimit: null,
    scrollableRestrictionClass: null,
    htmlAriaLabel: "List of items",
    loadingStateText: "Loading",
    noItemsMessage: "No items found.",
    tooManyItemsMessage: "Too many items to display.",
    itemClass: "cursor-pointer py-2",
  }
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getKeyItem = (item: T) => {
  const key = item[props.itemKey];

  if (typeof key === "string" || typeof key === "number") {
    return key;
  }

  throw new Error("Item key must be a string.");
};

const containerClass = computed(() => {
  const classes = ["divide-y divide-gray-200"];

  if (typeof props.scrollableRestrictionClass === "string") {
    classes.push("overflow-y-auto");
    classes.push(props.scrollableRestrictionClass);
  }

  return classes;
});
</script>
