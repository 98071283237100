<template>
  <router-link v-slot="{ isActive, href, navigate }" :to="to" custom>
    <BaseNavMenuButton
      html-role="link"
      :href="href"
      :active="isActive"
      :label="label"
      :without-icon="withoutIcon"
      @click="navigate()"
    >
      <template #icon>
        <slot name="icon"></slot>
      </template>
    </BaseNavMenuButton>
  </router-link>
</template>

<script lang="ts">
import BaseNavMenuButton from "@/base/components/buttons/BaseNavMenuButton.vue";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { RouteLocationRaw } from "vue-router";

export default defineComponent({
  name: "BaseNavMenuLinkItem",
  expose: [],
  components: {
    BaseNavMenuButton,
  },

  props: {
    /**
     * Vue route
     */
    to: {
      required: true,
      type: [String, Object] as PropType<RouteLocationRaw>,
      validator: (to) => (typeof to === "string" ? assertStringIsNotBlank(to) : true),
    },

    /**
     * Hyperlink component text
     */
    label: {
      required: true,
      type: String,
      validator: (text) => assertStringIsNotBlank(text),
    },

    /** Whether to hide the icon */
    withoutIcon: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      required: false,
      default: false,
    },
  },
});
</script>
