enum MimeTypesEnum {
  jpeg = "image/jpeg",
  png = "image/png",
  gif = "image/gif",
  webp = "image/webp",
  svg = "image/svg+xml",
  heic = "image/heic",

  pdf = "application/pdf",
  doc = "application/msword",
  docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls = "application/vnd.ms-excel",
  xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ppt = "application/vnd.ms-powerpoint",
  pptx = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
}

export default MimeTypesEnum;
