/**
 * Dispatcher object
 */

import type { DispatcherInterface, DispatcherMethod, DispatcherMethods } from "./DispatcherInterface";

/**
 * Class used to dispatch multiple methods at once in a single call.
 *
 * Useful for organizing multiple methods that need to be called in a single place.
 */
class Dispatcher implements DispatcherInterface {
  public readonly methods: DispatcherMethods;

  public constructor() {
    this.methods = [];
  }

  public addDispatcherMethod(method: DispatcherMethod): void {
    this.methods.push(method);
  }

  public dispatch(): void {
    this.methods.forEach((method) => {
      method.callable();
    });
  }
}

export default Dispatcher;
