import { ref } from "vue";
import type { Ref } from "vue";
import ErrorBag from "@/validation/classes/ErrorBag.ts";
import type { ErrorBagInterface, ErrorBagErrorsConstructor, Field } from "@/validation/classes/ErrorBag.ts";

/**
 * Reusable logic for interacting with error bags in vue components
 * @author Jamie Wood
 * @returns return state object
 */
export default function useErrorBag(): {
  errorBag: Ref<ErrorBagInterface>;
  newErrorBag: (errors: ErrorBagErrorsConstructor) => void;
  findFirstMessageByFieldName: (field: Field) => string | undefined;
} {
  /**
   * The error bag
   */
  const errorBag = ref(ErrorBag.new({}));
  /**
   * Creates a new error bag
   * @param errors the error bag errors
   */
  function newErrorBag(errors: ErrorBagErrorsConstructor): void {
    errorBag.value = ErrorBag.new(errors);
  }

  /**
   * Find the first error message by field name
   * @param field the field to find
   * @returns the first found error message or undefined
   */
  function findFirstMessageByFieldName(field: Field): string | undefined {
    return errorBag.value.findFirstMessageByFieldName(field);
  }

  return {
    errorBag,
    newErrorBag,
    findFirstMessageByFieldName,
  };
}
