<template>
  <nav :aria-label="htmlAriaLabel">
    <ol class="space-y-4 md:flex md:space-x-8 md:space-y-0">
      <li v-for="step in displaySteps" :key="step.key" class="md:flex-1">
        <!-- Completed Step -->
        <button
          type="button"
          :disabled="disabled"
          class="flex flex-col w-full py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 disabled:cursor-not-allowed"
          :class="step.borderClasses"
          @click="handleClickStep(step)"
        >
          <span class="text-sm font-medium" :class="step.headingClasses">{{ step.title }}</span>

          <span class="text-sm font-medium">{{ step.description }}</span>
        </button>
      </li>
    </ol>
  </nav>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { Step } from "./BaseSteps.vue";

export default defineComponent({
  name: "BaseStepsSimple",
  expose: [],
  props: {
    /** The aria label for the steps */
    htmlAriaLabel: {
      required: true,
      type: String,
    },

    /** The steps to display */
    steps: {
      required: true,
      type: Array as PropType<Step[]>,
    },

    /** Whether the steps are disabled */
    disabled: {
      type: Boolean,
      required: false,
    },
  },

  emits: {
    clickStep: (_step: Step) => true,
  },

  computed: {
    displaySteps() {
      return this.steps.map((step) => {
        const borderClasses = [];
        const headingClasses = [];

        if (step.status === "current") {
          borderClasses.push("border-indigo-600");
          headingClasses.push("text-indigo-600");
        } else if (step.status === "completed") {
          borderClasses.push("group border-indigo-600 group hover:border-indigo-800");
          headingClasses.push("text-indigo-600 group-hover:text-indigo-800");
        } else {
          borderClasses.push("group border-gray-200 group hover:border-gray-300");
          headingClasses.push("text-gray-500 group-hover:text-gray-700");
        }

        return {
          ...step,
          borderClasses,
          headingClasses,
        };
      });
    },
  },

  methods: {
    handleClickStep(step: Step) {
      this.$emit("clickStep", step);
    },
  },
});
</script>
