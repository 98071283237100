<template>
  <fieldset>
    <legend :class="{ 'sr-only': hideLabel, [labelClass]: true }">{{ label }}</legend>

    <div class="-space-y-px rounded-md bg-white" :class="{ 'mt-2': !hideLabel }">
      <label
        v-for="(option, index) in options"
        :key="option.htmlId"
        :class="getLabelClass(index, option)"
        :for="option.htmlId"
        data-testid="radio-group-panel-option"
      >
        <input
          :id="option.htmlId"
          type="radio"
          :name="option.htmlName"
          :value="option.htmlValue"
          class="disabled:cursor-not-allowed mt-0.5 h-4 w-4 shrink-0 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-600 active:ring-2 active:ring-offset-2 active:ring-indigo-600"
          :aria-labelledby="`${option.htmlName}-${index}-label`"
          :aria-describedby="`${option.htmlName}-${index}-description`"
          :checked="isChecked(option)"
          :disabled="disabled"
          @change="updateModelValue(option.htmlValue)"
          @keydown.enter="updateModelValue(option.htmlValue)"
        />

        <span class="ml-3 flex flex-col">
          <span
            :id="`${option.htmlName}-${index}-label`"
            class="block text-sm font-medium"
            :class="[isChecked(option) ? 'text-blue-900' : 'text-gray-900']"
          >
            {{ option.label }}
          </span>

          <span
            :id="`${option.htmlName}-${index}-description`"
            class="block text-sm"
            :class="[isChecked(option) ? 'text-blue-700' : 'text-gray-500']"
          >
            {{ option.description }}
          </span>
        </span>
      </label>
    </div>
  </fieldset>

  <!-- Error message -->
  <p
    v-show="hasErrorMessage"
    :id="errorHtmlId"
    :aria-hidden="!hasErrorMessage"
    class="mt-2 text-sm text-red-600"
    data-testid="radio-group-panel-error"
  >
    {{ errorMessage }}
  </p>
</template>

<script lang="ts" setup generic="T extends string | boolean">
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import DisabledProp from "@/base/props/DisabledProp.ts";
import { computed, type PropType } from "vue";
import type { RadioGroupOption, RadioGroupOptions, RadioGroupOptionValues } from "./BaseRadioGroupPanelInterface.ts";

const props = defineProps({
  /** The list of options to display. */
  options: {
    type: Array as PropType<RadioGroupOptions<T>>,
    required: true,
  },

  /** The bound model value for the radio input. */
  modelValue: {
    required: false,
    type: [String, null, Boolean] as PropType<boolean | string | null | undefined>,
    default: undefined,
  },

  /** The label for the radio group panel. */
  label: {
    required: true,
    type: String,
  },

  ...DisabledProp,

  /** The error message to display. */
  errorMessage: {
    required: false,
    type: [String, undefined] as PropType<string | undefined>,
    default: undefined,
  },

  /** The HTML ID of the error message. */
  errorHtmlId: {
    required: false,
    type: String,
    default: "radio-group-panel-error",
  },

  /** Whether to hide the label. */
  hideLabel: {
    required: false,
    type: Boolean,
  },

  /**
   * Class for the field label.
   */
  labelClass: {
    type: String,
    required: false,
    default: "text-sm font-medium text-gray-700",
  },
});

defineEmits<{
  (e: "update:modelValue", value: RadioGroupOptionValues<T>): void;
  (e: "clickRadioGroupPanelOption", option: RadioGroupOption<T>): void;
}>();

const { updateModelValue } = useModelValue<RadioGroupOptionValues<T>>();

const isChecked = (option: RadioGroupOption<T>): boolean => {
  return (
    (typeof props.modelValue === "string" || typeof props.modelValue === "boolean") &&
    option.htmlValue === props.modelValue
  );
};

const hasErrorMessage = computed(() => {
  return typeof props.errorMessage === "string";
});

const getLabelClass = (optionIndex: number, option: RadioGroupOption<T>): string[] => {
  const classes = ["p-4", "focus:outline-none", "border", "cursor-pointer", "relative", "flex"];

  // Set the base label class.
  if (optionIndex === 0) {
    if (props.options.length === 1) {
      // Only option. Rounded.
      classes.push("rounded-md");
    } else {
      // First option. Rounded top.
      classes.push("rounded-tl-md rounded-tr-md");
    }
  } else if (optionIndex === props.options.length - 1) {
    // Last option. Rounded bottom.
    classes.push("rounded-bl-md rounded-br-md");
  }

  if (props.disabled) {
    classes.push("cursor-not-allowed bg-gray-50");
  } else if (isChecked(option)) {
    // Checked. Blue border and background.
    classes.push("z-10 border-blue-200 bg-blue-50");
  } else {
    // Not checked. Gray border.
    classes.push("border-gray-300");
  }

  return classes;
};
</script>
