<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div>
    <!-- Render provided signature -->
    <template v-if="signatureUrl && !editMode">
      <h4 data-testid="signed-by-text" class="text-sm font-medium text-gray-700">{{ signedByText }}</h4>

      <div class="mt-2 border border-gray-300 rounded-md">
        <img :src="signatureUrl" alt="Signature" class="w-full h-full" />
      </div>

      <div v-if="hideEditModeButton === false" class="mt-2 flex justify-end">
        <button
          type="button"
          :disabled="disabled"
          class="text-blue-500 hover:text-blue-400 cursor-pointer font-medium text-sm"
          @click="editMode = true"
        >
          Change
        </button>
      </div>
    </template>

    <!-- Signature canvas input -->
    <template v-else>
      <label :for="htmlId" class="block" data-testid="signature-label" :class="labelClass">
        <span
          data-testid="signature-label-text"
          v-bind="{
            ...(hideLabel ? { class: 'sr-only' } : {}),
          }"
        >
          {{ label }}
        </span>

        <VueSignaturePad
          :id="htmlId"
          ref="signaturePad"
          :disabled="disabled"
          aria-label="Please sign here"
          :class="[hasErrorMessage ? 'border-red-300' : 'border-gray-300', !hideLabel && 'mt-2']"
          class="w-full h-full border rounded-md disabled:bg-gray-50 disabled:cursor-not-allowed"
        />
      </label>

      <!-- Signature Canvas Error message -->
      <p
        v-show="hasErrorMessage"
        :id="errorId"
        :aria-hidden="!hasErrorMessage"
        class="mt-2 text-sm text-red-600"
        data-testid="signature-error-message"
      >
        {{ displayErrorMessage }}
      </p>

      <!--  Signature Canvas Button Controls -->
      <div class="space-x-3 mt-2 flex justify-end">
        <button
          v-if="editMode"
          class="text-gray-500 hover:text-gray-400 cursor-pointer text-sm disabled:cursor-not-allowed disabled:text-gray-300"
          :disabled="disabled"
          type="button"
          @click="editMode = false"
        >
          Cancel
        </button>

        <button
          class="text-blue-500 hover:text-blue-400 cursor-pointer text-sm disabled:cursor-not-allowed disabled:text-blue-300"
          type="button"
          :disabled="disabled"
          @click="save"
        >
          Save
        </button>

        <button
          class="text-gray-500 hover:text-gray-400 cursor-pointer text-sm disabled:cursor-not-allowed disabled:text-gray-300"
          type="button"
          :disabled="disabled"
          @click="undo"
        >
          Undo
        </button>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";
import type { PropType } from "vue";
import { defineComponent, ref } from "vue";
import { VueSignaturePad } from "vue-signature-pad";

export default defineComponent({
  name: "BaseInputSignature",
  expose: [],
  components: {
    VueSignaturePad,
  },

  /**
   * Do not inherit attributes from the parent component.
   *
   * This is because we want to control the attributes that are passed to the signature pad.
   */
  inheritAttrs: false,

  props: {
    /**
     * The model value for the input.
     *
     * This should be a file object.
     */
    htmlId: {
      required: true,
      type: String,
    },

    /**
     * Hide the label from the input.
     *
     * Will still render the label for screen readers.
     */
    hideLabel: {
      required: false,
      type: Boolean,
    },

    /**
     * The canvas label to display above the signature pad
     */
    label: {
      required: false,
      type: String,
      default: "Please sign here",
    },

    /**
     * Error message to render with the input
     *
     * Should only be used if there is a error to display
     */
    errorMessage: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * Id HTML attribute for error message
     */
    errorId: {
      required: false,
      type: String,
      default: "error",
      validator: (id) => assertStringIsNotBlank(id),
    },

    /**
     * URL to the signature image to render.
     *
     * If this is provided, the signature will be rendered as an image.
     * We also have a edit mode button to allow the user to change the signature.
     */
    signatureUrl: {
      required: false,
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    /**
     * Hide the edit mode button when the signature is rendered.
     *
     * This is useful when the signature is readonly and should not be changed.
     */
    hideEditModeButton: {
      required: false,
      type: Boolean,
    },

    /**
     * Text to display when the signature is rendered.
     */
    signedByText: {
      required: false,
      type: String,
      default: "Signed",
    },

    /**
     * Disable the component from emitting the model value event.
     *
     * This will prevent the user from saving or undoing the signature.
     * This wont disable the actual signature pad as we don't have a way to do that.
     */
    disabled: {
      required: false,
      type: Boolean,
    },

    /**
     * Class for the field label.
     */
    labelClass: {
      type: String,
      required: false,
      default: "text-sm font-medium text-gray-700",
    },
  },

  emits: {
    "update:modelValue": (_file: File) => true,
  },

  setup() {
    const signaturePad = ref<InstanceType<typeof VueSignaturePad>>();

    return {
      signaturePad,
    };
  },

  data() {
    return {
      editMode: false,
      localErrorMessage: undefined as string | undefined,
    };
  },

  computed: {
    displayErrorMessage() {
      return this.localErrorMessage ?? this.errorMessage;
    },

    hasErrorMessage() {
      return this.displayErrorMessage !== undefined;
    },
  },

  watch: {
    signatureUrl(val) {
      /* istanbul ignore else -- @preserve */
      if (val !== undefined) {
        this.editMode = false;
      }
    },
  },

  methods: {
    async save() {
      this.localErrorMessage = undefined;

      const validateRes = this.validate();

      if (validateRes === false) {
        return;
      }

      const getFile = async (signature: string): Promise<File> => {
        const response = await fetch(signature);
        const blob = await response.blob();
        const file = new File([blob], "signature", { type: "image/png" });

        return file;
      };

      const file = await getFile(validateRes).catch(
        /* istanbul ignore next -- @preserve */ (error) => {
          this.localErrorMessage = "Failed to save signature. Please contact support.";

          throw error;
        }
      );

      this.$emit("update:modelValue", file);
    },

    undo() {
      const localSignaturePad = this.strictSignaturePad();

      localSignaturePad.undoSignature();
    },

    validate() {
      const localSignaturePad = this.strictSignaturePad();

      const signature = localSignaturePad.saveSignature();

      if (signature.isEmpty) {
        this.localErrorMessage = "Please sign before saving.";

        return false;
      }

      return signature.data;
    },

    strictSignaturePad() {
      /* istanbul ignore if -- @preserve */
      if (this.signaturePad === undefined) {
        this.localErrorMessage = "Failed to load signature pad. Please contact support.";

        throw new Error("Signature pad is not mounted.");
      }

      return this.signaturePad;
    },
  },
});
</script>
