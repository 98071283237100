import z from "zod";
import VaporStoreResponseSchema from "./VaporStoreResponseSchema.ts";

/**
 * The response from the vapor store request.
 */
const VaporMediaSchema = VaporStoreResponseSchema.extend({
  file: z.instanceof(File),
});

export default VaporMediaSchema;
