import MimeTypesEnum from "../enums/MimeTypesEnum.ts";

/**
 * Reusable code for accessing mime types for different types of files.
 * @returns composable
 */
export default function useMimeTypesComposable(): {
  getDefaultImageMimeTypes: () => (MimeTypesEnum | string)[];
  getDefaultMimeTypes: () => (MimeTypesEnum | string)[];
} {
  const getDefaultImageMimeTypes = (): (MimeTypesEnum | string)[] => {
    return [
      MimeTypesEnum.jpeg,
      MimeTypesEnum.png,
      MimeTypesEnum.gif,
      MimeTypesEnum.webp,
      MimeTypesEnum.svg,
      MimeTypesEnum.heic,
    ];
  };
  const getDefaultDocumentMimeTypes = (): (MimeTypesEnum | string)[] => {
    return [
      MimeTypesEnum.pdf,
      MimeTypesEnum.doc,
      MimeTypesEnum.docx,
      MimeTypesEnum.xls,
      MimeTypesEnum.xlsx,
      MimeTypesEnum.ppt,
      MimeTypesEnum.pptx,
    ];
  };
  const getDefaultMimeTypes = (): (MimeTypesEnum | string)[] => {
    return [...getDefaultImageMimeTypes(), ...getDefaultDocumentMimeTypes()];
  };

  return {
    /**
     * Get the default image mime types.
     * @returns Mime type enum values
     */
    getDefaultImageMimeTypes,
    /**
     * Get the default mime types.
     * @returns Mime type enum values
     */
    getDefaultMimeTypes,
  };
}
