import StringPropValidator from "@/validation/props/StringPropValidator.ts";
import type { PropType } from "vue";

const OptionalStringProp = {
  required: false as const,
  type: String as PropType<string | undefined>,
  validator: StringPropValidator,
};

const RequiredStringProp = {
  required: true as const,
  type: String,
  validator: StringPropValidator,
};

export { OptionalStringProp, RequiredStringProp };
