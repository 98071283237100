<template>
  <label :for="id">
    <span class="sr-only">Select a tab</span>

    <!-- Use an "onBlur" listener to redirect the user to the selected tab URL. -->
    <select
      :id="id"
      :name="name"
      class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500"
      @change="handleChange($event)"
    >
      <RouterLink v-for="tab in tabs" :key="tab.name" v-slot="{ isActive }" :to="tab.to" custom>
        <option :key="tab.name" :value="tab.name" :selected="isActive" :aria-selected="isActive">
          {{ tab.name }}
        </option>
      </RouterLink>
    </select>
  </label>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import getTabsProp from "../TabProps.ts";

export default defineComponent({
  name: "BaseMobileTabMenu",
  expose: [],

  props: {
    /**
     * The tabs to display.
     */
    tabs: getTabsProp(true),

    /**
     * The id of the tab menu.
     */
    id: {
      required: true,
      type: String as PropType<string>,
    },

    /**
     * The name of the tab menu.
     */
    name: {
      required: true,
      type: String as PropType<string>,
    },
  },

  methods: {
    async handleChange($event: Event) {
      // 1. Find the selected tab by matching the name in the select element
      const target = $event.target as HTMLSelectElement;
      const selectedTab = this.tabs.find((tab) => tab.name === target.value);

      // 2. If the tab exists, redirect to the tab's route
      if (selectedTab !== undefined) {
        await this.$router.push(selectedTab.to);
      }
    },
  },
});
</script>
