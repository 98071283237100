import errorHandler from "../../app/error/ErrorHandler.ts";

/**
 * Reusable code for handling unexpected errors.
 * @returns functions
 */
function useErrorHandlerComposable(): {
  handleErrorWithoutFailing: (error: Error) => void;
} {
  const handleErrorWithoutFailing = (error: Error): void => {
    errorHandler(error);
  };

  return {
    handleErrorWithoutFailing,
  };
}

export default useErrorHandlerComposable;
