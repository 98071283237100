/**
 * Gets the Red/Green/Blue colour for the percentage value based on bounds.
 * @param percentageValue Percentage
 * @returns colour
 */
export function getRagColour(percentageValue: number | undefined): string {
  /* istanbul ignore if -- @preserve */
  if (percentageValue == null) return "#1ba14a";

  /* istanbul ignore if -- @preserve */
  if (percentageValue > 90) return "#1ba14a";
  /* istanbul ignore if -- @preserve */
  if (percentageValue > 60) return "#f6993f";

  return "#d12346";
}

export default getRagColour;
