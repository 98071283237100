<template>
  <div class="pb-2 sm:flex sm:items-center sm:justify-between">
    <div>
      <h3 class="text-2xl font-bold leading-6 text-gray-900">{{ title }}</h3>

      <p v-if="description" class="mt-2 max-w-4xl text-sm text-gray-500">
        {{ description }}
      </p>
    </div>

    <div class="sm:flex mt-3 sm:ml-4 sm:mt-0 sm:space-x-3 sm:space-y-0 space-y-3">
      <slot name="inputs" />

      <slot name="buttons" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * Base header component used for page sections
 */
export default defineComponent({
  name: "BaseHeader",
  expose: [],
  props: {
    /**
     * The title of the header.
     */
    title: {
      required: true,
      type: String,
    },

    /**
     * The description of the header.
     */
    description: {
      required: false,
      type: String,
      default: "",
    },
  },
});
</script>
