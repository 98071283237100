<template>
  <slot
    :is-posting="isPosting"
    :post="post"
    :response-error-bag="responseErrorBag"
    :reset-response-error-bag="resetResponseErrorBag"
  />
</template>

<script lang="ts" setup generic="T">
import UsePostComposable from "@/app/http/composables/UsePostComposable.ts";
import UseNotificationStore from "@/base/stores/NotificationStore.ts";
import type { RouteLocationRaw } from "vue-router";
import { useRouter } from "vue-router";
import type { ZodSchema } from "zod";

const props = defineProps<{
  /** The URL to post to */
  postUrl: string;
  /** Configuration object for navigating back to previous page after post */
  navigateBackAfterPost?: { fallback: RouteLocationRaw } | undefined;
  /** Notification message to be displayed after post is successful */
  successMessage?: string | undefined;
  // eslint-disable-next-line no-undef, vue/require-prop-comment
  schema?: ZodSchema<T>;
}>();
const router = useRouter();
const { push } = UseNotificationStore();
const { doPost, isPosting, responseErrorBag, resetResponseErrorBag, parsedResponseData, responseMessage } =
  UsePostComposable(props.postUrl, props.schema, {
    onFailure: "pushNotification",
    onSchemaParseFailure: "pushNotification",
  });

/**
 * Post data to the API
 * @param data The data to post
 * @returns The response data
 */
async function post(data: unknown): Promise<T | undefined> {
  // Clear the error bag after each request attempt
  resetResponseErrorBag();

  let response: T | undefined;

  // Make the request and return the response
  try {
    response = await doPost(data);
  } catch (error) {
    return response;
  }

  let successMessage;

  if (props.successMessage !== undefined) {
    successMessage = props.successMessage;
  } else if (responseMessage.value !== undefined) {
    successMessage = responseMessage.value;
  } else {
    successMessage = "Successfully Created";
  }

  push({
    title: "Success",
    message: successMessage,
    type: "success",
  });

  const backFallback = props.navigateBackAfterPost?.fallback;

  // If fallback is configured we assume the developer wants to go back to the previous page
  if (backFallback !== undefined) {
    // If a previous page exists in the router history, go back
    if (router.options.history.state["back"] !== undefined) {
      router.back();
    } else {
      // Otherwise, go to the fallback page
      await router.push(backFallback);
    }
  }

  if (props.schema !== undefined) {
    return parsedResponseData.value;
  }

  return response;
}
</script>

<script lang="ts">
/**
 * Reusable component for wrapping create pages/views
 *
 * - Contains reusable logic for posting data to a API
 * - Contains reusable logic for navigating back to previous page after post
 * - Contains reusable logic for displaying response error messages
 */
export default {
  name: "BasePostContainer",
};
</script>
