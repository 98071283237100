<template>
  <div class="grid gap-x-2 gap-y-2" :class="gridColsClass" role="group" aria-labelledby="check-group-buttons-label">
    <title id="check-group-buttons-label" class="sr-only">
      {{ label }}
    </title>

    <template v-for="(option, optionIdx) in options" :key="option.value">
      <button
        :id="getButtonId(option)"
        :for="getCheckboxId(option)"
        :disabled="disableInputs"
        :class="[
          getButtonClass(),
          isChecked(optionIdx) ? getActiveButtonClass(option.value) : getInactiveButtonClass(option.value),
        ]"
        type="button"
        tabindex="0"
        @click="handleCheckboxChange(option, !isChecked(optionIdx))"
      >
        <span class="capitalize">{{ option.buttonText }}</span>

        <input
          :id="getCheckboxId(option)"
          :disabled="disableInputs"
          type="checkbox"
          :value="option.value"
          class="hidden"
          :aria-labelledby="getButtonId(option)"
          :checked="isChecked(optionIdx)"
          :aria-checked="isChecked(optionIdx)"
        />
      </button>
    </template>
  </div>
</template>

<script setup lang="ts" generic="T extends string | number">
import type { PropType } from "vue";
import { computed, ref, unref } from "vue";
import useModelValue from "@/base/composables/ModelValueComposable.ts";
import { getSmallGridColsClass } from "@/base/functions/css/grid/GridFunctions.ts";
import type { GridColsSize } from "@/base/functions/css/grid/GridFunctions.ts";

interface CheckGroupOption {
  value: T;

  buttonText: string;
}

type CheckGroupOptions = CheckGroupOption[] | readonly CheckGroupOption[];

type CheckGroupOptionValue = CheckGroupOptions[number]["value"];
type CheckGroupOptionValues = CheckGroupOptionValue[];

const props = defineProps({
  /** The HTML ID prefix to use for the buttons. */
  htmlIdPrefix: {
    required: false,
    type: String as PropType<string | undefined>,
    default: undefined,
  },

  /** The options to display. */
  options: {
    required: true,
    type: Array as PropType<CheckGroupOptions>,
  },

  /** The field metadata to configure. */
  modelValue: {
    required: true,
    type: [Array, null] as PropType<CheckGroupOptionValues | null | undefined>,
  },

  /** The class to use for the buttons. */
  buttonClass: {
    required: false,
    type: String as PropType<string | undefined>,
    default: undefined,
  },

  /** The class to use for the active buttons. */
  activeButtonClass: {
    required: false,
    type: [String, Function] as PropType<
      string | ((optionValue: CheckGroupOptionValue) => string[] | string) | undefined
    >,
    default: undefined,
  },

  /** The class to use for the inactive buttons. */
  inactiveButtonClass: {
    required: false,
    type: [String, Function] as PropType<
      string | ((optionValue: CheckGroupOptionValue) => string[] | string) | undefined
    >,
    default: undefined,
  },

  /** Used to disable the checkbox inputs */
  disableInputs: {
    required: false,
    type: Boolean,
  },

  /** Used to set the grid size */
  gridColsSize: {
    required: false,
    default: 2,
    type: [String, Number] as PropType<GridColsSize>,
  },

  /** The label to use for the checkbox group. */
  label: {
    required: false,
    type: String,
    default: "Checkbox Group",
  },

  /** Enforce that at least one option is checked. */
  atLeastOneOptionChecked: {
    required: false,
    type: Boolean,
  },
});

defineEmits<{
  (e: "update:modelValue", value: CheckGroupOptionValues): void;
}>();

const gridColsClass = ref(getSmallGridColsClass(props.gridColsSize));

const { updateModelValue } = useModelValue<CheckGroupOptionValues>();

const getCheckboxId = (option: CheckGroupOption): string => {
  return `${props.htmlIdPrefix}-${option.value}-checkbox`;
};

const getButtonId = (option: CheckGroupOption): string => {
  return `${props.htmlIdPrefix}-${option.value}-button`;
};

const getButtonClass = (): string => {
  return (
    props.buttonClass ??
    "px-4 py-2 text-base shadow-sm font-medium rounded-md inline-flex items-center justify-center cursor-pointer h-10 w-full sm:col-span-1 col-span-2"
  );
};

const getActiveButtonClass = (optionValue: CheckGroupOptionValue): string[] | string => {
  if (typeof props.activeButtonClass === "function") {
    return props.activeButtonClass(optionValue);
  }

  if (typeof props.activeButtonClass === "string") {
    return props.activeButtonClass;
  }

  return "bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed";
};

const getInactiveButtonClass = (optionValue: CheckGroupOptionValue): string[] | string => {
  if (typeof props.inactiveButtonClass === "function") {
    return props.inactiveButtonClass(optionValue);
  }

  if (typeof props.inactiveButtonClass === "string") {
    return props.inactiveButtonClass;
  }

  return "border-gray-300 border text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 disabled:bg-gray-50 disabled:cursor-not-allowed";
};

const handleCheckboxChange = (option: CheckGroupOption, checked: boolean): void => {
  const modelValues = unref(props.modelValue);
  const newValues: CheckGroupOptionValues = modelValues ? [...modelValues] : [];

  if (checked) {
    newValues.push(option.value);
  } else {
    const index = newValues.findIndex((value) => value === option.value);

    /* istanbul ignore if -- @preserve */
    if (index !== -1) {
      newValues.splice(index, 1);
    }
  }

  if (props.atLeastOneOptionChecked && newValues.length === 0) {
    return;
  }

  updateModelValue(newValues);
};

const checkedOptionsIndexes = computed(() => {
  const checkedOptions: CheckGroupOptionValues = props.modelValue ?? [];

  return checkedOptions.map((option) => props.options.findIndex((o) => o.value === option));
});

const isChecked = (optionIndex: number): boolean => {
  return checkedOptionsIndexes.value.includes(optionIndex);
};
</script>
