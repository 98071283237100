<template>
  <router-link v-slot="{ isActive, href, navigate }" :to="to" custom>
    <div>
      <a :href="href" v-bind="getAriaAttributes(isActive)" :class="getClassList(isActive)" @click.prevent="navigate()">
        {{ hyperlinkText }}
      </a>
    </div>
  </router-link>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { RouteLocationRaw } from "vue-router";
import assertStringIsNotBlank from "@/base/functions/asserts/strings/AssertStringIsNotBlank.ts";

export default defineComponent({
  name: "BaseNavMenuSubItemLink",
  expose: [],
  props: {
    /**
     * Vue route
     */
    to: {
      required: true,
      type: [String, Object] as PropType<RouteLocationRaw>,
      validator: (to) => (typeof to === "string" ? assertStringIsNotBlank(to) : /* istanbul ignore next */ true),
    },

    /**
     * Hyperlink component text
     */
    hyperlinkText: {
      required: true,
      type: String,
      validator: (text) => assertStringIsNotBlank(text),
    },
  },

  methods: {
    getClassList(isActive: boolean) {
      return [!isActive ? "hover:text-gray-900 hover:font-medium text-gray-500" : "text-gray-900 font-medium"];
    },

    /**
     * @todo extrapolate
     * @param isActive Whether the link is currently active.
     * @returns object
     */
    getAriaAttributes(isActive: boolean) {
      return {
        ...(isActive ? { ariaCurrent: "page" } : {}),
        role: "link",
      };
    },
  },
});
</script>
