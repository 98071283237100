<template>
  <ul class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
    <li v-for="(item, index) in gridList" :key="item.id" class="overflow-hidden rounded-xl border border-gray-200">
      <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <img
          :src="item.imageUrl"
          :alt="item.title"
          loading="lazy"
          class="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
        />

        <div role="heading" aria-level="3" class="text-sm font-medium leading-6 text-gray-900">{{ item.title }}</div>

        <div v-if="item.menuItems !== undefined" class="relative ml-auto">
          <button
            :id="`options-menu-${index}-button`"
            type="button"
            class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500"
            :aria-expanded="isGridListItemOpen(item.id)"
            aria-haspopup="true"
            @click="openGridListItemMenuId = openGridListItemMenuId !== item.id ? item.id : undefined"
          >
            <span class="sr-only">Open options</span>

            <FontAwesomeIcon :icon="['fas', 'ellipsis']" aria-hidden="true" />
          </button>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <BaseDropdownMenu
              v-show="isGridListItemOpen(item.id)"
              :aria-labelledby="`options-menu-${index}-button`"
              class="absolute right-0"
              :items="item.menuItems"
              @click="$emit('clickMenuItem', { dropDownItem: $event, gridListItem: item })"
            >
            </BaseDropdownMenu>
          </transition>
        </div>
      </div>

      <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div v-for="info in item.additionalInformation" :key="info.label" class="flex justify-between gap-x-4 py-3">
          <dt class="text-gray-500">{{ info.label }}</dt>

          <dd
            :class="{
              'font-medium text-gray-900': info.isValueBold,
              'text-gray-700': !info.isValueBold,
            }"
          >
            {{ info.value }}
          </dd>
        </div>
      </dl>

      <div
        v-if="item.mainAction"
        class="flex items-center gap-x-4 border-gray-900/5 bg-gray-50 p-4 border-t"
        data-testid="main-action"
      >
        <button
          type="button"
          class="mx-auto cursor-pointer leading-6 text-gray-900 flex items-center space-x-2 hover:text-gray-500"
          @click="$emit('clickMainAction', item)"
        >
          <FontAwesomeIcon :icon="item.mainAction.icon" aria-hidden="true" :title="item.mainAction.label + ' Icon'" />

          <span class="text-xs font-medium">{{ item.mainAction.label }}</span>

          <span class="sr-only">{{ item.title }}</span>
        </button>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseDropdownMenu from "@/base/components/menus/dropdownMenus/BaseDropdownMenu.vue";
import type { BaseDropdownMenuButtonItem } from "@/base/interfaces/menus/dropdownMenus/BaseDropdownMenuButtonItem.ts";
import type { BaseDropdownMenuLinkItem } from "@/base/interfaces/menus/dropdownMenus/BaseDropdownMenuLinkItem.ts";

library.add(faEllipsis);

/** A grid list item */
interface LogoCardGridListItem {
  /** Unique identifier of the card */
  id: number | string;
  /** Title to display in grid card list */
  title: string;
  /** Url of image to display in grid card list */
  imageUrl: string;
  /** Additional information to display in sections below title */
  additionalInformation: {
    /** Text describing the information */
    label: string;
    /** Value of the information */
    value: number | string;

    /** Whether the value should be bold */
    isValueBold?: boolean;
    /**
     * The type of the value. Controls the html element used to display the value.
     * @todo add time type
     */
    type: "text";
  }[];

  /** Menu items to display in the grid list item menu */
  menuItems?: (BaseDropdownMenuButtonItem | BaseDropdownMenuLinkItem)[] | undefined;

  /** Display the main action button below the additional content */
  mainAction?: {
    /** The description of the main action */
    label: string;

    /** Icon to display next to the label */
    icon: string[];
  };
}

export type { LogoCardGridListItem };

/**
 * A grid list item with additional information
 *
 * - has functionality for displaying additional information
 * - has functionality for relative menu
 */
export default defineComponent({
  name: "LogoCardGridList",
  expose: [],
  components: {
    FontAwesomeIcon,
    BaseDropdownMenu,
  },

  props: {
    /** Grid list to display */
    gridList: {
      required: true,
      type: Array as PropType<LogoCardGridListItem[]>,
    },
  },

  emits: {
    /**
     * Emitted when a menu item is clicked
     * @param _items The menu item that was clicked
     * @param _items.dropDownItem The menu item that was clicked
     * @param _items.gridListItem The grid list item the menu item belongs to
     * @returns Whether the event should bubble up
     */
    clickMenuItem: (_items: {
      dropDownItem: BaseDropdownMenuButtonItem | BaseDropdownMenuLinkItem;
      gridListItem: LogoCardGridListItem;
    }) => true,

    /**
     * Emitted when the main action button is clicked
     * @param _gridListItem The grid list item that was clicked
     * @returns Whether the event should bubble up
     */
    clickMainAction: (_gridListItem: LogoCardGridListItem) => true,
  },

  data() {
    return {
      /** The id of the open grid list item menu */
      openGridListItemMenuId: undefined as LogoCardGridListItem["id"] | undefined,
    };
  },

  methods: {
    /**
     * Check if the menu is open for a specific grid list item by id
     * @param id The id of the grid list item
     * @returns Whether the menu is open for the grid list item
     */
    isGridListItemOpen(id: LogoCardGridListItem["id"]) {
      return this.openGridListItemMenuId === id;
    },
  },
});
</script>
