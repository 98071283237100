import type { ComputedRef, PropType } from "vue";
import { computed } from "vue";

/** @deprecated */
export const BaseButtonThemeIndigo = "indigo";
export const BaseButtonThemeWhite = "white";
export const BaseButtonThemeRed = "red";
export const BaseButtonThemeGreen = "green";

export const BaseButtonSizeMedium = "md";
export const BaseButtonSizeExtraLarge = "xl";

export type BaseButtonTheme =
  | typeof BaseButtonThemeGreen
  | typeof BaseButtonThemeIndigo
  | typeof BaseButtonThemeRed
  | typeof BaseButtonThemeWhite;

export type BaseButtonSize = typeof BaseButtonSizeExtraLarge | typeof BaseButtonSizeMedium;

export const BaseButtonThemes = Object.freeze([
  BaseButtonThemeIndigo,
  BaseButtonThemeWhite,
  BaseButtonThemeRed,
  BaseButtonThemeGreen,
]);

export const BaseButtonSizes = Object.freeze([BaseButtonSizeMedium, BaseButtonSizeExtraLarge]);

export const BaseButtonDefaultTheme = BaseButtonThemeIndigo;
export const BaseButtonDefaultSize = BaseButtonSizeExtraLarge;

export const BASE_BUTTON_CLASS_LIST = Object.freeze([
  "font-medium",
  "disabled:cursor-not-allowed",
  "shadow-sm",
  "focus:outline-none",
  "focus:ring-2",
  "focus:ring-offset-2",
]);

export const BaseButtonSizeMediumClassList = Object.freeze(["px-2.5", "py-1.5", "text-sm", "rounded-md"]);

/**
 * Based off of the tailwind buttons, this is the largest.
 */
export const BaseButtonSizeExtraLargeClassList = Object.freeze(["px-4", "py-2", "text-sm", "rounded-md"]);

/** @todo Change the theme names to blue */
/** @deprecated The contents of this theme are just blue and we no longer use indigo for buttons */
export const BaseButtonThemeIndigoClassList = Object.freeze([
  "border",
  "border-blue-600",
  "disabled:bg-blue-300",
  "bg-blue-600",
  "text-white",
  "hover:bg-blue-700",
  "focus:ring-blue-500",
]);

export const BaseButtonThemeWhiteClassList = Object.freeze([
  "disabled:bg-gray-50",
  "bg-white",
  "border",
  "text-gray-900",
  "border-gray-300",
  "hover:bg-gray-50",
  "focus:ring-gray-900",
]);

export const BaseButtonThemeRedClassList = Object.freeze([
  "border",
  "border-red-600",
  "disabled:bg-red-300",
  "bg-red-600",
  "text-white",
  "hover:bg-red-700",
  "focus:ring-red-500",
]);

export const BaseButtonThemeGreenClassList = Object.freeze([
  "border",
  "border-green-600",
  "disabled:bg-green-300",
  "bg-green-600",
  "text-white",
  "hover:bg-green-700",
  "focus:ring-green-500",
]);

export const themeProp = {
  type: String as PropType<BaseButtonTheme>,
  required: false,
  default: BaseButtonThemeIndigo,
  validator: (theme: string) => BaseButtonThemes.includes(theme),
};

export const sizeProp = {
  type: String as PropType<BaseButtonSize>,
  default: BaseButtonSizeMedium,
  required: false,
  validator: (size: string) => BaseButtonSizes.includes(size),
};

/**
 * Reusable logic for styling buttons.
 * @param theme {string}
 * @param size {string}
 * @returns Classlist
 */
export function useBaseButton(
  theme: BaseButtonTheme,
  size: BaseButtonSize
): {
  buttonThemeClassList: ComputedRef<string[]>;
  buttonSizeClassList: ComputedRef<string[]>;
  buttonClassList: ComputedRef<string[]>;
} {
  const buttonThemeClassList = computed(() => {
    if (!BaseButtonThemes.includes(theme)) {
      throw new TypeError(`Invalid theme '${theme}' provided`);
    }

    if (theme === BaseButtonThemeWhite) {
      return [...BaseButtonThemeWhiteClassList];
    }

    if (theme === BaseButtonThemeRed) {
      return [...BaseButtonThemeRedClassList];
    }

    if (theme === BaseButtonThemeGreen) {
      return [...BaseButtonThemeGreenClassList];
    }

    return [...BaseButtonThemeIndigoClassList];
  });

  const buttonSizeClassList = computed(() => {
    if (!BaseButtonSizes.includes(size)) {
      throw new TypeError(`Invalid size '${size}' provided`);
    }

    if (size === BaseButtonSizeExtraLarge) {
      return [...BaseButtonSizeExtraLargeClassList];
    }

    return [...BaseButtonSizeMediumClassList];
  });

  return {
    buttonThemeClassList,
    buttonSizeClassList,
    buttonClassList: computed(() => {
      const classList = [...BASE_BUTTON_CLASS_LIST];

      classList.push(...buttonThemeClassList.value);
      classList.push(...buttonSizeClassList.value);

      return classList;
    }),
  };
}
