const DisabledProp = {
  /** Used to disable elements in a component */
  disabled: {
    required: false,
    type: Boolean,
    default: false,
  },
};

const RequiredDisabledProp = {
  /** Used to disable elements in a component */
  disabled: {
    required: true as const,
    type: Boolean,
  },
};

export { RequiredDisabledProp };

export default DisabledProp;
