import type { CaptureContext } from "@sentry/core";
import * as Sentry from "@sentry/vue";
import type z from "zod";
import { handleErrorWithoutFailingInProduction } from "../../../app/error/ErrorHandler.ts";

/**
 * A prop validator that logs an error message to Sentry if the value is invalid.
 *
 * This should not be used for all prop validations, only for cases where the prop is critical to the component.
 * @param value The value to validate.
 * @param validator The validator function to use.
 * @param errorMessage The error message to log to Sentry.
 * @param captureContext The capture context to use.
 * @returns A prop validator function.
 */
function propValidator<T>(
  value: T,
  validator: (value: T) => boolean,
  errorMessage: string,
  captureContext?: CaptureContext | undefined
): boolean {
  const res = validator(value);

  if (!res) {
    Sentry.captureMessage(errorMessage, captureContext);
  }

  return res;
}

/**
 * A prop validator that uses a schema to validate the value.
 *
 * Logs an error message to Sentry if the value is invalid.
 * @param value The value to validate.
 * @param schema The zod schema to use.
 * @returns Whether the value is valid.
 */
function propSchemaValidator<T>(value: unknown, schema: z.ZodType<T>): boolean {
  const res = schema.safeParse(value);

  if (!res.success) {
    handleErrorWithoutFailingInProduction(res.error);
  }

  return res.success;
}

export { propSchemaValidator, propValidator };
