import { lowerCase } from "lodash";
import type { Ref } from "vue";
import { ref, watch, unref } from "vue";
import { lowerCaseSingular, titleCaseSingular, titleCasePlural } from "@/base/functions/Strings.ts";

/**
 * Slug converted to title case
 * @example My Task Type
 */
type SlugToTitleCasePlural = Ref<string>;

/**
 * Slug converted to lower case
 * @example my task type
 */
type SlugToLowerCasePlural = Ref<string>;

/**
 * Slug converted to title case and singular
 * @example My Task Type
 */
type SlugToTitleCaseSingular = Ref<string>;

/**
 * Slug converted to lower case and singular
 * @example my task type
 */
type SlugToLowerCaseSingular = Ref<string>;

/**
 * Composable to format the slug of a task type to different text formats
 */
interface SlugStringsComposable {
  slugToTitleCasePlural: SlugToTitleCasePlural;
  slugToLowerCasePlural: SlugToLowerCasePlural;
  slugToTitleCaseSingular: SlugToTitleCaseSingular;
  slugToLowerCaseSingular: SlugToLowerCaseSingular;
}

/**
 * Composable to format the slug of a task type to different text formats
 * @param slug The slug of the task type
 * @returns The slug strings
 */
export default function useSlugStringsComposable(slug: Ref<string>): SlugStringsComposable {
  const slugToTitleCasePlural: SlugToTitleCasePlural = ref("");
  const slugToLowerCaseSingular: SlugToLowerCaseSingular = ref<string>("");
  const slugToTitleCaseSingular: SlugToTitleCaseSingular = ref<string>("");
  const slugToLowerCasePlural: SlugToLowerCasePlural = ref<string>("");

  watch(
    () => unref(slug),
    (unrefSlug) => {
      slugToTitleCasePlural.value = titleCasePlural(unrefSlug);
      slugToLowerCaseSingular.value = lowerCaseSingular(unrefSlug);
      slugToTitleCaseSingular.value = titleCaseSingular(unrefSlug);
      slugToLowerCasePlural.value = lowerCase(unrefSlug);
    },
    { immediate: true }
  );

  return {
    slugToTitleCasePlural,
    slugToLowerCaseSingular,
    slugToTitleCaseSingular,
    slugToLowerCasePlural,
  };
}
