import { isAxiosError } from "axios";
import z from "zod";
import type { ErrorBagInterface } from "@/validation/classes/ErrorBag";
import ErrorBag from "@/validation/classes/ErrorBag.ts";

const AxiosErrorResponseDataSchema = z.object({
  message: z.string().optional(),
  errors: z.record(z.string(), z.array(z.string())).optional(),
});

/**
 * Default data class for http client errors
 *
 * This is uses to parse error data from an axios error
 */
export default class HttpClientErrorDataClass {
  /**
   * The error bag created from the axios response data errors (laravel error bag)
   */
  public readonly responseErrorBag: ErrorBagInterface;

  /**
   * The flashed error message from the axios response data (laravel flashed error)
   */
  public readonly responseErrorMessage: string | undefined;

  /**
   * Create a new instance of `HttpClientErrorDataClass` from a `Error` class
   * @param axiosError the error to parse
   * @throws {Error} thrown if axios error is not an instance of `Error`
   * @returns new instance of `HttpClientErrorDataClass`
   */
  public static new(axiosError: Error): HttpClientErrorDataClass {
    let errorBag = ErrorBag.new({});
    let errorMessage: string | undefined;

    // Assert error is error
    if (!(axiosError instanceof Error)) {
      throw new Error("HttpClientErrorDataClass expects `axiosError` to be an instance of `Error`");
    }

    // If the error is an axios error we attempt to parse the data from it
    if (isAxiosError(axiosError)) {
      const errorResponseData = AxiosErrorResponseDataSchema.safeParse(axiosError.response?.data);
      if (errorResponseData.success) {
        errorBag = ErrorBag.new(errorResponseData.data.errors || {});
        errorMessage = errorResponseData.data.message;
      }
    }

    // Create new class
    return new HttpClientErrorDataClass(errorBag, errorMessage);
  }

  private constructor(responseErrorBag: ErrorBagInterface, responseErrorMessage: string | undefined) {
    this.responseErrorBag = responseErrorBag;
    this.responseErrorMessage = responseErrorMessage;
  }
}
