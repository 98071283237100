<template>
  <BaseTableLoadingRow v-for="i in rowsCount" :key="i" v-bind="cellClassList && { cellClassList }" />

  <tr class="sr-only" role="status" :aria-label="screenReaderText" aria-live="polite">
    <td colspan="100%"></td>
  </tr>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import BaseTableLoadingRow from "./BaseTableLoadingRow.vue";

export default defineComponent({
  name: "BaseTableLoadingRows",
  expose: [],

  components: {
    BaseTableLoadingRow,
  },

  props: {
    /** Text for screen readers. */
    screenReaderText: {
      type: String,
      required: false,
      default: "Loading",
    },

    /** Class list to pass to the row which applies it to the cell */
    cellClassList: {
      type: [Array, String] as PropType<string[] | string | undefined>,
      required: false,
      default: undefined,
    },

    /**
     * The number of rows to display
     */
    rowsCount: {
      type: Number,
      required: false,
      default: 1,
      validator(value) {
        /* istanbul ignore if -- @preserve */
        if (typeof value !== "number") {
          return false;
        }

        return value >= 1;
      },
    },
  },
});
</script>
