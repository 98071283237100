<template>
  <VueApexCharts type="radialBar" :options="options" :series="series" />
</template>

<script lang="ts">
import type { ApexOptions } from "apexcharts";
import { initFlowbite } from "flowbite";
import type { PropType } from "vue";
import VueApexCharts from "vue3-apexcharts";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseRadialBarChart",
  expose: [],
  components: {
    VueApexCharts,
  },

  props: {
    /** The datasets to display on the chart */
    series: {
      type: Array as PropType<number[]>,
      required: true,
    },

    /** The label for each series item */
    labels: {
      type: Array as PropType<ApexOptions["labels"]>,
      required: true,
    },

    /**
     * The colours for each of the data.
     */
    colors: {
      type: [Array, undefined] as PropType<ApexOptions["colors"] | undefined>,
      required: false,
      default: undefined,
    },

    /**
     * What colour should the center label be.
     */
    labelColor: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },

    /**
     * What colour should the center value be.
     */
    valueColor: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },

    /**
     * What colour should the remaining circle be.
     */
    trackColor: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },

    /**
     * Should the name / label be shown in the center.
     */
    showLabel: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: false,
    },

    /**
     * Should the value be shown in the center.
     */
    showValue: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined,
    },
  },

  computed: {
    options() {
      return {
        colors: this.colors,

        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
            },

            dataLabels: {
              name: {
                color: this.labelColor,
                show: this.showLabel,
              },

              value: {
                color: this.valueColor,
                show: this.showValue,
                fontSize: "16px",
                offsetY: 5 * Number(this.showLabel !== true),
              },
            },

            track: {
              background: this.trackColor,
            },
          },
        },

        chart: {
          type: "radialBar",
          fontFamily: "Inter, sans-serif",
        },

        series: this.series,

        labels: this.labels,
      };
    },
  },

  watch: {
    series() {
      // this.$forceUpdate();
    },
  },

  mounted() {
    initFlowbite();
  },
});
</script>
